import * as React from "react";
import { SVGProps } from "react";

const SvgLike = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5 15V7.736a1 1 0 0 1 .106-.447L7.575 2.35a1.185 1.185 0 0 1 2.226.743L9.107 6.91A.5.5 0 0 0 9.6 7.5h4.464c.89 0 1.469.936 1.07 1.732a.599.599 0 0 0 0 .536l.143.285a1 1 0 0 1 0 .894l-.17.342a1 1 0 0 0-.106.447v.528a1 1 0 0 1-.106.447l-.288.578a1 1 0 0 0-.106.447v.585a.76.76 0 0 1-.08.34c-.258.514-.783.839-1.359.839H5.5A.5.5 0 0 1 5 15ZM4 15V8a.5.5 0 0 0-.5-.5h-2A.5.5 0 0 0 1 8v7a.5.5 0 0 0 .5.5h2A.5.5 0 0 0 4 15Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgLike;
