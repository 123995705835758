import { createTRPCReact } from "@trpc/react-query";
import type { AppRouter } from "../../../../backend-common/src/trpc/types";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;

export const trpc: ReturnType<typeof createTRPCReact<AppRouter>> =
	createTRPCReact<AppRouter>();

export { AppRouter };
