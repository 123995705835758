import { ZodIssue } from "zod/lib";
import { z } from "zod";

export interface ErrorResponse {
	error: ApiError | ApiValidationError;
	correlationId?: string;
}

export const ApiErrorSchema = z.object({
	name: z.string(),
	httpCode: z.number(),
	type: z.literal("api"),
	message: z.string(),
	description: z.string().optional(),
});
export type ApiError = z.infer<typeof ApiErrorSchema>;

export const UnknownError: ApiError = {
	name: "unknown_error",
	httpCode: 500,
	type: "api",
	message: "Something went wrong during your request",
	description: "Please try again later",
};

export const BadRequest: ApiError = {
	name: "bad_request",
	httpCode: 400,
	message: "Bad Request",
	type: "api",
};

export const Unauthorized: ApiError = {
	name: "unauthorized",
	httpCode: 401,
	message: "Unauthorized",
	type: "api",
};

export const Forbidden: ApiError = {
	name: "forbidden",
	httpCode: 403,
	message: "Forbidden",
	type: "api",
};

export const NotFound: ApiError = {
	name: "not_found",
	httpCode: 404,
	message: "Not found",
	type: "api",
};

export const UserAccountDoesNotExist: ApiError = {
	name: "user_account_does_not_exist",
	httpCode: 422,
	message: "A July account with that email does not exist",
	description: "Please use a different email address, or create an account.",
	type: "api",
};

export const NoPitchCreditsRemaining: ApiError = {
	name: "no_pitch_credits_remaining",
	httpCode: 429,
	message: "No pitch credits remaining",
	description: "Please upgrade your account to continue",
	type: "api",
};

export const NoWishlistCreditsRemaining: ApiError = {
	name: "no_wishlist_credits_remaining",
	httpCode: 429,
	message: "No wishlist credits remaining",
	type: "api",
};

export const NoContractAnalysisCreditsRemaining: ApiError = {
	name: "no_contract_analysis_credits_remaining",
	httpCode: 429,
	message: "No contract analysis credits remaining",
	type: "api",
};

export const TooManyRequests: ApiError = {
	name: "too_many_requests",
	httpCode: 429,
	message: "Too many requests",
	type: "api",
};

export const PlatformNotSupported: ApiError = {
	name: "platform_not_supported",
	httpCode: 422,
	message: "Platform is not supported",
	type: "api",
};

export const GmailSendPermissionsRequired: ApiError = {
	name: "gmail_send_permissions_required",
	httpCode: 400,
	message:
		"We were unable to send your outreach due to a problem with the Gmail API. Please go to settings and disconnect then reconnect your Gmail account.",
	type: "api",
};

export const NoInstagramConnected: ApiError = {
	name: "no_instagram_connected",
	httpCode: 400,
	message: "Please connect an Instagram account first",
	type: "api",
};

export const InvoiceCannotBeSent: ApiError = {
	name: "invoice_cannot_be_sent",
	httpCode: 422,
	message: "That invoice cannot be sent",
	type: "api",
};

export const InvoiceCannotBeEdited: ApiError = {
	name: "invoice_cannot_be_edited",
	httpCode: 422,
	message: "That invoice cannot be edited",
	type: "api",
};

export const StripeConnectAccountRequired: ApiError = {
	name: "stripe_connect_account_required",
	httpCode: 422,
	message: "A Stripe Connect account is required",
	type: "api",
};

export const StripeAdditionalInformationRequired: ApiError = {
	name: "stripe_additional_information_required",
	httpCode: 422,
	message: "Additional Stripe information is required",
	type: "api",
};

export const StripeInsufficientFundsForWithdraw: ApiError = {
	name: "stripe_insufficient_funds_for_withdraw",
	httpCode: 422,
	message: "Insufficient funds for withdraw",
	type: "api",
};

export const DotsUserRequired: ApiError = {
	name: "dots_user_required",
	httpCode: 422,
	message: "Please set up payments first",
	description: "Navigate to the Payment tab to set up payments",
	type: "api",
};

export const FailedToFetchInstagramAccountInfo: ApiError = {
	name: "failed_to_fetch_instagram_account_info",
	httpCode: 422,
	message: "We were unable to connect your Instagram account",
	description:
		"Please make sure the account you're connecting is a public creator or business account.",
	type: "api",
};

export const FailedToFetchYouTubeAccountInfo: ApiError = {
	name: "failed_to_fetch_youtube_account_info",
	httpCode: 422,
	message: "We were unable to connect your YouTube account",
	description:
		"Please make sure you have a YouTube channel associated with this account.",
	type: "api",
};

export const FailedToFetchTikTokAccountInfo: ApiError = {
	name: "failed_to_fetch_tiktok_account_info",
	httpCode: 422,
	message: "We were unable to connect your TikTok account",
	description:
		"Please make sure you have a TikTok account associated with this account.",
	type: "api",
};

export const NoAccountsEligibleToConnect: ApiError = {
	name: "no_accounts_eligible_to_connect",
	httpCode: 422,
	message: "No eligible Instagram accounts found",
	description:
		"Please ensure you're choosing an instagram account when redirected to Facebook. You may need to to click 'Edit Settings' first.",
	type: "api",
};

export const NoFacebookAccountsEligibleToConnect: ApiError = {
	name: "no_facebook_accounts_eligible_to_connect",
	httpCode: 422,
	message: "No eligible Facebook accounts found",
	description:
		"Please ensure you're choosing a facebook account when redirected to Facebook. You may need to to click 'Edit Settings' first.",
	type: "api",
};

export const ConnectedAccountMissingScopes: ApiError = {
	name: "connected_account_missing_scopes",
	httpCode: 422,
	message: "Some permissions were not granted",
	description:
		"Please make sure you grant all requested permissions when connecting your account. We only request the minimum required.",
	type: "api",
};

export const AlreadyInvited: ApiError = {
	name: "already_invited",
	httpCode: 422,
	message: "That email has already been invited",
	description: "Please check your inbox to see the previous invitation.",
	type: "api",
};

export const InvalidCoupon: ApiError = {
	name: "invalid_coupon",
	httpCode: 422,
	message: "That coupon is invalid",
	description: "Please double check the coupon code and try again.",
	type: "api",
};

export const ManagerAlreadyExists: ApiError = {
	name: "manager_already_exists",
	httpCode: 422,
	message: "A manager with that email already exists",
	description: "Please enter a different email address.",
	type: "api",
};

export const ManagerAccountDoesNotExist: ApiError = {
	name: "manager_account_does_not_exist",
	httpCode: 422,
	message: "A July account with that email does not exist",
	description:
		"Please have them create a July account, then invite them again.",
	type: "api",
};

export const ContractPdfTooLong: ApiError = {
	name: "contract_pdf_too_long",
	httpCode: 422,
	message: "That contract is too long",
	description: "Please upload a contract under 100 pages",
	type: "api",
};

export interface ApiValidationError {
	type: "validation";
	issues: ZodIssue[];
	name?: string;
}

export const EmailAlreadyInUse: ApiValidationError = {
	type: "validation",
	name: "email_already_in_use",
	issues: [
		{
			code: "custom",
			message: "Email already in use",
			path: ["email"],
		},
	],
};

export const UserWithEmailNotFound: ApiValidationError = {
	type: "validation",
	name: "user_with_email_not_found",
	issues: [
		{
			code: "custom",
			message: "A user with this email address was not found",
			path: ["email"],
		},
	],
};

export const AgencyCreatorLoginNotAllowed: ApiValidationError = {
	type: "validation",
	name: "unable_to_login_agency_creator",
	issues: [
		{
			code: "custom",
			message:
				"User not found. If you are managed by an agency ask the agency for a new sign in link.",
			path: ["email"],
		},
	],
};

export const IncorrectPassword: ApiValidationError = {
	type: "validation",
	name: "incorrect_password",
	issues: [
		{
			code: "custom",
			message: "That password is incorrect",
			path: ["password"],
		},
	],
};

export const PasswordMismatch: ApiValidationError = {
	type: "validation",
	name: "password_mismatch",
	issues: [
		{
			code: "custom",
			message: "Passwords don't match",
			path: ["confirmNewPassword"],
		},
	],
};

export const VerificationLinkNoLongerValid: ApiValidationError = {
	type: "validation",
	name: "verification_link_no_longer_valid",
	issues: [
		{
			code: "custom",
			message: "Verification link is no longer valid",
			path: ["token"],
		},
	],
};

export const PasswordResetLinkNoLongerValid: ApiValidationError = {
	type: "validation",
	name: "password_reset_link_no_longer_valid",
	issues: [
		{
			code: "custom",
			message: "Password reset link is no longer valid",
			path: ["token"],
		},
	],
};

export const InvoiceAmountTooSmall: ApiValidationError = {
	type: "validation",
	name: "invoice_amount_too_small",
	issues: [
		{
			code: "custom",
			message: "Invoice totals must be at least $1",
			path: ["items", 0, "amount"],
		},
	],
};

export const InvoiceAmountTooLarge: ApiValidationError = {
	type: "validation",
	name: "invoice_amount_too_large",
	issues: [
		{
			code: "custom",
			message:
				"Invoice totals cannot exceed $50,000. If you need to send an invoice this large, please contact us.",
			path: ["items", 0, "amount"],
		},
	],
};

export const InvoiceDueDateRequired: ApiValidationError = {
	type: "validation",
	name: "no_due_date_set",
	issues: [
		{
			code: "custom",
			message: "A due date is required",
			path: ["dueAt"],
		},
	],
};

export const UsernameTaken: ApiValidationError = {
	type: "validation",
	name: "username_taken",
	issues: [
		{
			code: "custom",
			message: "That username is already taken",
			path: ["username"],
		},
		{
			code: "custom",
			message: "That username is already taken",
			path: ["creatorProfile", "username"],
		},
	],
};

export const NoUsernameEntered: ApiValidationError = {
	type: "validation",
	name: "empty_input_username_field",
	issues: [
		{
			code: "custom",
			message: "No username entered",
			path: ["platformUsernames", "instagramUsername"],
		},
	],
};

export const ImATeaPot: ApiError = {
	name: "im_a_tea_pot",
	httpCode: 418,
	message: "I'm a teapot",
	description: "I'm a teapot",
	type: "api",
};
