import * as React from "react";
import { SVGProps } from "react";

const SvgGrabDots = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M11.583 3.833a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0ZM11.583 8.5a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0ZM11.583 13.167a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0ZM7.083 3.833a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0ZM7.083 8.5a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0ZM7.083 13.167a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgGrabDots;
