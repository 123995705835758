import { ToastProvider } from "./Toast/Provider";
import { TooltipProvider } from "./Tooltip";

export interface SolisProviderProps {
	children: React.ReactNode;
}

export const SolisProvider: React.FC<SolisProviderProps> = ({ children }) => {
	return (
		<>
			<TooltipProvider delayDuration={200}>{children}</TooltipProvider>
			<ToastProvider />
		</>
	);
};
