import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { cx } from "../classnames";

const SubtleTabs = TabsPrimitive.Root;

const SubtleTabsList = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.List>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.List
		ref={ref}
		className={cx(
			"inline-flex items-center justify-center rounded-md p-1",
			className,
		)}
		{...props}
	/>
));
SubtleTabsList.displayName = TabsPrimitive.List.displayName;

const SubtleTabsTrigger = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Trigger
		className={cx(
			"inline-flex w-full items-center justify-center bg-gray-600 p-2 transition-all first:rounded-l-lg last:rounded-r-lg disabled:pointer-events-none data-[state=active]:bg-gray-400",
			"transition-all duration-150 ease-in-out",
			className,
		)}
		{...props}
		ref={ref}
	/>
));
SubtleTabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const SubtleTabsContent = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Content
		className={className}
		tabIndex={-1}
		{...props}
		ref={ref}
	/>
));
SubtleTabsContent.displayName = TabsPrimitive.Content.displayName;

export { SubtleTabs, SubtleTabsList, SubtleTabsTrigger, SubtleTabsContent };
