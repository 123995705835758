import { useStore } from "zustand";
import {
	type ReportStore,
	createReportStore,
} from "../../utils/store/report-store";
import { createContext, ReactNode, useContext, useRef } from "react";

export type ReportStoreApi = ReturnType<typeof createReportStore>;

export const ReportStoreContext = createContext<ReportStoreApi | undefined>(
	undefined,
);

export interface ReportStoreProviderProps {
	children: ReactNode;
}

export const ReportStoreProvider: React.FC<ReportStoreProviderProps> = ({
	children,
}) => {
	const storeRef = useRef<ReportStoreApi>();
	if (!storeRef.current) {
		storeRef.current = createReportStore();
	}

	return (
		<ReportStoreContext.Provider value={storeRef.current}>
			{children}
		</ReportStoreContext.Provider>
	);
};

export const useReportStore = <T,>(selector: (store: ReportStore) => T): T => {
	const reportStoreContext = useContext(ReportStoreContext);

	if (!reportStoreContext) {
		throw new Error(`useReportStore must be used within ReportStoreProvider`);
	}

	return useStore(reportStoreContext, selector);
};
