import { z } from "zod";
import {
	AllThemesSchema,
	CurrencyTypeSchema,
	DefaultThemesSchema,
} from "./agency";
import {
	CustomProfileSchema,
	TwitchPostSchema,
	YouTubePostSchema,
} from "./profile";
import { ThemeSchema } from "./themes";

export const MediaKitThemeSchema = DefaultThemesSchema;

export type MediaKitTheme = z.infer<typeof MediaKitThemeSchema>;

export const MediaKitBaseStatSchema = z.object({
	name: z.string(),
	title: z.string(),
	enabled: z.boolean(),
});

export const MediaKitCollabStatSchema = z.object({
	title: z.string(),
	value: z.string(),
});
export type MediaKitCollabStat = z.infer<typeof MediaKitCollabStatSchema>;

export const MediaKitCollabStatsSchema = z.array(MediaKitCollabStatSchema);
export type MediaKitCollabStats = z.infer<typeof MediaKitCollabStatsSchema>;

const dateSchema = z.preprocess((arg) => {
	if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
}, z.date());

export const MediaKitValueStatSchema = MediaKitBaseStatSchema.extend({
	type: z.literal("value"),
	value: z.number(),
	display: z.enum(["number", "percent"]),
	description: z.string().optional(),
	updatedAt: dateSchema.optional(),
	source: z
		.enum([
			"instagram",
			"youtube",
			"tiktok",
			"facebook",
			"twitch",
			"custom",
			"no-auth",
		])
		.optional(),
});
export type MediaKitValueStat = z.infer<typeof MediaKitValueStatSchema>;

export const MediaKitCustomValueStatSchema = MediaKitBaseStatSchema.extend({
	type: z.literal("value"),
	value: z.union([z.number(), z.string()]),
});
export type MediaKitCustomValueStat = z.infer<
	typeof MediaKitCustomValueStatSchema
>;

export const MediaKitCustomPlatformValueStatSchema =
	MediaKitBaseStatSchema.extend({
		type: z.literal("custom"),
		value: z.union([z.number(), z.string()]),
		description: z.string().optional(),
		source: z.literal("custom"),
	});
export type MediaKitCustomPlatformValueStat = z.infer<
	typeof MediaKitCustomPlatformValueStatSchema
>;

export const MediaKitFieldsCustomStatsSchema = MediaKitBaseStatSchema.extend({
	value: z.union([z.number(), z.string()]),
	description: z.string().optional(),
});
export type MediaKitFieldsCustomStats = z.infer<
	typeof MediaKitFieldsCustomStatsSchema
>;

export const UpsertMediaKitCustomPlatformValueStatSchema = z.object({
	stats: MediaKitCustomPlatformValueStatSchema.array(),
	blockUuid: z.string().uuid(),
	mediaKitUuid: z.string().uuid(),
});

export type UpsertMediaKitCustomPlatformValueStat = z.infer<
	typeof UpsertMediaKitCustomPlatformValueStatSchema
>;

export const MediaKitCustomStatFormSchema = z.object({
	type: z.literal("custom"),
	value: z.union([z.number().min(2), z.string().min(2)]),
	description: z.string().optional(),
	source: z.literal("custom"),
	name: z.string(),
	title: z.string().min(2),
	enabled: z.boolean(),
});

export const MediaKitBarStatSchema = MediaKitBaseStatSchema.extend({
	type: z.literal("bar"),
	data: z.record(z.number()),
});
export type MediaKitBarStat = z.infer<typeof MediaKitBarStatSchema>;

export const MediaKitPieStatSchema = MediaKitBaseStatSchema.extend({
	type: z.literal("pie"),
	data: z.record(z.number()),
});
export type MediaKitPieStat = z.infer<typeof MediaKitPieStatSchema>;

export const MediaKitStatSchema = z.discriminatedUnion("type", [
	MediaKitValueStatSchema,
	MediaKitBarStatSchema,
	MediaKitPieStatSchema,
	MediaKitCustomPlatformValueStatSchema,
]);
export type MediaKitStat = z.infer<typeof MediaKitStatSchema>;

export const MediaKitCustomProfileStatSchema = z.discriminatedUnion("type", [
	MediaKitCustomValueStatSchema,
	MediaKitBarStatSchema,
	MediaKitPieStatSchema,
]);
export type MediaKitCustomProfileStat = z.infer<
	typeof MediaKitCustomProfileStatSchema
>;

export const MediaKitBaseBlockSchema = z.object({
	uuid: z.string().uuid(),
	enabled: z.boolean(),
	order: z.number(),
});

export const MediaKitFacebookBlockPostSchema = z.object({
	likeCount: z.number(),
	commentCount: z.number(),
	shareCount: z.number(),
	postUrl: z.string().url(),
	mediaUrl: z.string().url().optional(),
});
export type MediaKitFacebookBlockPost = z.infer<
	typeof MediaKitFacebookBlockPostSchema
>;

export const MediaKitFacebookBlockSchema = MediaKitBaseBlockSchema.extend({
	type: z.literal("facebook"),
	fields: z.object({
		username: z.string(),
		accountUrl: z.string().url(),
		stats: z.array(MediaKitStatSchema),
		weekStats: z.array(MediaKitStatSchema).optional(),
		twoWeekStats: z.array(MediaKitStatSchema).optional(),
		monthStats: z.array(MediaKitStatSchema).optional(),
		recentPosts: z.object({
			enabled: z.boolean(),
			posts: z.array(MediaKitFacebookBlockPostSchema),
		}),
		customStats: z.array(MediaKitCustomPlatformValueStatSchema).optional(),
	}),
	areTabsVisible: z.boolean().optional(),
});
export type MediaKitFacebookBlock = z.infer<typeof MediaKitFacebookBlockSchema>;

export const MediaKitInstagramBlockPostSchema = z.object({
	likeCount: z.number(),
	commentsCount: z.number(),
	mediaUrl: z.string().url().optional(),
	thumbnailUrl: z.string().url().optional(),
	mediaType: z.string(),
	postUrl: z.string().url(),
});
export type MediaKitInstagramBlockPost = z.infer<
	typeof MediaKitInstagramBlockPostSchema
>;

export const MediaKitInstagramBlockSchema = MediaKitBaseBlockSchema.extend({
	type: z.literal("instagram"),
	fields: z.object({
		username: z.string(),
		accountUrl: z.string().url(),
		stats: z.array(MediaKitStatSchema),
		weekStats: z.array(MediaKitStatSchema).optional(),
		twoWeekStats: z.array(MediaKitStatSchema).optional(),
		monthStats: z.array(MediaKitStatSchema).optional(),
		recentPosts: z.object({
			enabled: z.boolean(),
			posts: z.array(MediaKitInstagramBlockPostSchema),
		}),
		customStats: z.array(MediaKitCustomPlatformValueStatSchema).optional(),
	}),
	isAuth: z.boolean().default(true),
	areTabsVisible: z.boolean().optional(),
});
export type MediaKitInstagramBlock = z.infer<
	typeof MediaKitInstagramBlockSchema
>;

export const TikTokPostSchema = z.object({
	likeCount: z.number(),
	viewCount: z.number(),
	commentsCount: z.number(),
	mediaUrl: z.string().url(),
	mediaType: z.string(),
	postUrl: z.string().url(),
});
export type TikTokPost = z.infer<typeof TikTokPostSchema>;

export const MediaKitTikTokBlockSchema = MediaKitBaseBlockSchema.extend({
	type: z.literal("tiktok"),
	fields: z.object({
		username: z.string(),
		accountUrl: z.string().url(),
		isVerified: z.boolean(),
		stats: z.array(MediaKitStatSchema),
		weekStats: z.array(MediaKitStatSchema).optional(),
		twoWeekStats: z.array(MediaKitStatSchema).optional(),
		monthStats: z.array(MediaKitStatSchema).optional(),
		recentPosts: z.object({
			enabled: z.boolean(),
			posts: z.array(TikTokPostSchema),
		}),
		customStats: z.array(MediaKitCustomPlatformValueStatSchema).optional(),
	}),
	isAuth: z.boolean().default(true),
	areTabsVisible: z.boolean().optional(),
});
export type MediaKitTikTokBlock = z.infer<typeof MediaKitTikTokBlockSchema>;

export const MediaKitYouTubeBlockSchema = MediaKitBaseBlockSchema.extend({
	type: z.literal("youtube"),
	fields: z.object({
		username: z.string(),
		accountUrl: z.string().url(),
		stats: z.array(MediaKitStatSchema),
		weekStats: z.array(MediaKitStatSchema).optional(),
		twoWeekStats: z.array(MediaKitStatSchema).optional(),
		monthStats: z.array(MediaKitStatSchema).optional(),
		recentPosts: z.object({
			enabled: z.boolean(),
			posts: z.array(YouTubePostSchema),
		}),
		customStats: z.array(MediaKitCustomPlatformValueStatSchema).optional(),
	}),
	isAuth: z.boolean().default(true),
	areTabsVisible: z.boolean().optional(),
});
export type MediaKitYouTubeBlock = z.infer<typeof MediaKitYouTubeBlockSchema>;

export const MediaKitTwitchBlockSchema = MediaKitBaseBlockSchema.extend({
	type: z.literal("twitch"),
	fields: z.object({
		username: z.string(),
		accountUrl: z.string().url(),
		stats: z.array(MediaKitStatSchema),
		recentPosts: z.object({
			enabled: z.boolean(),
			posts: z.array(TwitchPostSchema),
		}),
		customStats: z.array(MediaKitCustomPlatformValueStatSchema).optional(),
	}),
});
export type MediaKitTwitchBlock = z.infer<typeof MediaKitTwitchBlockSchema>;

export const MediaKitPlatformBlockSchema = z.discriminatedUnion("type", [
	MediaKitFacebookBlockSchema,
	MediaKitInstagramBlockSchema,
	MediaKitTikTokBlockSchema,
	MediaKitYouTubeBlockSchema,
	MediaKitTwitchBlockSchema,
]);
export type MediaKitPlatformBlock = z.infer<typeof MediaKitPlatformBlockSchema>;

export const MediaKitCollab = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(0),
	url: z.string().url().optional(),
	description: z.string().optional(),
	logoUuid: z.string().uuid().optional(),
	logoUrl: z.string().url().optional(),
	order: z.number(),
	testimonial: z.string().optional(),
	stats: MediaKitCollabStatsSchema,
});
export type MediaKitCollab = z.infer<typeof MediaKitCollab>;

export const MediaKitCollabsBlockSchema = MediaKitBaseBlockSchema.extend({
	type: z.literal("collabs"),
	collabs: z.array(MediaKitCollab),
});
export type MediaKitCollabsBlock = z.infer<typeof MediaKitCollabsBlockSchema>;

export const MediaKitRateSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string(),
	price: z.string(),
	order: z.number(),
});

export type MediaKitRate = z.infer<typeof MediaKitRateSchema>;

export const MediaKitRatesBlockSchema = MediaKitBaseBlockSchema.extend({
	type: z.literal("rates"),
	rates: z.array(MediaKitRateSchema),
});
export type MediaKitRatesBlock = z.infer<typeof MediaKitRatesBlockSchema>;

export const ReorderMediaKitRatesSchema = z.object({
	uuid: z.string().uuid(),
	order: z.number(),
});
export type ReorderMediaKitRates = z.infer<typeof ReorderMediaKitRatesSchema>;

export const ReorderMediaKitCollabSchema = z.object({
	uuid: z.string().uuid(),
	order: z.number(),
});
export type ReorderMediaKitCollab = z.infer<typeof ReorderMediaKitRatesSchema>;

export const MediaKitInfoBlockSchema = z.discriminatedUnion("type", [
	MediaKitCollabsBlockSchema,
	MediaKitRatesBlockSchema,
]);
export type MediaKitInfoBlock = z.infer<typeof MediaKitInfoBlockSchema>;

export const UpsertMediaKitRateSchema = z.object({
	uuid: z.string().uuid().optional(),
	title: z.string().min(1).max(30),
	price: z.string().min(0),
});

export type UpsertMediaKitRate = z.infer<typeof UpsertMediaKitRateSchema>;

export const MediaKitBlockSchema = z.union([
	MediaKitPlatformBlockSchema,
	MediaKitInfoBlockSchema,
]);
export type MediaKitBlock = z.infer<typeof MediaKitBlockSchema>;

export const UpsertMediaKitCollabSchema = z.object({
	uuid: z.string().uuid().optional(),
	title: z.string().min(1),
	url: z.preprocess((arg) => {
		if (typeof arg !== "string") return arg;
		if (!arg.startsWith("https://")) return "https://" + arg;
		return arg;
	}, z.string().url().optional()),
	description: z.string().optional(),
	logoUuid: z.string().uuid().optional(),
	testimonial: z.string().optional(),
	stats: z.array(MediaKitCollabStatSchema),
});
export type UpsertMediaKitCollab = z.infer<typeof UpsertMediaKitCollabSchema>;

export const MediaKitHighlightedPostSchema = z.object({
	postUrl: z.string().url(),
	platform: z.string(),
	thumbnailUrl: z.string().url(),
	likeCount: z.number().optional(),
	viewCount: z.number().optional(),
	commentsCount: z.number().optional(),
	shareCount: z.number().optional(),
	mediaType: z.string().optional(),
	expiresAt: z.number().optional(),
	statsFetchedAt: z.number().optional(),
});
export type MediaKitHighlightedPost = z.infer<
	typeof MediaKitHighlightedPostSchema
>;

export const MediaKitHighlightedPostsSchema = z.array(
	MediaKitHighlightedPostSchema,
);
export type MediaKitHighlightedPosts = z.infer<
	typeof MediaKitHighlightedPostsSchema
>;

export const UpsertMediaKitHighlightedPostSchema = z.object({
	mediaKitUuid: z.string().uuid(),
	postUrl: z.string().url(),
	creatorProfileUuid: z.string().uuid(),
});

export const MediaKitSchema = z.object({
	uuid: z.string().uuid(),
	contact: z.string(),
	name: z.string(),
	pronouns: z.string().nullable(),
	bio: z.string(),
	about: z.string().max(750),
	isAboutEnabled: z.boolean(),
	tags: z.string(),
	blocks: z.array(MediaKitBlockSchema),
	isPublic: z.boolean(),
	headerStats: z.string(),
	headshot: z
		.object({
			uuid: z.string().uuid(),
			url: z.string().url(),
		})
		.optional(),
	theme: AllThemesSchema,
	accessCode: z.string().nullish(),
	darkMode: z.boolean(),

	// React query seems to fail to notice when some deeply nested fields change,
	// so we add a top level field we can use to force a rerender when doing
	// optimistic updates.
	nonce: z.string().optional(),
	customTheme: ThemeSchema.nullable(),
	agencyName: z.string(),
	agencyUsername: z.string(),
	agencyLogo: z.string().url().optional(),
	customProfiles: z.array(CustomProfileSchema),
	currency: CurrencyTypeSchema,
	highlightedPosts: z.array(MediaKitHighlightedPostSchema),
	showDateRangeFilters: z.boolean(),
	showHighlightedPosts: z.boolean(),
});
export type MediaKit = z.infer<typeof MediaKitSchema>;

export const LockedMediaKitSchema = z.object({
	about: z.string(),
	name: z.string(),
	headshotUrl: z.string().url().optional(),
	darkMode: z.boolean().optional(),
});
export type LockedMediaKit = z.infer<typeof LockedMediaKitSchema>;

export const ViewMediaKitSchema = z.discriminatedUnion("isLocked", [
	z.object({ isLocked: z.literal(false), data: MediaKitSchema }),
	z.object({ isLocked: z.literal(true), data: LockedMediaKitSchema }),
]);
export type ViewMediaKit = z.infer<typeof ViewMediaKitSchema>;

export const RequestMediaKitAccessSchema = z.object({
	username: z.string(),
	name: z.string(),
	email: z.string().email(),
	message: z.string().max(750).optional(),
});
export type RequestMediaKitAccess = z.infer<typeof RequestMediaKitAccessSchema>;

export const GrantMediaKitAccessSchema = z.object({
	email: z.string().email(),
	name: z.string(),
});
export type GrantMediaKitAccess = z.infer<typeof GrantMediaKitAccessSchema>;

export const UpdateMediaKitSchema = z.object({
	isPublic: z.boolean().optional(),
	name: z.string().optional(),
	bio: z.string().max(120).optional(),
	about: z.string().optional(),
	isAboutEnabled: z.boolean().optional(),
	showHighlightedPosts: z.boolean().optional(),
	tags: z.string().optional(),
	headerStats: z.string().optional(),
	theme: z.string().optional(),
	contact: z.string().email().optional(),
	darkMode: z.boolean().optional(),
	pronouns: z.string().nullable(),
});
export type UpdateMediaKit = z.infer<typeof UpdateMediaKitSchema>;

export const MediaKitFieldsSchema = z.object({
	stats: z.array(
		z.object({
			name: z.string(),
			enabled: z.boolean(),
		}),
	),
	recentPosts: z.object({
		enabled: z.boolean(),
	}),
	customStats: z.array(MediaKitFieldsCustomStatsSchema).optional(),
});
export type MediaKitFields = z.infer<typeof MediaKitFieldsSchema>;

export const UpdateMediaKitBlockSchema = z.object({
	type: z.string().optional(),
	enabled: z.boolean().optional(),
	order: z.number().optional(),
	fields: MediaKitFieldsSchema.optional(),
});
export type UpdateMediaKitBlock = z.infer<typeof UpdateMediaKitBlockSchema>;

export const CUSTOM_PROFILE_STATS = {
	age: {
		name: "age",
		title: "Age",
		type: "bar",
		enabled: false,
	},
	country: {
		name: "country",
		title: "Country",
		type: "pie",
		enabled: false,
	},
	gender: {
		name: "gender",
		title: "Gender",
		type: "pie",
		enabled: false,
	},
} as const;

export const TIKTOK_STATS: Stats = {
	followers: {
		name: "followers",
		title: "Followers",
		type: "value",
		display: "number",
		enabled: false,
		description: "The number of unique users following this account.",
		source: "tiktok",
	},
	engagement_rate: {
		name: "engagement_rate",
		title: "Engagement Rate",
		type: "value",
		display: "percent",
		enabled: false,
		description:
			"Calculated as Total Likes, Comments and Shares / Number of posts / Total Views within the specified date range.",
		source: "tiktok",
	},
	average_likes: {
		name: "average_likes",
		title: "Average Likes",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The average number of likes for posts within the specified date range. Calculated as Total Likes / Total Posts.",
		source: "tiktok",
	},
	average_comments: {
		name: "average_comments",
		title: "Average Comments",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The average number of comments for posts within the specified date range. Calculated as Total Comments / Total Posts.",
		source: "tiktok",
	},
	average_views: {
		name: "average_views",
		title: "Average Views",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The average number of views for posts within the specified date range. Calculated as Total Views / Total Posts.",
		source: "tiktok",
	},
	average_shares: {
		name: "average_shares",
		title: "Average Shares",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The average number of shares for posts within the specified date range. Calculated as Total Shares / Total Posts.",
		source: "tiktok",
	},
	age: {
		name: "age",
		title: "Age",
		type: "bar",
		enabled: false,
	},
	country: {
		name: "country",
		title: "Country",
		type: "pie",
		enabled: false,
	},
	gender: {
		name: "gender",
		title: "Gender",
		type: "pie",
		enabled: false,
	},
} as const;

export const TIKTOK_NOAUTH_STATS: Stats = {
	followers: {
		name: "followers",
		title: "Followers",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description: "The number of unique users following this account.",
	},
	engagement_rate: {
		name: "engagement_rate",
		title: "Engagement Rate",
		type: "value",
		display: "percent",
		enabled: false,
		source: "no-auth",
		description: "Calculated as Total Likes / Total Followers.",
	},
	average_likes: {
		name: "average_likes",
		title: "Average Likes",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The average number of likes over the past 20 posts. Calculated as Total Likes / Total Posts.",
	},
	average_comments: {
		name: "average_comments",
		title: "Average Comments",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The average number of comments over the past 20 posts. Calculated as Total Comments / Total Posts.",
	},
	age: {
		name: "age",
		title: "Age",
		type: "bar",
		enabled: false,
	},
	country: {
		name: "country",
		title: "Country",
		type: "pie",
		enabled: false,
	},
	gender: {
		name: "gender",
		title: "Gender",
		type: "pie",
		enabled: false,
	},
} as const;

export type Stats = Record<
	string,
	{
		name: string;
		title: string;
		enabled: boolean;
		type: "value" | "bar" | "pie";
		display?: "number" | "percent";
		description?: string;
		source?:
			| "instagram"
			| "youtube"
			| "tiktok"
			| "facebook"
			| "twitch"
			| "custom"
			| "no-auth";
	}
>;

export const INSTAGRAM_STATS: Stats = {
	followers: {
		name: "followers",
		title: "Followers",
		type: "value",
		display: "number",
		enabled: false,
		source: "instagram",
		description: "The number of unique users following this account.",
	},
	engagement_rate: {
		name: "engagement_rate",
		title: "Engagement Rate",
		type: "value",
		display: "percent",
		enabled: false,
		source: "instagram",
		description:
			"Calculated as Total Likes & Comments within the specified date range / Number of followers.",
	},
	average_likes: {
		name: "average_likes",
		title: "Average Likes",
		type: "value",
		display: "number",
		enabled: false,
		source: "instagram",
		description:
			"The average number of likes for posts within the specified date range. Calculated as Total Likes / Total Posts.",
	},
	average_comments: {
		name: "average_comments",
		title: "Average Comments",
		type: "value",
		display: "number",
		enabled: false,
		source: "instagram",
		description:
			"The average number of comments for posts within the specified date range. Calculated as Total Comments / Total Posts.",
	},
	gender: {
		name: "gender",
		title: "Gender",
		type: "pie",
		enabled: false,
	},
	country: {
		name: "country",
		title: "Country",
		type: "pie",
		enabled: false,
	},
	age: {
		name: "age",
		title: "Age",
		type: "bar",
		enabled: false,
	},
	story_views: {
		name: "story_views",
		title: "Average Story Views",
		type: "value",
		display: "number",
		enabled: false,
		source: "instagram",
		description:
			"The average number of story views within the specified date range. Calculated as Story Impressions / Total Stories.",
	},
	impressions: {
		name: "impressions",
		title: "Impressions",
		type: "value",
		display: "number",
		enabled: false,
		source: "instagram",
		description:
			"The total number of times any media from this account has been viewed over the past 30 days.",
	},
} as const;

export const INSTAGRAM_NOAUTH_STATS: Stats = {
	followers: {
		name: "followers",
		title: "Followers",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description: "The number of unique users following this account.",
	},
	engagement_rate: {
		name: "engagement_rate",
		title: "Engagement Rate",
		type: "value",
		display: "percent",
		enabled: false,
		source: "no-auth",
		description: "Calculated as Total Likes / Total Followers.",
	},
	average_likes: {
		name: "average_likes",
		title: "Average Likes",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The average number of likes over the past 20 posts. Calculated as Total Likes / Total Posts.",
	},
	average_comments: {
		name: "average_comments",
		title: "Average Comments",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The average number of comments over the past 20 posts. Calculated as Total Comments / Total Posts.",
	},
	gender: {
		name: "gender",
		title: "Gender",
		type: "pie",
		enabled: false,
	},
	country: {
		name: "country",
		title: "Country",
		type: "pie",
		enabled: false,
	},
	age: {
		name: "age",
		title: "Age",
		type: "bar",
		enabled: false,
	},
} as const;

export const YOUTUBE_STATS: Stats = {
	subscribers: {
		name: "subscribers",
		title: "Subscribers",
		type: "value",
		display: "number",
		enabled: false,
		description: "The number of unique users subscribed to this channel.",
		source: "youtube",
	},
	total_views: {
		name: "total_views",
		title: "Total Views",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The combined total number of views for all of this channel’s videos.",
		source: "youtube",
	},
	gender: {
		name: "gender",
		title: "Gender",
		type: "pie",
		enabled: false,
	},
	age: {
		name: "age",
		title: "Age",
		type: "bar",
		enabled: false,
	},
	country: {
		name: "country",
		title: "Country",
		type: "pie",
		enabled: false,
	},
	average_watch_time: {
		name: "average_watch_time",
		title: "Average Watch Time",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The average amount of time viewers spend watching a video from this channel.",
		source: "youtube",
	},
	average_views: {
		name: "average_views",
		title: "Average Views",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The average number of views for videos within the specified date range. Calculated as Total Views / Total Videos.",
		source: "youtube",
	},
	average_likes: {
		name: "average_likes",
		title: "Average Likes",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The average number of likes for videos within the specified date range. Calculated as Total Likes / Total Videos.",
		source: "youtube",
	},
	average_comments: {
		name: "average_comments",
		title: "Average Comments",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The average number of comments for videos within the specified date range. Calculated as Total Comments / Total Videos.",
		source: "youtube",
	},
	engagement_rate: {
		name: "engagement_rate",
		title: "Engagement Rate",
		type: "value",
		display: "percent",
		enabled: false,
		description:
			"Calculated as Average Likes + Average Comments / Average Views within the specified date range.",
		source: "youtube",
	},
	average_shorts_views: {
		name: "average_shorts_views",
		title: "Average Shorts Views",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The average number of views for Shorts within the specified date range. Calculated as Total Shorts Views / Total Shorts.",
		source: "youtube",
	},
	average_shorts_likes: {
		name: "average_shorts_likes",
		title: "Average Shorts Likes",
		type: "value",
		display: "number",
		enabled: false,
		description:
			"The average number of likes for Shorts within the specified date range. Calculated as Total Shorts Likes / Total Shorts.",
		source: "youtube",
	},
} as const;

export const YOUTUBE_NOAUTH_STATS: Stats = {
	subscribers: {
		name: "subscribers",
		title: "Subscribers",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description: "The number of unique users subscribed to this channel.",
	},
	total_views: {
		name: "total_views",
		title: "Total Views",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The combined total number of views for all of this channel’s videos.",
	},
	gender: {
		name: "gender",
		title: "Gender",
		type: "pie",
		enabled: false,
	},
	age: {
		name: "age",
		title: "Age",
		type: "bar",
		enabled: false,
	},
	country: {
		name: "country",
		title: "Country",
		type: "pie",
		enabled: false,
	},
	average_likes: {
		name: "average_likes",
		title: "Average Likes",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The average number of likes over the past 20 posts. Calculated as Total Likes / Total Posts.",
	},
	average_comments: {
		name: "average_comments",
		title: "Average Comments",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The average number of comments over the past 20 posts. Calculated as Total Comments / Total Posts.",
	},
	engagement_rate: {
		name: "engagement_rate",
		title: "Engagement Rate",
		type: "value",
		display: "percent",
		enabled: false,
		source: "no-auth",
		description: "Calculated as Total Likes / Total Followers.",
	},
} as const;

export const TWITCH_STATS = {
	followers: {
		name: "followers",
		title: "Followers",
		type: "value",
		display: "number",
		enabled: false,
		source: "twitch",
		description: "The number of unique users following this channel.",
	},
	average_live_views: {
		name: "average_live_views",
		title: "Average Live Views",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The average number of viewers simultaneously watching a stream from this channel.",
	},
	hours_watched: {
		name: "hours_watched",
		title: "Watch Time (Hours)",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description: "The total number of hours spent watching this channel.",
	},
	highest_views: {
		name: "highest_views",
		title: "Highest Views",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The highest number of viewers simultaneously watching a stream from this channel.",
	},
	total_live_views: {
		name: "total_live_views",
		title: "Total Live Views",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The total number of times any livestream from this channel has been viewed.",
	},
	unique_views: {
		name: "unique_views",
		title: "Unique Views",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The total number of unique users who have watched livestreams or videos from this channel.",
	},
	daily_average_views: {
		name: "daily_average_views",
		title: "Daily Average Views",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The daily average number of users who watch a livestream or video from this channel.",
	},
	average_view_duration: {
		name: "average_view_duration",
		title: "Average View Duration (Hours)",
		type: "value",
		display: "number",
		enabled: false,
		source: "no-auth",
		description:
			"The average amount of time a viewer spends watching a livestream or video from this channel.",
	},
	daily_chat_engagement_rate: {
		name: "daily_chat_engagement_rate",
		title: "Daily Chat Engagement Rate",
		type: "value",
		display: "percent",
		enabled: false,
		source: "no-auth",
		description:
			"The percentage of viewers using chat during this channel’s livestreams.",
	},
} as const;

export const FACEBOOK_STATS = {
	followers: {
		name: "followers",
		title: "Followers",
		type: "value",
		display: "number",
		enabled: false,
		source: "facebook",
		description: "The number of unique users following this account.",
	},
	engagement_rate: {
		name: "engagement_rate",
		title: "Engagement Rate",
		type: "value",
		display: "percent",
		enabled: false,
		source: "facebook",
		description:
			"Calculated as Total Likes, Reactions, Comments and Shares / Total Posts within the specified date range.",
	},
	average_likes: {
		name: "average_likes",
		title: "Average Likes",
		type: "value",
		display: "number",
		enabled: false,
		source: "facebook",
		description:
			"The average number of likes for posts within the specified date range. Calculated as Total Likes / Total Posts.",
	},
	average_comments: {
		name: "average_comments",
		title: "Average Comments",
		type: "value",
		display: "number",
		enabled: false,
		source: "facebook",
		description:
			"The average number of comments for posts within the specified date range. Calculated as Total Comments / Total Posts.",
	},
	average_reactions: {
		name: "average_reactions",
		title: "Average Reactions",
		type: "value",
		display: "number",
		enabled: false,
		source: "facebook",
		description:
			"The average number of reactions (including likes) for posts within the specified date range. Calculated as Total Reactions / Posts.",
	},
	average_shares: {
		name: "average_shares",
		title: "Average Shares",
		type: "value",
		display: "number",
		enabled: false,
		source: "facebook",
		description:
			"The average number of shares for posts within the specified date range. Calculated as Total Shares / Total Posts.",
	},
	page_likes: {
		name: "page_likes",
		title: "Page Likes",
		type: "value",
		display: "number",
		enabled: false,
		source: "facebook",
		description: "The total number of likes the page itself has received.",
	},
	impressions: {
		name: "impressions",
		title: "Impressions",
		type: "value",
		display: "number",
		enabled: false,
		source: "facebook",
		description:
			"The number of times any content from this page (posts, stories, ads, etc.) has been shown on a user’s screen over the past 28d.",
	},
	engaged_users: {
		name: "engaged_users",
		title: "Engaged Users",
		type: "value",
		display: "number",
		enabled: false,
		source: "facebook",
	},
	country: {
		name: "country",
		title: "Country",
		type: "pie",
		enabled: false,
	},
} as const;

export const UpsertMediaKitCollabStatSchema = z.object({
	title: z.string(),
	value: z.string(),
	statIndex: z.number().optional(),
});
export type UpsertMediaKitCollabStat = z.infer<
	typeof UpsertMediaKitCollabStatSchema
>;

export const DeleteMediaKitCollabStatSchema = z.object({
	collabUuid: z.string().uuid(),
	statIndex: z.number(),
});
export type DeleteMediaKitCollabStat = z.infer<
	typeof DeleteMediaKitCollabStatSchema
>;

export const MediaKitPreviewSchema = z.object({
	firstName: z.string(),
	lastName: z.string(),
	displayName: z.string().nullable(),
	industries: z.array(z.string()),
	username: z.string(),
	isRatesEnabled: z.boolean(),
	creatorProfileUuid: z.string(),
	profilePicture: z.string().url().optional(),
});
export type MediaKitPreview = z.infer<typeof MediaKitPreviewSchema>;
