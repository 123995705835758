import { z } from "zod";

export const AgencyInvoiceStatusSchema = z.enum([
	"sent",
	"paid",
	"overdue",
	"void",
]);
export type AgencyInvoiceStatus = z.infer<typeof AgencyInvoiceStatusSchema>;

export const AgencyFastPayStatus = z.enum(["REQUESTED", "APPROVED", "PAID"]);

export const InvoiceOverviewStatsSchema = z.object({
	outstanding: z.object({ amount: z.number(), number: z.number() }),
	overdue: z.object({ amount: z.number(), number: z.number() }),
});

export type InvoiceOverviewStats = z.infer<typeof InvoiceOverviewStatsSchema>;

export const InvoiceDeliverableSchema = z.object({
	title: z.string().min(2),
	amount: z.number(),
});

export type InvoiceDeliverable = z.infer<typeof InvoiceDeliverableSchema>;

export const InvoiceDeliverablesSchema = z.array(InvoiceDeliverableSchema);
export type InvoiceDeliverables = z.infer<typeof InvoiceDeliverablesSchema>;

// A talent, manager, deliverables entry for an invoice
export const InvoiceEntrySchema = z.object({
	uuid: z.string().uuid(),
	talent: z
		.object({
			uuid: z.string().uuid(),
			firstName: z.string(),
			lastName: z.string(),
			profilePictureUrl: z.string().optional(),
		})
		.optional(),
	manager: z
		.object({
			uuid: z.string().uuid(),
			firstName: z.string(),
			lastName: z.string(),
			profilePictureUrl: z.string().optional(),
		})
		.optional(),
	deliverables: z.array(InvoiceDeliverableSchema),
});
export type InvoiceEntry = z.infer<typeof InvoiceEntrySchema>;

export const AgencyInvoiceSchema = z.object({
	invoiceNumber: z.string(),
	brandName: z.string().min(2),
	brandEmail: z.string().email(),
	dueDate: z.date(),
	entries: z.array(InvoiceEntrySchema).nonempty(),
	fastPay: z.boolean(),
});

export type AgencyInvoice = z.infer<typeof AgencyInvoiceSchema>;

export const AgencyInvoicePreviewSchema = z.object({
	uuid: z.string().uuid(),
	invoiceNumber: z.string(),
	brandName: z.string().min(2),
	brandEmail: z.string().email(),
	amount: z.number(),
	dueDate: z.date(),
	status: AgencyInvoiceStatusSchema,
	fastPayStatus: AgencyFastPayStatus.optional(),
	allDeliverables: z.array(InvoiceDeliverableSchema),
	split: z.array(
		z
			.object({
				name: z.string().optional(),
				profilePictureUrl: z.string().url().optional(),
			})
			.optional(),
	),
});

export type AgencyInvoicePreview = z.infer<typeof AgencyInvoicePreviewSchema>;

export const BankDetails = z.object({
	name: z.string(),
	address: z.string(),
	routingNumber: z.string(),
	accountNumber: z.string(),
});
export type BankDetails = z.infer<typeof BankDetails>;

export const InvoiceDetails = z.object({
	id: z.string(),
	brandName: z.string(),
	dueAt: z.date(),
	approved: z.boolean(),
	fastPayStatus: AgencyFastPayStatus.optional(),
	deliverables: z
		.object({
			name: z.string(),
			amount: z.number(),
		})
		.array(),
});
export type InvoiceDetails = z.infer<typeof InvoiceDetails>;

export const AgencyDetails = z.object({
	name: z.string(),
	logoUrl: z.string().nullable(),
});
export type AgencyDetails = z.infer<typeof AgencyDetails>;

const BaseInvoicePaymentPreview = z.object({
	invoice: InvoiceDetails,
	agency: AgencyDetails,
});

export const InvoicePaymentPreview = z.discriminatedUnion("status", [
	BaseInvoicePaymentPreview.extend({
		status: z.literal("open"),
		bankDetails: BankDetails,
	}),
	BaseInvoicePaymentPreview.extend({
		status: z.literal("paid"),
	}),
	BaseInvoicePaymentPreview.extend({
		status: z.literal("void"),
	}),
]);
export type InvoicePaymentPreview = z.infer<typeof InvoicePaymentPreview>;

export const InvoicePaymentPdf = z.object({
	id: z.string(),
	createdAt: z.date(),
	dueDate: z.date(),
	agency: z.object({
		name: z.string(),
		email: z.string().email(),
	}),
	brand: z.object({
		name: z.string(),
		email: z.string().email(),
	}),
	deliverables: z
		.object({
			title: z.string(),
			amount: z.number(),
		})
		.array(),
	bankDetails: BankDetails,
});
export type InvoicePaymentPdf = z.infer<typeof InvoicePaymentPdf>;
