import * as React from "react";
import { SVGProps } from "react";

const SvgVerified = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M4 4.667h8v6.666H4V4.667Z" fill="currentcolor" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.414 1.9 10.029 0 7.906 1.01 5.756.057 4.425 1.994l-2.338.25L1.9 4.585 0 5.971l1.01 2.123-.953 2.15 1.937 1.332.25 2.338 2.342.186L5.971 16l2.123-1.01 2.15.953 1.332-1.937 2.338-.25.186-2.342 1.9-1.385-1.01-2.123.953-2.15-1.937-1.332-.25-2.338-2.342-.186Zm-.545 3.764L7.083 9.521l-1.96-1.984-.78.747 2.74 2.762 4.565-4.603-.78-.779Z"
			fill="#42A5F5"
		/>
	</svg>
);

export default SvgVerified;
