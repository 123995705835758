import * as React from "react";
import { SVGProps } from "react";

const SvgTiktokLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#tiktok-logo_svg__a)">
			<path
				d="M6.657 6.819v-.615a5.158 5.158 0 0 0-.67-.047c-2.745 0-4.978 2.143-4.978 4.777 0 1.616.841 3.046 2.125 3.911a4.655 4.655 0 0 1-1.337-3.252c0-2.597 2.17-4.714 4.86-4.774Z"
				fill="#00F2EA"
			/>
			<path
				d="M6.79 13.604c1.16 0 2.106-.94 2.149-2.113l.004-10.464h1.876c-.04-.218-.06-.44-.06-.662H8.195L8.192 10.83c-.043 1.172-.99 2.112-2.15 2.112-.348 0-.69-.086-.999-.252.2.283.462.514.766.673.303.159.64.242.98.242Zm7.533-9.025v-.582a3.494 3.494 0 0 1-1.94-.587 3.553 3.553 0 0 0 1.94 1.17"
				fill="#00F2EA"
			/>
			<path
				d="M12.306 3.558a3.624 3.624 0 0 1-.875-2.365h-.684c.09.482.274.94.542 1.347.268.407.614.753 1.017 1.018ZM5.982 8.666c-1.187.002-2.148.977-2.149 2.18.001.4.11.79.312 1.13.203.341.494.618.84.8a2.188 2.188 0 0 1-.407-1.27c.001-1.205.962-2.18 2.148-2.181.222 0 .434.037.635.1V6.778a4.613 4.613 0 0 0-.635-.047l-.11.002v2.034c-.205-.066-.42-.1-.634-.1Z"
				fill="#FF004F"
			/>
			<path
				d="M13.981 4.916v2.02a6.01 6.01 0 0 1-3.555-1.162v5.282c0 2.638-2.11 4.784-4.704 4.784a4.621 4.621 0 0 1-2.696-.867c.441.482.974.867 1.566 1.13.592.262 1.23.398 1.875.397 2.594 0 4.704-2.146 4.704-4.784V6.433a6.01 6.01 0 0 0 3.556 1.163v-2.6c-.25 0-.5-.026-.746-.08Z"
				fill="#FF004F"
			/>
			<path
				d="M10.684 10.882v-5.29a6.242 6.242 0 0 0 3.64 1.164V4.733a3.644 3.644 0 0 1-1.983-1.167 3.624 3.624 0 0 1-1.596-2.373H8.83l-.004 10.431a2.196 2.196 0 0 1-2.195 2.106 2.2 2.2 0 0 1-1.783-.912 2.187 2.187 0 0 1-1.178-1.937 2.196 2.196 0 0 1 2.847-2.086V6.754c-2.602.06-4.701 2.184-4.701 4.788a4.76 4.76 0 0 0 1.293 3.262c.814.568 1.775.87 2.76.869 2.654 0 4.815-2.15 4.815-4.791Z"
				fill="currentcolor"
			/>
		</g>
		<defs>
			<clipPath id="tiktok-logo_svg__a">
				<path
					fill="currentcolor"
					transform="translate(0 .5)"
					d="M0 0h16v16H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgTiktokLogo;
