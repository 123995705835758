import { css } from "@emotion/react";
import { Root } from "@radix-ui/react-toggle";
import React, { ReactElement } from "react";
import { cx } from "../classnames";
import { color } from "../theme";
import { Text } from "./Text";

interface ToggleProps {
	id?: string;
	label?: string | ReactElement;
	disabled?: boolean;
	checked: boolean;
	setChecked: (checked: boolean) => void;
}

export const Toggle = React.forwardRef<
	React.ElementRef<typeof Root>,
	ToggleProps
>(({ id, label, disabled = false, checked, setChecked }, ref) => {
	const toggle = (
		<Root
			id={id}
			pressed={checked}
			onPressedChange={setChecked}
			disabled={disabled}
			className="shadow-20 relative h-4 w-[1.635rem] rounded-full border border-gray-300 bg-transparent transition-all transition-all duration-150 ease-in-out ease-out focus-visible:ring-4 data-[state=on]:border-blue-600 data-[state=on]:bg-blue-600"
			css={css`
				&[data-state="off"] div {
					left: 1px;
				}
				&[data-state="on"] div {
					left: 11px;
				}
				&:disabled {
					background: ${color.gray[300]};
					border-color: ${color.gray[300]};
				}
				&:disabled div {
					box-shadow: none;
					background: ${color.gray[200]};
				}
			`}
			ref={ref}
		>
			<div className="bg-white-200 shadow-20 absolute left-[1px] top-[1px] h-3 w-3 rounded-full transition-all ease-out data-[state=on]:left-full" />
		</Root>
	);

	if (label) {
		return (
			<label
				className={cx(
					"flex items-center gap-3 ",
					disabled ? "text-gray-200" : "text-white-100",
				)}
			>
				{toggle} <Text>{label}</Text>
			</label>
		);
	}

	return toggle;
});

Toggle.displayName = Root.displayName;
