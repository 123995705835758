import * as React from "react";
import { SVGProps } from "react";

const SvgUser = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.333 15.167H2.667v-1.334A3.334 3.334 0 0 1 6 10.5h4a3.333 3.333 0 0 1 3.333 3.333v1.334ZM8 9.167a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgUser;
