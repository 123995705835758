import { z } from "zod";
import { DateStringSchema } from "./utils";
import { PlatformEngagementTierSchema } from "./profile";

export const CreatorCalculatorProfileSchema = z.object({
	instagram: z
		.object({
			expiresAt: DateStringSchema,
			profilePictureUrl: z.string().url(),
			followers: z.number(),
			engagement: z.number(),
			ratePerPost: z.number(),
			name: z.string().optional(),
			engagementTier: PlatformEngagementTierSchema.optional(),
		})
		.optional(),
	tiktok: z
		.object({
			followers: z.number(),
			engagement: z.number(),
			ratePerPost: z.number(),
		})
		.optional(),
	youtube: z
		.object({
			subscribers: z.number(),
			ratePerPost: z.number(),
		})
		.optional(),
});
export type CreatorCalculatorProfile = z.infer<
	typeof CreatorCalculatorProfileSchema
>;
