import { cx } from "../classnames";

interface AILoaderProps {
	className?: string;
}

export const AiLoader: React.FC<AILoaderProps> = ({ className }) => {
	return (
		<div
			className={cx(
				"h-2 w-full max-w-[352px] rounded-full bg-gray-700",
				className,
			)}
		>
			<div className=" animate-ai-loading shadow-brand-glow-small h-2 w-full rounded-full bg-blue-600" />
		</div>
	);
};
