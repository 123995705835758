import { createContext, ReactNode, useContext, useRef } from "react";
import { useStore } from "zustand";

import { type DeckStore, createDeckStore } from "~/utils/store/deck-store";

export type DeckStoreApi = ReturnType<typeof createDeckStore>;

export const DeckStoreContext = createContext<DeckStoreApi | undefined>(
	undefined,
);

export interface DeckStoreProviderProps {
	children: ReactNode;
}

export const DeckStoreProvider: React.FC<DeckStoreProviderProps> = ({
	children,
}) => {
	const storeRef = useRef<DeckStoreApi>();
	if (!storeRef.current) {
		storeRef.current = createDeckStore();
	}

	return (
		<DeckStoreContext.Provider value={storeRef.current}>
			{children}
		</DeckStoreContext.Provider>
	);
};

export const useDeckStore = <T,>(selector: (store: DeckStore) => T): T => {
	const deckStoreContext = useContext(DeckStoreContext);

	if (!deckStoreContext) {
		throw new Error(`useDeckStore must be used within DeckStoreProvider`);
	}

	return useStore(deckStoreContext, selector);
};
