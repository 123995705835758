import * as React from "react";
import { SVGProps } from "react";

const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2 2.5h12a.667.667 0 0 1 .667.667v10.666A.667.667 0 0 1 14 14.5H2a.666.666 0 0 1-.667-.667V3.167A.667.667 0 0 1 2 2.5Zm6.04 5.789-4.275-3.63-.863 1.016 5.147 4.37 5.054-4.374-.872-1.008-4.19 3.626H8.04Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgEmail;
