import { z } from "zod";
import { ApiErrorSchema } from ".";

export const AgencyBillingErrorSchema = ApiErrorSchema.extend({
	name: z.enum(["CARD_NOT_SET"]),
});
export type AgencyBillingError = z.infer<typeof AgencyBillingErrorSchema>;

export const CardNotSet: AgencyBillingError = {
	name: "CARD_NOT_SET",
	httpCode: 400,
	message: "Missing billing method",
	description:
		"This action can not be performed without a valid payment method on file. Please add a payment method and try again.",
	type: "api",
};
