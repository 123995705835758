import * as React from "react";
import { SVGProps } from "react";

const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.397 7.536 7.35 2.363 8.679 1 16 8.5 8.68 16l-1.331-1.364 5.048-5.172H0V7.536h12.397Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgArrowRight;
