import { useStore } from "zustand";
import {
	type RosterStore,
	createRosterStore,
} from "../../utils/store/roster-store";
import { createContext, ReactNode, useContext, useRef } from "react";

export type RosterStoreApi = ReturnType<typeof createRosterStore>;

export const RosterStoreContext = createContext<RosterStoreApi | undefined>(
	undefined,
);

export interface RosterStoreProviderProps {
	children: ReactNode;
}

export const RosterStoreProvider: React.FC<RosterStoreProviderProps> = ({
	children,
}) => {
	const storeRef = useRef<RosterStoreApi>();
	if (!storeRef.current) {
		storeRef.current = createRosterStore();
	}

	return (
		<RosterStoreContext.Provider value={storeRef.current}>
			{children}
		</RosterStoreContext.Provider>
	);
};

export const useRosterStore = <T,>(selector: (store: RosterStore) => T): T => {
	const rosterStoreContext = useContext(RosterStoreContext);

	if (!rosterStoreContext) {
		throw new Error(`useRosterStore must be used within RosterStoreProvider`);
	}

	return useStore(rosterStoreContext, selector);
};
