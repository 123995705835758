import { SVGProps } from "react";

const SvgTwitch = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clip-path="url(#clip0_2375_136417)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.4769 13.9093H8.34623L6.2609 16H4.17556V13.9093H0.351562V2.784L1.39156 0H15.6476V9.73867L11.4769 13.9093ZM14.2609 9.04533V1.392H2.78356V11.4773H5.91423V13.5627L7.99956 11.4773H11.8236L14.2609 9.04533Z"
				fill="currentcolor"
			/>
			<path
				d="M10.4334 4.17578V8.35178H11.8254V4.17578H10.4334ZM6.60938 8.34645H8.00137V4.17578H6.60938V8.34645Z"
				fill="currentcolor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2375_136417">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default SvgTwitch;
