import * as React from "react";
import { SVGProps } from "react";

const SvgNo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 15.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm0-2a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
			fill="currentcolor"
		/>
		<path d="M2.5 12.5 4 14l9-9-1.5-1.5-9 9Z" fill="currentcolor" />
	</svg>
);

export default SvgNo;
