import * as React from "react";
import { SVGProps } from "react";

const SvgPencil = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m8.814 3.909 3.777 3.778L3.777 16.5H0v-3.778l8.814-8.814Zm1.26-1.259L11.961.76a.89.89 0 0 1 1.259 0l2.518 2.52a.89.89 0 0 1 0 1.258L13.85 6.427 10.073 2.65Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgPencil;
