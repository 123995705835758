import { Invoice } from "../schemas";

/**
 * Compute the amount of money that should be withheld from a paid invoice and
 * keep as a fee.
 */
export const computeFee = (amount: number, isFastPay: boolean) => {
	if (isFastPay) {
		const percentToTake = 2.5;
		return Math.round(amount * (percentToTake / 100));
	} else {
		return 0;
	}
};

/**
 * Get an invoices status in a format suitable to display to a user.
 */
export const getInvoiceStatusDisplayName = (invoice: Invoice) => {
	// Show "paid" for invoices that were paid by fastpay but may not have been
	// paid by the invoicee yet
	if (invoice.payoutSentAt) {
		return "paid";
	}

	if (invoice.status === "open") {
		return "sent";
	}

	return invoice.status;
};

/**
 * Format an amount in cents into a string with a dollar sign, commas, and two
 * decimal places.
 *
 * Example: 1000 -> $10.00
 * Example: 100000 -> $1,000.00
 */
export const formatAmount = (amount: number) => {
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});
	return formatter.format(amount / 100);
};
