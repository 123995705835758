import { z } from "zod";

export const UpdateBusinessSchema = z.object({
	name: z.string().min(1).max(255),
	whatWeDo: z.string().min(1).max(1024),
	funFact: z.string().min(1).max(1024),
	hasCompletedOnboarding: z.boolean(),
});
export type UpdateBusiness = z.infer<typeof UpdateBusinessSchema>;

export const BusinessPlatformSchema = z.enum([
	"instagram",
	"tiktok",
	"youtube",
]);
export type BusinessPlatform = z.infer<typeof BusinessPlatformSchema>;

export const BusinessCompensationTypeSchema = z.enum([
	"direct",
	"affiliate",
	"gifted",
]);
export type BusinessCompensationType = z.infer<
	typeof BusinessCompensationTypeSchema
>;

export const BusinessSearchSizeSchema = z.enum([
	"100k",
	"500k",
	"1m",
	"infinity",
]);
export type BusinessSearchSize = z.infer<typeof BusinessSearchSizeSchema>;

export const UpdateCampaignSchema = z.object({
	uuid: z.string().uuid(),

	isPaused: z.boolean(),

	platforms: BusinessPlatformSchema.array(),
	compensationTypes: BusinessCompensationTypeSchema.array(),

	searchKeywords: z.string(),
	searchSize: BusinessSearchSizeSchema.array(),

	modelCreators: z.string(),

	outreachMessage: z.string(),
});
export type UpdateCampaign = z.infer<typeof UpdateCampaignSchema>;

export const LeadSocialsSchema = z.object({
	instagram: z.string().nullish(),
	tiktok: z.string().nullish(),
	youtube: z.string().nullish(),
});
export type LeadSocials = z.infer<typeof LeadSocialsSchema>;

export const AdminUpdateLeadSchema = z.object({
	uuid: z.string().uuid(),
	email: z.string(),
	name: z.string(),
	stage: z.enum(["contacted", "interested", "accepted", "denied"]),
	socials: LeadSocialsSchema.optional(),
	mediaKitUrl: z.string().nullish(),
});
export type AdminUpdateLead = z.infer<typeof AdminUpdateLeadSchema>;

