import * as React from "react";
import { SVGProps } from "react";

const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m3.74 14.097 9.455-5.158a.5.5 0 0 0 0-.878L3.74 2.903a.5.5 0 0 0-.739.44V13.657a.5.5 0 0 0 .74.439Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgPlay;
