import * as React from "react";
import { SVGProps } from "react";

const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.444 12.778 15 11.222l-7-7-7 7 1.556 1.556L8 7.333l5.444 5.445Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgChevronUp;
