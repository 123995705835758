import * as React from "react";
import { SVGProps } from "react";

const SvgPaypalLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#paypal-logo_svg__a)">
			<g clipPath="url(#paypal-logo_svg__b)">
				<path
					d="M15.995.5H.005A.005.005 0 0 0 0 .505v15.99c0 .003.002.005.005.005h15.99a.005.005 0 0 0 .005-.005V.505A.005.005 0 0 0 15.995.5Z"
					fill="currentcolor"
				/>
				<path
					d="m11.781 6.275-6.134 6.694h-2.25a.265.265 0 0 1-.25-.313l1.5-9.5a.438.438 0 0 1 .437-.375h3.813c2.625.094 3.344 1.438 2.875 3.5l.01-.006Z"
					fill="#002C8A"
				/>
				<path
					d="M11.881 5.656c.938.5 1.156 1.438.844 2.688-.406 1.844-1.625 2.625-3.406 2.656l-.5.031c-.188 0-.313.125-.344.313l-.406 2.469a.438.438 0 0 1-.438.374H5.756a.265.265 0 0 1-.25-.312l.688-4.469c.031-.156 5.687-3.75 5.687-3.75Z"
					fill="#009BE1"
				/>
				<path
					d="m6.156 9.625.625-3.969a.437.437 0 0 1 .407-.343h3c.718 0 1.25.125 1.687.343-.156 1.375-.813 3.594-4 3.657H6.5a.364.364 0 0 0-.344.312Z"
					fill="#001F6B"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="paypal-logo_svg__a">
				<rect y={0.5} width={16} height={16} rx={4} fill="currentcolor" />
			</clipPath>
			<clipPath id="paypal-logo_svg__b">
				<rect y={0.5} width={16} height={16} rx={2} fill="currentcolor" />
			</clipPath>
		</defs>
	</svg>
);

export default SvgPaypalLogo;
