import * as React from "react";
import { SVGProps } from "react";

const SvgVenmo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.825 1.5c.51.84.738 1.706.738 2.8 0 3.488-2.978 8.019-5.394 11.2h-5.52L1.438 2.264l4.834-.459 1.175 9.418C8.54 9.44 9.89 6.64 9.89 4.733c0-1.045-.18-1.756-.46-2.34l4.395-.893Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgVenmo;
