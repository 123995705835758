import * as React from "react";
import { SVGProps } from "react";

const SvgLink = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.655 6.845a3.56 3.56 0 0 1 .008 5.03l-.008.008-1.575 1.575a3.566 3.566 0 0 1-5.038 0 3.566 3.566 0 0 1 0-5.038l.87-.87a.375.375 0 0 1 .64.249c.014.415.089.833.226 1.236a.377.377 0 0 1-.088.389l-.307.307c-.657.657-.678 1.726-.027 2.39a1.69 1.69 0 0 0 2.398.011l1.575-1.574a1.687 1.687 0 0 0-.242-2.588.376.376 0 0 1-.163-.295.934.934 0 0 1 .274-.699l.494-.493a.377.377 0 0 1 .482-.04c.172.119.333.254.481.402Zm3.303-3.303a3.566 3.566 0 0 0-5.038 0L6.345 5.117a3.56 3.56 0 0 0 .481 5.441c.15.105.353.089.482-.04l.494-.494a.934.934 0 0 0 .274-.699.376.376 0 0 0-.163-.295 1.687 1.687 0 0 1-.242-2.587l1.575-1.575a1.69 1.69 0 0 1 2.398.012c.65.663.63 1.732-.027 2.39l-.307.306a.377.377 0 0 0-.088.39c.137.402.212.82.227 1.235.012.326.409.48.64.249l.87-.87a3.566 3.566 0 0 0 0-5.038Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgLink;
