import * as React from "react";
import { SVGProps } from "react";

const SvgCardIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 .5a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4H4Zm9 11.556V7.61H3v4.445c0 .117.053.23.146.314.094.083.221.13.354.13h9c.133 0 .26-.047.354-.13a.421.421 0 0 0 .146-.314ZM3 6.722h10V4.944a.421.421 0 0 0-.146-.314.534.534 0 0 0-.354-.13h-9a.533.533 0 0 0-.354.13.421.421 0 0 0-.146.314v1.778Zm6.5 4.445v-.89h2v.89h-2Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCardIcon;
