import { z } from "zod";
import { ApiErrorSchema } from ".";

export const AgencyPaymentsErrorSchema = ApiErrorSchema.extend({
	name: z.enum([
		"APPLICATION_ALREADY_CREATED",
		"AGENCY_ALREADY_ONBOARDED",
		"COULD_NOT_CREATE_APPLICATION",
		"UNIT_WEBHOOK_MISSING_AGENCY_UUID_TAG",
		"CUSTOMER_ALREADY_CREATED_FOR_AGENCY",
		"UNEXPECTED_UNIT_WEBHOOK_FORMAT",
		"ACCOUNT_ALREADY_CREATED_FOR_AGENCY",
		"INVALID_BANK_ACCOUNT_INFO_FORMAT",
	]),
});
export type AgencyPaymentsError = z.infer<typeof AgencyPaymentsErrorSchema>;

export const CouldNotCreateApplication: AgencyPaymentsError = {
	name: "COULD_NOT_CREATE_APPLICATION",
	httpCode: 500,
	message: "An application could not be creaed",
	description:
		"We could not create an July Payments application. Please try again later and contact support if the error persists",
	type: "api",
};

export const ApplicationAlreadyCreated: AgencyPaymentsError = {
	name: "APPLICATION_ALREADY_CREATED",
	httpCode: 409,
	message: "An application has already been created by this agency",
	description:
		"There is currently an open application, please use that one instead of creating a new one",
	type: "api",
};

export const AgencyAlreadyOnboarded: AgencyPaymentsError = {
	name: "AGENCY_ALREADY_ONBOARDED",
	httpCode: 409,
	message: "Agency already onboarded to July payments",
	description:
		"Your agency has already onboarded to July payments and can not create a new application",
	type: "api",
};

export const UnitWebhookEventMissingTag = (
	webhookId: string,
): AgencyPaymentsError => {
	return {
		name: "UNIT_WEBHOOK_MISSING_AGENCY_UUID_TAG",
		httpCode: 500,
		message: `Webhook with id ${webhookId} is missing agencyUuid stag`,
		type: "api",
	};
};

export const UnitCustomerAlreadyCreated: AgencyPaymentsError = {
	name: "CUSTOMER_ALREADY_CREATED_FOR_AGENCY",
	httpCode: 500,
	message: "A unit customer was already created for this agency",
	type: "api",
};

export const UnitAccountAlreadyCreated: AgencyPaymentsError = {
	name: "ACCOUNT_ALREADY_CREATED_FOR_AGENCY",
	httpCode: 500,
	message: "A unit account was already created for this agency",
	type: "api",
};

export const UnexpectedUnitWebhookFormat = (
	webhookType: string,
): AgencyPaymentsError => {
	return {
		name: "UNEXPECTED_UNIT_WEBHOOK_FORMAT",
		httpCode: 500,
		message: `Unexpected webhook format for event with type ${webhookType}`,
		type: "api",
	};
};

export const InvalidBankAccountInfoFormat: AgencyPaymentsError = {
	name: "INVALID_BANK_ACCOUNT_INFO_FORMAT",
	httpCode: 500,
	message: "Unable to retrieve bank account information",
	description:
		"We were unable to fetch your account information, please contact support or try again later.",
	type: "api",
};
