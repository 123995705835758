import { JSXElementConstructor, useMemo } from "react";
import { Info, Check, Warning, X } from "@withjuly/julycons/bold";
import { cx } from "../classnames";
import * as RadixToast from "@radix-ui/react-toast";
import { UseToastProps, useToast } from "../hooks";

export interface ToastProps {
	variant?: "neutral" | "success" | "warning" | "error";
	title: string;
	description?: string;
	icon?: JSXElementConstructor<{ className?: string }>;
	onClose?: () => void;
}

export const Toast: React.FC<ToastProps> = ({
	variant = "neutral",
	title,
	description,
	icon,
}) => {
	const Icon = useMemo(() => {
		if (icon) {
			return icon;
		}

		if (variant === "neutral") {
			return Info;
		} else if (variant === "success") {
			return Check;
		} else {
			return Warning;
		}
	}, [icon]);

	return (
		<RadixToast.Root
			className={cx(
				"font-repro rounded-solis-xl flex min-w-[228px] max-w-[512px] items-center justify-between gap-2 py-3 pl-4 pr-3 backdrop-blur-lg",
				"data-[state=open]:animate-toast-slide-in data-[state=closed]:animate-hide",
				"data-[swipe=end]:animate-toast-swipe-out data-[swipe=cancel]:translate-x-0 data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:transition-[transform_200ms_ease-out]",
				variant === "neutral" &&
					"border-stroke-secondary bg-sky-alpha-4 border",
				variant === "success" && "border-green-alpha-4 bg-green-alpha-4 border",
				variant === "warning" &&
					"border-yellow-alpha-4 bg-yellow-alpha-4 border",
				variant === "error" && "border-red-alpha-4 bg-red-alpha-4 border",
			)}
		>
			<div className="flex items-center gap-3">
				<Icon
					className={cx(
						"mt-[3px] h-4 min-h-4 w-4 min-w-4 self-start",
						variant === "neutral" && "text-sky-8",
						variant === "success" && "text-green-8",
						variant === "warning" && "text-yellow-8",
						variant === "error" && "text-red-8",
					)}
				/>
				<div>
					<RadixToast.Title className="text-paragraph-md">
						{title}
					</RadixToast.Title>
					<RadixToast.Description
						className={`text-paragraph-xs ${
							variant === "neutral"
								? "text-text-primary"
								: variant === "success"
									? "text-green-10"
									: variant === "warning"
										? "text-yellow-9"
										: variant === "error"
											? "text-red-10"
											: ""
						}`}
					>
						{description}
					</RadixToast.Description>
				</div>
			</div>
			<RadixToast.Close className="text-text-secondary hover:bg-surface-hover-2 flex h-8 w-8 items-center justify-center rounded-full transition-colors">
				<X />
			</RadixToast.Close>
		</RadixToast.Root>
	);
};

interface ControlledToastProps extends UseToastProps {
	id: string;
}

export const ControlledToast: React.FC<ControlledToastProps> = ({
	render,
	...rest
}) => {
	if (render) {
		return render(rest);
	} else {
		return <Toast {...rest} />;
	}
};

export const ToastProvider = () => {
	const { toasts } = useToast();

	return (
		<RadixToast.Provider>
			{toasts.map((props) => {
				return <ControlledToast key={props.id} {...props} />;
			})}
			<RadixToast.Viewport className="fixed bottom-0 right-0 z-[1008] m-0 flex max-w-[100vw] list-none flex-col gap-4 p-[var(--viewport-padding)] outline-none [--viewport-padding:_2rem]" />
		</RadixToast.Provider>
	);
};
