import * as Popover from "@radix-ui/react-popover";
import { JSXElementConstructor, forwardRef, useState } from "react";
import { BaseInputProps } from "./Input";
import { Label } from "./Label";
import { cx } from "../classnames";
import { ScrollArea } from "./ScrollArea";
import { Tag } from "./Tag";
import { Plus } from "@withjuly/julycons/bold";

interface DropdownInputProps extends BaseInputProps {
	options: string[];
	onOptionSelected?: (value: string) => void;
	leadingIcon?: JSXElementConstructor<{ className?: string }>;
	customOptions?: string[];
	selectedOptions?: string[];
	onCreateClicked?: (value: string) => void;
	isCreateEnabled?: boolean;
}

export const SearchCategoryInput = forwardRef<
	HTMLInputElement,
	DropdownInputProps
>((otherProps, ref) => {
	const {
		name,
		size = "md",
		error,
		label,
		rounded,
		placeholder,
		value,
		onChange,
		disabled,
		className,
		maxLength,
		autofocus,
		customOptions,
		selectedOptions,
		isCreateEnabled = true,
	} = otherProps;

	const [showDropdown, setShowDropdown] = useState(() => false);

	return (
		<Popover.Root open={showDropdown} modal={false}>
			<div className="font-repro group flex w-full flex-col gap-2">
				{label ? (
					<Label size="xs" variant="overline" color="secondary">
						{label}
					</Label>
				) : null}
				<Popover.Trigger asChild={true}>
					<div
						className={cx(
							"focus-within:border-brand bg-slate-alpha-2 relative flex items-center justify-center gap-2 border-[2px] border-transparent",
							disabled && "opacity-40 hover:cursor-not-allowed",
							rounded && "rounded-full",
							!rounded && "rounded-solis-md",
							size === "md" && "h-10 pl-3 pr-2",
							size === "lg" && "h-12 pl-[18px] pr-[10px]",
							size === "xl" && "h-14 pl-[18px] pr-[10px]",
							error && "border-red-7 focus-within:border-red-7",
							className,
						)}
					>
						{otherProps.leadingIcon ? (
							<otherProps.leadingIcon className="text-text-secondary h-4 min-h-4 w-4 min-w-4" />
						) : null}
						<input
							name={name}
							maxLength={maxLength}
							type="text"
							className="placeholder:text-text-placeholder text-paragraph-md hide-calendar h-5 w-full bg-transparent focus:outline-none focus:ring-0 disabled:cursor-not-allowed "
							placeholder={placeholder}
							ref={ref}
							value={value}
							onChange={(e) => {
								if (!showDropdown) {
									setShowDropdown(true);
								}

								onChange?.(e);
							}}
							disabled={disabled}
							autoFocus={autofocus}
							onBlur={() => {
								setTimeout(() => {
									setShowDropdown(() => false);
								}, 300);
							}}
						/>
					</div>
				</Popover.Trigger>
			</div>

			<Popover.Portal className="z-[300] w-full">
				<Popover.Content
					onOpenAutoFocus={(e) => e.preventDefault()}
					onCloseAutoFocus={(e) => e.preventDefault()}
					onPointerDownOutside={() => {
						setShowDropdown(() => false);
					}}
					className="bg-surface-primary border-stroke-primary rounded-solis-md z-[200] flex w-[var(--radix-popover-trigger-width)] transform flex-col border data-[side=bottom]:translate-y-2 data-[side=top]:-translate-y-2"
				>
					<ScrollArea className="h-fit max-h-64 w-full overflow-y-scroll">
						{isCreateEnabled &&
						otherProps.options?.filter(
							(opt) =>
								value &&
								opt
									.replaceAll(" ", "")
									.toLowerCase()
									.includes(value?.toLowerCase().replaceAll(" ", "")),
						).length === 0 ? (
							<button
								className="hover:bg-surface-hover-1 flex h-10 w-full items-center gap-2 px-3 transition-colors"
								onClick={(e) => {
									e.preventDefault();
									if (value) {
										otherProps.onCreateClicked?.(value);
									}
								}}
							>
								<Plus />
								<p className="text-paragraph-md font-repro">Create '{value}'</p>
							</button>
						) : null}
						{otherProps.options
							?.filter(
								(opt) =>
									value &&
									opt
										.replaceAll(" ", "")
										.toLowerCase()
										.includes(value?.replaceAll(" ", "").toLowerCase()),
							)
							.map((option, i) => {
								return (
									<button
										key={`${i}-${option}`}
										className={cx(
											"border-b-stroke-tertiary hover:bg-surface-hover-1 flex h-10 w-full items-center justify-between border-b-[0.5px] px-3 transition-colors last:border-0",
											selectedOptions &&
												selectedOptions.includes(option) &&
												"cursor-not-allowed",
										)}
										onClick={(e) => {
											e.preventDefault();
											otherProps.onOptionSelected?.(option);
											setShowDropdown(() => false);
										}}
										disabled={
											selectedOptions && selectedOptions.includes(option)
										}
									>
										<div className="flex gap-2">
											<p className="text-paragraph-md font-repro max-w-[200px] truncate capitalize">
												{option}
											</p>
											{customOptions && customOptions.includes(option) ? (
												<Tag text="Custom" color="pink" />
											) : null}
										</div>

										{selectedOptions && selectedOptions.includes(option) ? (
											<p className="text-paragraph-sm text-text-tertiary font-repro">
												Already added
											</p>
										) : null}
									</button>
								);
							})}
					</ScrollArea>
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	);
});

SearchCategoryInput.displayName = "SearchCategoryInput";
