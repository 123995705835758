import * as React from "react";
import { SVGProps } from "react";

const SvgLoader = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8 .5a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm0 14.028A6.029 6.029 0 1 0 8 2.471a6.029 6.029 0 0 0 0 12.057Z"
			fill="#333638"
		/>
		<path
			d="M13.657 2.843a8 8 0 0 0-11.314 0l1.394 1.394a6.028 6.028 0 0 1 8.526 0l1.394-1.394Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgLoader;
