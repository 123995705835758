import * as React from "react";
import { SVGProps } from "react";

const SvgPlaceholder = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M15.5 8a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z"
			stroke="currentcolor"
		/>
	</svg>
);

export default SvgPlaceholder;
