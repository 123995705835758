import * as React from "react";
import { SVGProps } from "react";

const SvgCashappLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10.425.5c1.6 0 2.4 0 3.275.275A3.4 3.4 0 0 1 15.725 2.8C16 3.675 16 4.475 16 6.077v4.843c0 1.605 0 2.41-.275 3.275A3.4 3.4 0 0 1 13.7 16.22c-.875.28-1.675.28-3.275.28h-4.85c-1.605 0-2.41 0-3.275-.275A3.4 3.4 0 0 1 .275 14.2C0 13.325 0 12.525 0 10.923V6.074C0 4.47 0 3.665.275 2.8A3.4 3.4 0 0 1 2.3.775C3.175.5 3.975.5 5.575.5h4.85Z"
			fill="#00D632"
		/>
		<path
			d="M10.617 6.45a.317.317 0 0 0 .45 0l.626-.65c.132-.125.125-.35-.015-.485a4.932 4.932 0 0 0-1.68-.96l.197-.95a.332.332 0 0 0-.32-.402h-1.21a.33.33 0 0 0-.32.264l-.175.845c-1.61.083-2.975.9-2.975 2.575 0 1.45 1.127 2.073 2.32 2.5 1.128.43 1.725.59 1.725 1.196 0 .622-.595.987-1.475.987-.8 0-1.64-.268-2.29-.92a.326.326 0 0 0-.46 0l-.675.675a.34.34 0 0 0 0 .48 4.41 4.41 0 0 0 1.948 1.1l-.185.893c-.042.207.11.4.317.402l1.213.01a.33.33 0 0 0 .325-.265l.175-.847c1.937-.13 3.117-1.198 3.117-2.755 0-1.436-1.175-2.04-2.6-2.533-.815-.303-1.52-.51-1.52-1.133 0-.605.657-.845 1.317-.845.84 0 1.648.348 2.176.823l-.005-.005Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCashappLogo;
