import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormProps, UseFormReturn } from "react-hook-form";
import { z } from "zod";

export interface UseZodFormProps<
	TSchema extends z.Schema,
	TFieldValues extends z.infer<TSchema> = z.infer<TSchema>,
> extends UseFormProps {
	schema: TSchema;
	values?: TFieldValues;
	defaultValues?: TFieldValues;
}

export const useZodForm = <
	TSchema extends z.Schema,
	TFieldValues extends z.infer<TSchema> = z.infer<TSchema>,
>({
	schema,
	...rest
}: UseZodFormProps<TSchema, TFieldValues>): UseFormReturn<TFieldValues> => {
	return useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
		...rest,
	});
};
