import * as React from "react";
import { SVGProps } from "react";

const SvgDiscord = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#discord_svg__a)">
			<path
				d="M13.553 3.505a13.334 13.334 0 0 0-3.3-1.005c-.156.276-.298.56-.423.85a12.42 12.42 0 0 0-3.664 0 8.975 8.975 0 0 0-.422-.85A13.43 13.43 0 0 0 2.44 3.507C.351 6.566-.215 9.55.068 12.49A13.36 13.36 0 0 0 4.116 14.5c.328-.436.618-.9.867-1.384a8.65 8.65 0 0 1-1.365-.645c.115-.082.227-.166.335-.249a9.594 9.594 0 0 0 8.094 0c.11.089.222.173.335.25a8.69 8.69 0 0 1-1.368.646c.249.484.539.946.867 1.382a13.3 13.3 0 0 0 4.051-2.01c.332-3.41-.568-6.365-2.379-8.985Zm-8.21 7.176c-.79 0-1.442-.709-1.442-1.58 0-.872.63-1.587 1.439-1.587s1.456.715 1.442 1.586c-.014.872-.636 1.58-1.44 1.58Zm5.315 0c-.79 0-1.44-.709-1.44-1.58 0-.872.63-1.587 1.44-1.587.81 0 1.452.715 1.438 1.586-.014.872-.634 1.58-1.438 1.58Z"
				fill="currentcolor"
			/>
		</g>
		<defs>
			<clipPath id="discord_svg__a">
				<path
					fill="currentcolor"
					transform="translate(0 .5)"
					d="M0 0h16v16H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgDiscord;
