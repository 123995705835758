import * as React from "react";
import { SVGProps } from "react";

const SvgCashapp = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M11.784 5.511a.46.46 0 0 0 .654 0l.909-.945c.192-.181.181-.508-.022-.705a7.17 7.17 0 0 0-2.442-1.395l.287-1.38A.483.483 0 0 0 10.705.5H8.946a.48.48 0 0 0-.465.385l-.254 1.228c-2.34.12-4.325 1.309-4.325 3.743 0 2.108 1.64 3.013 3.373 3.634 1.638.625 2.507.858 2.507 1.737 0 .905-.865 1.436-2.144 1.436-1.163 0-2.384-.39-3.329-1.338a.473.473 0 0 0-.668 0l-.982.982a.494.494 0 0 0 0 .697c.764.752 1.73 1.298 2.832 1.6L5.222 15.9a.482.482 0 0 0 .462.585l1.762.015a.48.48 0 0 0 .472-.385l.255-1.232c2.815-.189 4.53-1.74 4.53-4.005 0-2.085-1.707-2.965-3.779-3.68-1.184-.44-2.21-.742-2.21-1.647 0-.88.957-1.228 1.916-1.228 1.221 0 2.395.505 3.161 1.195l-.007-.007Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCashapp;
