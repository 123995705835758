import * as React from "react";
import { SVGProps } from "react";

const SvgShopifyLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.367 3.58a.155.155 0 0 0-.14-.13c-.059-.004-1.198-.022-1.198-.022s-.952-.925-1.046-1.019c-.094-.094-.278-.066-.35-.044l-.478.148a3.36 3.36 0 0 0-.23-.563C9.588 1.303 9.09.96 8.49.96h-.002c-.042 0-.083.004-.125.007A1.257 1.257 0 0 0 7.31.5c-.776.023-1.548.583-2.173 1.577-.44.7-.776 1.578-.87 2.259l-1.527.473c-.45.14-.463.155-.522.578-.044.32-1.219 9.41-1.219 9.41l9.85 1.703 4.27-1.061L13.367 3.58Zm-3.705-.914-.765.236c-.005-.392-.052-.938-.235-1.41.588.111.878.777 1 1.174Zm-1.28.396-1.645.51c.16-.61.46-1.215.83-1.613a1.66 1.66 0 0 1 .56-.406c.214.447.26 1.08.255 1.509ZM7.326 1.017a.79.79 0 0 1 .466.122c-.21.108-.412.265-.602.468-.492.529-.87 1.348-1.02 2.14l-1.35.418C5.085 2.92 6.13 1.05 7.325 1.017Z"
			fill="#95BF47"
		/>
		<path
			d="M13.227 3.45c-.058-.004-1.197-.021-1.197-.021s-.953-.925-1.047-1.02a.233.233 0 0 0-.132-.06v14.15l4.27-1.06L13.366 3.58a.156.156 0 0 0-.14-.13Z"
			fill="#5E8E3E"
		/>
		<path
			d="M8.485 5.646 7.989 7.5s-.553-.252-1.209-.21c-.962.06-.972.667-.962.819.052.83 2.235 1.01 2.358 2.954.096 1.529-.811 2.575-2.119 2.657-1.569.1-2.433-.826-2.433-.826l.333-1.415s.87.657 1.565.613a.615.615 0 0 0 .601-.66c-.068-1.083-1.846-1.019-1.958-2.797-.095-1.496.888-3.013 3.057-3.15.835-.053 1.263.16 1.263.16Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgShopifyLogo;
