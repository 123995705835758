import { Loader } from "@withjuly/julycons";
import { PropsWithChildren, JSXElementConstructor } from "react";

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	size?: "xs" | "sm" | "md" | "lg" | "xl";
	variant?: "primary" | "danger" | "secondary" | "blank" | "outline";
	leadingIcon?: JSXElementConstructor<{ className?: string }>;
	trailingIcon?: JSXElementConstructor<{ className?: string }>;
	isLoading?: boolean;
	className?: string;
}

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
	size = "md",
	variant = "primary",
	leadingIcon: LeadingIcon,
	trailingIcon: TrailingIcon,
	isLoading,
	children,
	className,
	onClick,
	...rest
}) => {
	const innerClassName = [
		"font-repro flex items-center justify-center rounded-full transition-all disabled:cursor-not-allowed disabled:opacity-30 overflow-clip relative",
		size === "xs" ? "text-button-xs h-6 gap-1 px-2" : "",
		size === "sm" ? "text-button-sm h-8 px-3 gap-2" : "",
		size === "md" ? "text-button-sm px-4 h-10 gap-2" : "",
		size === "lg" ? "text-button-md h-12 gap-3 px-6" : "",
		size === "xl" ? "text-button-lg h-14 gap-3 px-6" : "",
	].join(" ");

	const buttonClassName = [
		"font-repro flex items-center justify-center rounded-full transition-all disabled:cursor-not-allowed disabled:opacity-30 group relative rounded-full disabled:cursor-not-allowed",
		variant === "primary"
			? "bg-brand hover:bg-sky-8 disabled:hover:bg-brand/30 disabled:bg-brand/30 text-text-inverse active:bg-brand"
			: "",
		variant === "danger"
			? "bg-text-error hover:bg-red-8 text-text-primary disabled:hover:bg-text-error disabled:hover:bg-text-error/30 disabled:bg-text-error/30 active:bg-text-error"
			: "",
		variant === "secondary"
			? "bg-surface-hover-1 hover:bg-surface-hover-2 text-text-primary disabled:hover:bg-surface-hover-1 active:bg-surface-hover-1"
			: "",
		variant === "blank"
			? "hover:bg-surface-hover-2 text-text-primary disabled:hover:bg-transparent active:bg-surface-hover-1"
			: "",
		variant === "outline"
			? "hover:bg-surface-hover-2 text-text-primary border-stroke-secondary border bg-transparent disabled:hover:bg-transparent active:bg-surface-hover-1"
			: "",
	].join(" ");

	const iconClassName = [
		size === "xs" ? "h-3 w-3" : "",
		size === "sm" ? "h-[14px] w-[14px]" : "",
		size === "md" ? "h-[14px] w-[14px]" : "",
		size === "lg" ? "h-[14px] w-[14px]" : "",
		size === "xl" ? "h-5 w-5" : "",
	].join(" ");

	return (
		<button
			disabled={isLoading || rest.disabled}
			className={`${buttonClassName} ${className}`}
			onClick={(e) => onClick?.(e)}
			{...rest}
		>
			{isLoading ? (
				<div className="absolute left-0 top-0 flex h-full w-full animate-spin items-center justify-center">
					<Loader className="text-[#ffffff]" />
				</div>
			) : null}
			<div className={`${innerClassName} ${isLoading ? "opacity-0" : ""}`}>
				{LeadingIcon ? <LeadingIcon className={iconClassName} /> : null}
				{children}
				{TrailingIcon ? <TrailingIcon className={iconClassName} /> : null}
			</div>
		</button>
	);
};
