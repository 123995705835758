/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from "axios";
import { ApiError } from "@withjuly/fabric";
import { TRPCClientError } from "@trpc/client";

/**
 * Attempt to transform an unknown error into a known one. Returns null if the
 * error type cannot be determined.
 */
export const getApiError = (error: unknown): ApiError | null => {
	if (
		axios.isAxiosError(error) &&
		error.response?.data?.error?.type === "api"
	) {
		return error.response.data.error as ApiError;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} else if (
		(error as Error)?.name === "TRPCClientError" &&
		(error as TRPCClientError<any>).data?.type === "api"
	) {
		return (error as TRPCClientError<any>).data as ApiError;
	}

	return null;
};
