import { z } from "zod";
import { Platform } from "./profile";

export const ExtensionCompanyQueryResponseSchema = z.array(
	z.object({
		uuid: z.string().uuid(),
		name: z.string(),
		awwCount: z.number(),
	}),
);
export type ExtensionCompanyQueryResponse = z.infer<
	typeof ExtensionCompanyQueryResponseSchema
>;

export const ExtensionAwwCreateSchema = z.object({
	postUrl: z.string().url(),
	username: z.string(),
	caption: z.string(),
	engagement: z.number(),
	size: z.number(),
	postedAt: z.optional(z.string()),
	imageUrl: z.optional(z.string()),
	company: z.object({
		value: z.string().uuid(),
		label: z.string(),
	}),
});
export type ExtensionAwwCreate = z.infer<typeof ExtensionAwwCreateSchema>;

export const ExtensionAwwSchema = z.object({
	uuid: z.string().uuid(),
	postUrl: z.string().url(),
	username: z.string(),
	caption: z.string(),
	engagement: z.number(),
	size: z.number(),
	postedAt: z.optional(z.date()),
	imageUrl: z.optional(z.string()),
	company: z.object({
		value: z.string().uuid(),
		label: z.string(),
	}),
});
export type ExtensionAww = z.infer<typeof ExtensionAwwSchema>;

export const GetAwwImageUploadRequestSchema = z.object({
	fileType: z.string(),
});
export type GetAwwImageUploadRequest = z.infer<
	typeof GetAwwImageUploadRequestSchema
>;

export const ExtensionCreateCompanyRequestSchema = z.object({
	name: z.string(),
	description: z.optional(z.string()),
	url: z.optional(z.string().url()),
	campaignName: z.string(),
	lastActive: z.optional(z.string()),
	industries: z.string(),
	profileUsername: z.string(),
	profilePlatform: z.nativeEnum(Platform),
});
export type ExtensionCreateCompanyRequest = z.infer<
	typeof ExtensionCreateCompanyRequestSchema
>;
