import { z } from "zod";
import { UploadedFileSchema } from "./upload";

export const UpsertTemplateSchema = z.object({
	emoji: z
		.string()
		.regex(/\p{Emoji}/u)
		.optional(),
	name: z.string().max(128),
	subject: z.string(),
	body: z.string(),
	attachments: z.array(z.string().uuid()).max(4),
});
export type UpsertTemplateRequest = z.infer<typeof UpsertTemplateSchema>;

export const EmailTemplateSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	emoji: z
		.string()
		.regex(/\p{Emoji}/u)
		.optional(),
	name: z.string(),
	subject: z.optional(z.string()),
	body: z.string(),
	isAvailableInFreeTier: z.boolean(),
	attachments: z.array(UploadedFileSchema),
});
export type EmailTemplate = z.infer<typeof EmailTemplateSchema>;

export const DefaultTemplateSchema = z.object({
	uuid: z.string().uuid().optional(),
	emoji: z.string().regex(/\p{Emoji}/u),
	name: z.string(),
	section: z.string(),
	description: z.string(),
	requiresUpgrade: z.boolean(),
	subject: z.string().optional(),
	body: z.string().optional(),
	order: z.number().default(0),
	attachments: z.array(UploadedFileSchema).default([]),
});
export type DefaultTemplate = z.infer<typeof DefaultTemplateSchema>;

export interface TemplateVariableData {
	displayName: string;
	description: string;
	defaultValue?: string;
	example: {
		before: string;
		after?: string;
	};
}

export const templateVariables: Record<string, TemplateVariableData> = {
	company_name: {
		displayName: "Company Name",
		description: "automatically fills in the name of the brand you're pitching",
		defaultValue: "Mejuri",
		example: {
			before: "Hey ",
			after: " team! I'm reaching out to to ask...",
		},
	},
	my_name: {
		displayName: "My Name",
		description: "automatically fills in your name",
		example: {
			before: "My name is ",
			after: " and I'm reaching out...",
		},
	},
	instagram_handle: {
		displayName: "Instagram Handle",
		description: "automatically fills in your instagram handle",
		example: {
			before: "My instagram handle is ",
		},
	},
	instagram_followers: {
		displayName: "Instagram Followers",
		description: "automatically fills in your instagram follower count",
		example: {
			before: "I have ",
			after: " followers on Instagram",
		},
	},
	instagram_engagement: {
		displayName: "Instagram Engagement",
		description: "automatically fills in your instagram engagement rate",
		example: {
			before: "I have an engagement rate of ",
			after: "% on Instagram",
		},
	},
	media_kit: {
		displayName: "Media Kit",
		description: "automatically fills in a link to your media kit",
		example: {
			before: "You can find my media kit here: ",
		},
	},
};
