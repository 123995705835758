import moment from "moment";
import { formatAmount } from "./payments";

export const formatOxfordCompanyList = (items: string[]): string => {
	const [first, second, third] = items;

	if (items.length === 1 && first) {
		return first;
	} else if (items.length === 2 && first && second) {
		return `${first} and ${second}`;
	}

	let start = items.slice(0, Math.min(items.length - 1, 2)).join(", ");

	if (items.length === 3) {
		start += `, and ${third}`;
	} else if (items.length === 4) {
		start += `, and one other company`;
	} else if (items.length > 4) {
		start += `, and ${items.length - 2} other companies`;
	}

	return start;
};

export const formatOxfordList = (items: string[]): string => {
	const [first, second] = items;

	if (items.length === 1 && first) {
		return first;
	} else if (items.length === 2 && first && second) {
		return `${first} and ${second}`;
	}

	let start = items.slice(0, items.length - 1).join(", ");

	if (items.length === 3) {
		start += `, and ${items[items.length - 1]}`;
	}

	return start;
};

export const nonNull = <T>(value: T): value is NonNullable<T> => {
	return value !== null && value !== undefined;
};

export const formatFollowerCount = (followerCount: number): string => {
	if (followerCount < 10_000) {
		return followerCount.toLocaleString();
	} else if (followerCount < 100_000) {
		const thousands = Math.round(followerCount / 100) / 10;
		return `${thousands.toLocaleString()}k`;
	} else if (followerCount < 1_000_000) {
		const thousands = Math.round(followerCount / 1000);
		return `${thousands.toLocaleString()}k`;
	} else {
		const millions = Math.round(followerCount / 100_000) / 10;
		return `${millions.toLocaleString()}m`;
	}
};

export const formatDurationAsHMS = (seconds: number) => {
	const duration = moment.duration(seconds, "seconds");

	// Build the format string conditionally
	let formatted = "";
	if (duration.hours() > 0) {
		formatted += duration.hours() + "h";
	}
	if (duration.minutes() > 0 || formatted.length > 0) {
		// Include minutes if hours are present or minutes are greater than zero
		formatted += duration.minutes() + "m";
	}
	if (duration.seconds() > 0 || formatted.length > 0) {
		// Include seconds if minutes/hours are present or seconds are greater than zero
		formatted += duration.seconds() + "s";
	}

	return formatted;
};

export const isLightTiktokLogo = (backgroundColor: string) => {
	const lightLogoContrast = contrastRatio(backgroundColor, "255 255 255");
	const darkLogoContrast = contrastRatio(backgroundColor, "0 0 0");

	return lightLogoContrast > darkLogoContrast;
};

const contrastRatio = (colorA: string, colorB: string) => {
	const luminanceA = luminance(colorA) + 0.05;
	const luminanceB = luminance(colorB) + 0.05;

	return luminanceA > luminanceB
		? luminanceA / luminanceB
		: luminanceB / luminanceA;
};

const luminance = (color: string) => {
	const [r, g, b] = color
		.split(" ")
		.map((v) => Number(v))
		.map((num) => (isNaN(num) ? 0 : num));

	const intensityR = intensity(r ?? 0 / 255);
	const intensityG = intensity(g ?? 0 / 255);
	const intensityB = intensity(b ?? 0 / 255);

	return 0.2126 * intensityR + 0.7152 * intensityG + 0.0722 * intensityB;
};

const intensity = (color: number) => {
	if (color <= 0.03928) {
		return color / 12.92;
	}
	return Math.pow((color + 0.055) / 1.055, 2.4);
};

export const toDollarsAndCents = (num: number) => {
	const dollars = formatAmount(num || 0).split(".")[0];
	const cents = `.${formatAmount(num || 0).slice(-2)}`;
	return [dollars, cents];
};

type Currency = {
	symbol: string;
	name: string;
	code: string;
};

export const CURRENCY_LIST: Currency[] = [
	{
		symbol: "$",
		name: "United States Dollar",
		code: "USD",
	},
	{
		symbol: "CA$",
		name: "Canadian Dollar",
		code: "CAD",
	},
	{
		symbol: "£",
		name: "British Pound Sterling",
		code: "GBP",
	},
	{
		symbol: "€",
		name: "Euro",
		code: "EUR",
	},
	{
		symbol: "CHF",
		name: "Swiss Franc",
		code: "CHF",
	},
	{
		symbol: "¥",
		name: "Japanese Yen",
		code: "JPY",
	},
	{
		symbol: "Rs.",
		name: "Indian Rupee",
		code: "INR",
	},
	{
		symbol: "AU$",
		name: "Australian Dollar",
		code: "AUD",
	},
	{
		symbol: "CN¥",
		name: "Chinese Yuan",
		code: "CNY",
	},
];
