import { z } from "zod";

export const UserReferralClaimInviteSchema = z.object({
	email: z.string().email().max(512),
	code: z.string().min(1).max(64),
});
export type UserReferralClaimInviteRequest = z.infer<
	typeof UserReferralClaimInviteSchema
>;

export const ReferralRewardName = z.enum([
	"free_month",
	"wishlist_credits",
	"20_gift",
	"merch",
	"500_gift",
]);

export type ReferralRewardName = z.infer<typeof ReferralRewardName>;

export interface ReferralReward {
	amount: number;
	name: ReferralRewardName;
	displayName: string;
	rewardType: string;
	claimText: string;
}

export const REFERRAL_REWARDS: ReferralReward[] = [
	{
		amount: 1,
		name: "free_month",
		displayName: "Free Month for you and a friend",
		rewardType: "automatic",
		claimText: "1 free month on Career was credited to your July account.",
	},
	{
		amount: 2,
		name: "wishlist_credits",
		displayName: "5 Wishlist Credits",
		rewardType: "automatic",
		claimText: "5 Wishlist Credits were added to your July account.",
	},
	{
		amount: 5,
		name: "20_gift",
		displayName: "$20 Gift 💸",
		rewardType: "manual",
		claimText: "We’ll send you an email with instructions to claim your $20",
	},
	{
		amount: 20,
		name: "merch",
		displayName: "Free Merch Drop",
		rewardType: "manual",
		claimText:
			"We’ll send you an email with instructions for claiming July Merch.",
	},
	{
		amount: 40,
		name: "500_gift",
		displayName: "$500 Gift 💸",
		rewardType: "manual",
		claimText: "We’ll send you an email with instructions to claim your $500",
	},
];

export const UserReferralRewardStatusSchema = z.union([
	z.literal("locked"),
	z.literal("unlocked"),
	z.literal("claimed"),
]);
export type UserReferralRewardStatus = z.infer<
	typeof UserReferralRewardStatusSchema
>;

export const UserReferralRewardSchema = z.object({
	amount: z.number(),
	name: z.string(),
	displayName: z.string(),
	status: UserReferralRewardStatusSchema,
});
export type UserReferralReward = z.infer<typeof UserReferralRewardSchema>;

export const UserReferralInfoSchema = z.object({
	username: z.string(),
	code: z.string(),
	referredCount: z.number(),
	rewards: z.array(UserReferralRewardSchema),
});
export type UserReferralInfo = z.infer<typeof UserReferralInfoSchema>;

export const InviteInfoSchema = z.object({ referrer: z.string() });
export type InviteInfo = z.infer<typeof InviteInfoSchema>;
