import * as React from "react";
import { SVGProps } from "react";

const SvgDoublechevronLeft = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10.31 8.5 15 13.167 13.66 14.5l-6.029-6 6.03-6L15 3.833 10.31 8.5Z"
			fill="currentcolor"
		/>
		<path
			d="m3.68 8.5 4.689 4.667-1.34 1.333L1 8.5l6.029-6 1.34 1.333L3.679 8.5Z"
			fill="currentcolor"
		/>
		<path
			d="M10.31 8.5 15 13.167 13.66 14.5l-6.029-6 6.03-6L15 3.833 10.31 8.5Z"
			stroke="currentcolor"
		/>
		<path
			d="m3.68 8.5 4.689 4.667-1.34 1.333L1 8.5l6.029-6 1.34 1.333L3.679 8.5Z"
			stroke="currentcolor"
		/>
	</svg>
);

export default SvgDoublechevronLeft;
