import * as React from "react";
import { SVGProps } from "react";

const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m8.964 4.103 5.172 5.048 1.364-1.33L8 .5.5 7.82l1.363 1.331 5.173-5.048V16.5h1.928V4.103Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgArrowUp;
