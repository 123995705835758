import { cva, VariantProps } from "class-variance-authority";
import React from "react";

const heading = cva(["font-bold font-agrandir"], {
	variants: {
		variant: {
			h1: ["text-5xl leading-[110%] tracking-[-0.04em]"],
			h2: ["text-[2rem] leading-[110%] tracking-[-0.04em]"],
			h3: ["text-[28px] leading-[110%] tracking-[-0.04em]"],
			h4: ["text-[1.5rem] leading-[115%] tracking-[-0.04em]"],
			h5: ["text-[1.25rem] leading-[110%] tracking-[-0.03em]"],
			h6: ["text-[1rem] leading-[110%] tracking-[-0.03em]"],
		},
	},
});

type HeadingVariantProps = VariantProps<typeof heading>;
export interface HeadingProps
	extends React.ButtonHTMLAttributes<HTMLParagraphElement>,
		Omit<HeadingVariantProps, "variant"> {
	variant: HeadingVariantProps["variant"];
	as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
	({ variant, className, as, ...rest }, ref) => {
		const Tag = as ?? variant ?? "h1";

		return (
			<Tag
				className={heading({
					variant,
					className,
				})}
				{...rest}
				ref={ref}
			/>
		);
	},
);

Heading.displayName = "Heading";
