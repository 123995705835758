import * as React from "react";
import { SVGProps } from "react";

const SvgExpandDotsVertical = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.778 2.278a1.778 1.778 0 1 1-3.556 0 1.778 1.778 0 0 1 3.556 0ZM9.778 8.5a1.778 1.778 0 1 1-3.556 0 1.778 1.778 0 0 1 3.556 0ZM9.778 14.722a1.778 1.778 0 1 1-3.556 0 1.778 1.778 0 0 1 3.556 0Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgExpandDotsVertical;
