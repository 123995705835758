import { X } from "@withjuly/julycons/bold";
import { useState } from "react";
import { Label } from "./Label";

interface TagInputProps {
	tags: string[];
	onTagAdd?: (tag: string) => void;
	onTagRemove?: (tag: string) => void;
	label?: string;
	tooltip?: string;
	description?: string;
	placeholder?: string;
	error?: boolean;
}

export const TagInput: React.FC<TagInputProps> = ({
	tags,
	onTagAdd,
	onTagRemove,
	label,
	tooltip,
	description,
	placeholder,
	error,
}) => {
	const [inputValue, setInputValue] = useState(() => "");

	return (
		<div className="flex w-full flex-col gap-2">
			{label ? (
				<Label size="xs" tooltip={tooltip} variant="overline" color="secondary">
					{label}
				</Label>
			) : null}
			<div className="font-repro focus-within:border-brand bg-slate-alpha-2 rounded-solis-md relative flex min-h-10 w-full flex-wrap items-center gap-1 gap-2 border-[2px] border-transparent px-3 py-[6px]">
				<input
					className="placeholder:text-text-placeholder text-paragraph-md block h-5 w-6 grow truncate bg-transparent focus:outline-none focus:ring-0 disabled:cursor-not-allowed"
					placeholder={placeholder}
					value={inputValue}
					onChange={(e) => {
						setInputValue(() => e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
							onTagAdd?.(inputValue);
							setInputValue(() => "");
						}
					}}
				/>
			</div>
			{description ? (
				<p
					className={`${
						error ? "text-red-7" : "text-text-tertiary"
					} text-paragraph-xs`}
				>
					{description}
				</p>
			) : null}
			<div className="flex flex-wrap gap-1">
				{tags.map((tag, i) => {
					return (
						<div key={`${tag}-${i}`} className="my-1 flex h-5 items-center">
							<div className="bg-sky-alpha-2 text-paragraph-sm text-brand flex h-[28px] items-center justify-center gap-1 rounded-full px-[10px]">
								{tag}
								<button
									onClick={(e) => {
										e.preventDefault();
										onTagRemove?.(tag);
									}}
								>
									<X className="h-[14px] w-[14px]" />
								</button>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
