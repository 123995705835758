import * as React from "react";
import { SVGProps } from "react";

const SvgStar = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m8 1.474 1.832 4.405a.947.947 0 0 0 .8.58l4.755.382-3.623 3.104a.948.948 0 0 0-.305.939l1.107 4.64-4.072-2.486a.947.947 0 0 0-.988 0l-4.071 2.487 1.107-4.641a.947.947 0 0 0-.306-.94L.613 6.842l4.756-.382a.947.947 0 0 0 .799-.58L8 1.474Z"
			stroke="currentcolor"
			strokeWidth={0.947}
		/>
	</svg>
);

export default SvgStar;
