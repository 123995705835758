import * as React from "react";
import { SVGProps } from "react";

const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M14 7.5C12.5 1 5.177 1.544 3.331 4.766"
			stroke="currentcolor"
			strokeWidth={1.5}
			strokeLinecap="round"
		/>
		<path
			d="M5.7 5.082 2.543 6.478a.312.312 0 0 1-.438-.28L2.031 2.75a.312.312 0 0 1 .48-.27l3.23 2.053a.312.312 0 0 1-.042.549Z"
			fill="currentcolor"
		/>
		<path
			d="M2 9.5c1 6.5 8.823 5.956 10.668 2.734"
			stroke="currentcolor"
			strokeWidth={1.5}
			strokeLinecap="round"
		/>
		<path
			d="m10.3 11.92 3.155-1.398a.312.312 0 0 1 .438.279l.077 3.449a.312.312 0 0 1-.48.27l-3.23-2.052a.312.312 0 0 1 .04-.549Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgRefresh;
