export type AudienceFilterName =
	| "isSmallAudience"
	| "isMediumAudience"
	| "isMediumLargeAudience"
	| "isLargeAudience"
	| "isHugeAudience"
	| "isMassiveAudience";

export const AudienceFilters: Record<
	AudienceFilterName,
	(size: number) => boolean
> = {
	isSmallAudience: (size: number) => size >= 0 && size <= 100_000,
	isMediumAudience: (size: number) => size > 100_000 && size <= 250_000,
	isMediumLargeAudience: (size: number) => size > 250_000 && size <= 500_000,
	isLargeAudience: (size: number) => size > 500_000 && size <= 750_000,
	isHugeAudience: (size: number) => size > 700_000 && size <= 1_000_000,
	isMassiveAudience: (size: number) => size > 1_000_000,
};
