import * as React from "react";
import { SVGProps } from "react";

const SvgNote = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m10.667 10.278-.104.006a.89.89 0 0 0-.779.779l-.006.104V16.5H.888A.885.885 0 0 1 0 15.617V1.383C0 .896.396.5.883.5h14.234c.487 0 .883.397.883.888v8.89h-5.333ZM16 12.056l-4.444 4.441v-4.441H16Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgNote;
