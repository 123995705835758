import * as React from "react";
import { SVGProps } from "react";

const SvgBank = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M0 14.9h16v1.6H0v-1.6Zm1.6-6.4h1.6v5.6H1.6V8.5Zm4 0h1.6v5.6H5.6V8.5Zm3.2 0h1.6v5.6H8.8V8.5Zm4 0h1.6v5.6h-1.6V8.5ZM0 4.5l8-4 8 4v3.2H0V4.5Zm8 .8a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgBank;
