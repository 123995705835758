import * as React from "react";
import { SVGProps } from "react";

const SvgCoffee = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x={1}
			y={1.468}
			width={10.818}
			height={14.064}
			rx={1.072}
			fill="currentcolor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.659 3.632H9.384V5.53h2.99c.888 0 1.608.72 1.608 1.608v1.106c0 .888-.72 1.608-1.608 1.608h-2.99v1.893h3.275a3.216 3.216 0 0 0 3.216-3.216V6.848a3.216 3.216 0 0 0-3.216-3.216Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCoffee;
