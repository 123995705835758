import * as React from "react";
import { SVGProps } from "react";

const SvgLock = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.377 6.201h1.459c.194 0 .379.078.516.216a.739.739 0 0 1 .213.52v8.827a.739.739 0 0 1-.213.52.726.726 0 0 1-.516.216H2.164a.726.726 0 0 1-.516-.215.739.739 0 0 1-.214-.52V6.936c0-.195.077-.382.214-.52a.726.726 0 0 1 .516-.216h1.459V4.914c0-1.17.461-2.293 1.282-3.121A4.359 4.359 0 0 1 8 .5c1.16 0 2.274.465 3.095 1.293a4.433 4.433 0 0 1 1.282 3.12v1.288ZM7.27 11.89v1.668h1.46V11.89a1.483 1.483 0 0 0 .159-2.441 1.451 1.451 0 0 0-1.777 0 1.483 1.483 0 0 0-.46 1.73c.123.3.34.55.618.711Zm3.648-5.688V4.914c0-.78-.307-1.529-.855-2.08A2.906 2.906 0 0 0 8 1.97c-.774 0-1.516.31-2.063.862a2.955 2.955 0 0 0-.855 2.08v1.288h5.836Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgLock;
