import * as React from "react";
import { SVGProps } from "react";

const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M4.6 4.3V2.2a.7.7 0 0 1 .7-.7h8.4a.7.7 0 0 1 .7.7V12a.7.7 0 0 1-.7.7h-2.1v2.1c0 .386-.315.7-.705.7h-8.39a.702.702 0 0 1-.705-.7L1.802 5c0-.386.315-.7.705-.7H4.6ZM3.202 5.7 3.2 14.1h7V5.7H3.202ZM6 4.3h5.6v7H13V2.9H6v1.4Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCopy;
