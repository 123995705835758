import * as React from "react";
import { SVGProps } from "react";

const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3.603 7.536 8.65 2.363 7.321 1 0 8.5 7.32 16l1.331-1.364-5.048-5.172H16V7.536H3.603Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgArrowLeft;
