import { ReactNode } from "react";
import { cx } from "../classnames";
import { Label as RadixLabel } from "../components/Label";
import * as Select from "@radix-ui/react-select";
import React from "react";
import { CaretDown, CaretUp, Check } from "@withjuly/julycons/bold";

export interface RootProps extends Select.SelectProps {
	size?: "md" | "lg" | "xl";
	label?: string;
	description?: string;
	tooltip?: string;
	placeholder?: string;
	icon?: ReactNode;
	className?: string;
}

export const Root: React.FC<RootProps> = ({
	size = "md",
	label,
	description,
	tooltip,
	placeholder,
	children,
	icon,
	className,
	...rest
}) => {
	return (
		<Select.Root {...rest}>
			<div className="font-repro flex w-full flex-col gap-2">
				{label ? (
					<RadixLabel
						variant="overline"
						color="secondary"
						size="xs"
						tooltip={tooltip}
					>
						{label}
					</RadixLabel>
				) : null}
				<div>
					<Select.Trigger
						className={cx(
							className,
							"bg-slate-alpha-2 rounded-solis-md text-paragraph-md data-[placeholder]:text-text-placeholder flex w-full items-center justify-between gap-2 px-3",
							size === "md" && "h-10",
							size === "lg" && "h-12",
							size === "xl" && "h-14",
							rest.disabled && "opacity-40",
						)}
					>
						<div
							className={cx(
								"font-repro flex items-center gap-2 whitespace-nowrap",
							)}
						>
							{icon ? icon : null}
							<Select.Value placeholder={placeholder} />
						</div>
						<CaretDown className="text-text-primary" />
					</Select.Trigger>
				</div>
				{description ? (
					<p className="text-paragraph-sm text-text-tertiary">{description}</p>
				) : null}
			</div>
			<Select.Portal className="rounded-solis-md border-brand //border-stroke-primary bg-surface-primary w-full overflow-clip rounded border">
				<Select.Content className="z-[100]">
					<Select.ScrollUpButton className="flex h-6 items-center justify-center">
						<CaretUp />
					</Select.ScrollUpButton>
					<Select.SelectViewport>{children}</Select.SelectViewport>
					<Select.ScrollDownButton className="flex h-6 items-center justify-center">
						<CaretDown />
					</Select.ScrollDownButton>
				</Select.Content>
			</Select.Portal>
		</Select.Root>
	);
};

export const Group: React.FC<Select.SelectGroupProps> = (props) => {
	return (
		<Select.Group
			{...props}
			className="border-b-stroke-tertiary border-b pt-2 last:border-b-0"
		/>
	);
};

interface LabelProps extends Omit<Select.SelectLabelProps, "children"> {
	label: string;
	icon?: ReactNode;
}

export const Label: React.FC<LabelProps> = ({ label, icon, ...rest }) => {
	return (
		<Select.Label {...rest} className="px-3">
			<RadixLabel
				variant="overline"
				color="secondary"
				size="xs"
				className="flex items-center gap-1"
			>
				{icon ?? null} {label}
			</RadixLabel>
		</Select.Label>
	);
};

interface ItemProps extends Select.SelectItemProps {
	icon?: ReactNode;
}

export const Item = React.forwardRef<
	React.ElementRef<React.FC<ItemProps>>,
	React.ComponentPropsWithoutRef<React.FC<ItemProps>>
>(({ icon, children, ...props }, forwardedRef) => {
	return (
		<Select.Item
			className="last:border-b-none border-b-stroke-tertiary hover:bg-surface-hover-1 font-repro //border-b-[0.5px] my-1 flex h-8 cursor-pointer items-center justify-between px-3"
			{...props}
			ref={forwardedRef}
		>
			<div className="flex w-full items-center gap-2">
				{icon ? icon : null}
				<Select.ItemText className="text-paragpah-md text-text-primary">
					{children}
				</Select.ItemText>
			</div>
			<Select.ItemIndicator className="SelectItemIndicator">
				<Check />
			</Select.ItemIndicator>
		</Select.Item>
	);
});
