import confetti from "canvas-confetti";
import { useCallback } from "react";
import { color, zIndex } from "../theme";

const randomInRange = (min: number, max: number) => {
	return Math.random() * (max - min) + min;
};

export const useConfetti = () => {
	const reward = useCallback(() => {
		const duration = 2.5 * 1000;
		const end = Date.now() + duration;

		const frame = () => {
			confetti({
				spread: 180,
				startVelocity: 4,
				particleCount: 2,
				ticks: 1000,
				shapes: ["square"],
				origin: {
					x: Math.random(),
					y: -0.02,
				},
				colors: [
					color.blue[500],
					color.blue[500],
					color.blue[500],
					color.notification[400],
					color.warning[200],
					color.success[400],
					color.danger[400],
				].sort(() => Math.random() - 0.5),
				gravity: randomInRange(1.5, 2.5),
				scalar: randomInRange(0.4, 1.2),
				drift: randomInRange(-0.5, 0.5),
				zIndex: zIndex.confetti,
			});

			// keep going until we are out of time
			if (Date.now() < end) {
				requestAnimationFrame(frame);
			}
		};

		frame();
	}, []);

	return reward;
};
