import * as Dialog from "@radix-ui/react-dialog";
import { ModalContent } from "./Content";
import { modalSizes } from "../../theme";

type SizeKey = keyof typeof modalSizes;
export type ModalSize = typeof modalSizes[SizeKey];

export interface ModalProps
	extends Omit<Dialog.DialogProps, "open" | "onOpenChange" | "children"> {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;

	content?: React.ReactNode;
	size?: ModalSize;

	// Don't use base modal styling, use children as modal content
	passThroughChildren?: boolean;

	closeable?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
	isOpen,
	setIsOpen,
	content,
	size,
	passThroughChildren = false,
	closeable = true,
	...rest
}) => {
	return (
		<Dialog.Root open={isOpen} onOpenChange={setIsOpen} modal {...rest}>
			{content && (
				<ModalContent
					size={size}
					passThroughChildren={passThroughChildren}
					onPointerDownOutside={(e) => {
						if (!closeable) {
							e.preventDefault();
						}
					}}
					onInteractOutside={(e) => {
						if (!closeable) {
							e.preventDefault();
						}
					}}
				>
					{content}
				</ModalContent>
			)}
		</Dialog.Root>
	);
};
