import { PropsWithChildren } from "react";
import { cx } from "../classnames";
import { Tooltip } from "./Tooltip";

interface LabelProps {
	size?: "2xs" | "xs" | "sm" | "md" | "lg";
	color?: "primary" | "secondary" | "tertiary";
	variant?: "overline" | "paragraph" | "bold";
	tooltip?: string;
	className?: string;
}

export const Label: React.FC<PropsWithChildren<LabelProps>> = ({
	size = "md",
	color = "primary",
	variant = "paragraph",
	tooltip,
	className,
	children,
}) => {
	let textColor = "text-text-primary";
	if (color === "tertiary") {
		textColor = "text-text-tertiary";
	} else if (color === "secondary") {
		textColor = "text-text-secondary";
	}

	return (
		<div className="font-repro relative flex items-center">
			<p
				className={`font-repro ${textColor} ${cx(
					variant === "overline" && "uppercase",
					variant === "overline" && size === "2xs" && "text-overline-2xs",
					variant === "overline" && size === "xs" && "text-overline-xs",
					variant === "overline" && size === "sm" && "text-overline-sm",
					variant === "overline" && size === "md" && "text-overline-md",
					variant === "overline" && size === "lg" && "text-overline-lg",
					variant === "paragraph" && size === "2xs" && "text-paragraph-2xs",
					variant === "paragraph" && size === "xs" && "text-paragraph-xs",
					variant === "paragraph" && size === "sm" && "text-paragraph-sm",
					variant === "paragraph" && size === "md" && "text-paragraph-md",
					variant === "paragraph" && size === "lg" && "text-paragraph-lg",
					variant === "bold" && size === "2xs" && "text-button-2xs",
					variant === "bold" && size === "xs" && "text-button-xs",
					variant === "bold" && size === "sm" && "text-button-sm",
					variant === "bold" && size === "md" && "text-button-md",
					variant === "bold" && size === "lg" && "text-button-lg",
					className,
				)}`}
			>
				{children}
			</p>
			{tooltip ? (
				<div className="relative ml-[2px] flex w-6 items-center justify-center">
					<div className="absolute">
						<Tooltip tooltip={tooltip} />
					</div>
				</div>
			) : null}
		</div>
	);
};
