import * as React from "react";
import { SVGProps } from "react";

const SvgQueue = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1 10.5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM1 14.5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Z"
			fill="currentcolor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 1.5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H2Zm1.5 2A.5.5 0 0 0 3 4v1a.5.5 0 0 0 .5.5h9A.5.5 0 0 0 13 5V4a.5.5 0 0 0-.5-.5h-9Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgQueue;
