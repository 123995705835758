import * as React from "react";
import { SVGProps } from "react";

const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m8.964 12.897 5.172-5.048 1.364 1.33L8 16.5.5 9.18l1.363-1.331 5.173 5.048V.5h1.928v12.397Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgArrowDown;
