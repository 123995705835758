import * as TabsPrimitive from "@radix-ui/react-tabs";
import React, { createContext, useContext, useRef, useState } from "react";
import { CSSProperties } from "react";
import { cx } from "../classnames";

interface ExclusionTabContextData {
	repositionHighlight: (rect: DOMRect) => void;
	wrapperRef?: React.RefObject<HTMLDivElement>;
	highlightRef?: React.RefObject<HTMLDivElement>;
	highlightStyles?: CSSProperties;
}

const ExclusionTabContext = createContext<ExclusionTabContextData>({
	repositionHighlight: () => {
		// Do nothing
	},
});
const useExclusionTab = () => useContext(ExclusionTabContext);

export const ExclusionTabs = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
>(({ ...props }, ref) => {
	const [hasHighlightedTab, setHighlightedTab] = useState<boolean>(false);
	const [tabBoundingBox, setTabBoundingBox] = useState<DOMRect>();
	const [wrapperBoundingBox, setWrapperBoundingBox] = useState<DOMRect>();
	const [isHoveredFromNull, setIsHoveredFromNull] = useState(true);

	const wrapperRef = useRef<HTMLDivElement>(null);
	const highlightRef = useRef<HTMLDivElement>(null);

	const repositionHighlight = (rect: DOMRect) => {
		setTabBoundingBox(rect);
		setWrapperBoundingBox(wrapperRef.current?.getBoundingClientRect());
		setIsHoveredFromNull(!hasHighlightedTab);
		setHighlightedTab(true);
	};

	const resetHighlight = () => setHighlightedTab(false);

	const highlightStyles: CSSProperties = {};
	if (tabBoundingBox && wrapperBoundingBox) {
		highlightStyles.transitionDuration = isHoveredFromNull ? "0ms" : "150ms";
		highlightStyles.opacity = hasHighlightedTab ? 1 : 0;
		highlightStyles.width = `${tabBoundingBox.width}px`;
		highlightStyles.height = `${tabBoundingBox.height}px`;
		highlightStyles.transform = `translate(${
			tabBoundingBox.left - wrapperBoundingBox.left
		}px)`;
	}

	return (
		<ExclusionTabContext.Provider
			value={{ repositionHighlight, wrapperRef, highlightRef, highlightStyles }}
		>
			<TabsPrimitive.Root ref={ref} onMouseLeave={resetHighlight} {...props} />
		</ExclusionTabContext.Provider>
	);
});
ExclusionTabs.displayName = TabsPrimitive.Trigger.displayName;

export const ExclusionTabsList: React.FC<
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
> = ({ className, children, ...props }) => {
	const { wrapperRef, highlightRef, highlightStyles } = useExclusionTab();

	return (
		<TabsPrimitive.List
			ref={wrapperRef}
			className={cx("relative flex gap-2", className)}
			{...props}
		>
			<TabsHighlight ref={highlightRef} style={highlightStyles} />
			{children}
		</TabsPrimitive.List>
	);
};
ExclusionTabsList.displayName = TabsPrimitive.List.displayName;

export const ExclusionTabsTrigger = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
	const { repositionHighlight } = useExclusionTab();

	return (
		<TabsPrimitive.Trigger
			ref={ref}
			onMouseOver={(e) =>
				repositionHighlight(e.currentTarget.getBoundingClientRect())
			}
			onFocus={(e) =>
				repositionHighlight(e.currentTarget.getBoundingClientRect())
			}
			className={cx(
				"hover:text-white-100 z-10 rounded-md px-3 py-2 text-gray-200 transition-all ease-out",
				className,
			)}
			{...props}
		/>
	);
});
ExclusionTabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

export const TabsHighlight: React.FC<React.HTMLProps<HTMLDivElement>> = (
	props,
) => {
	return (
		<div
			className="absolute left-0 top-0 rounded-md bg-gray-400 transition-all ease-out"
			{...props}
		/>
	);
};
