import Script from "next/script";

export const GoogleAnalytics = () => {
	return (
		<>
			<Script
				strategy="afterInteractive"
				src="https://www.googletagmanager.com/gtag/js?id=G-CTLJ733EC0"
			/>
			<Script id="google-analytics" strategy="afterInteractive">
				{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-CTLJ733EC0', {
                page_path: window.location.pathname,
              });
				`}
			</Script>

			<noscript>
				<iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-PQ6KWGV"
					height="0"
					width="0"
					style={{ display: "none", visibility: "hidden" }}
				/>
			</noscript>
		</>
	);
};
