import * as React from "react";
import { SVGProps } from "react";

const SvgCard = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M16 6.9v8a.8.8 0 0 1-.8.8H.8a.8.8 0 0 1-.8-.8v-8h16Zm0-1.6H0V2.1a.8.8 0 0 1 .8-.8h14.4a.8.8 0 0 1 .8.8v3.2Zm-5.6 6.4v1.6h3.2v-1.6h-3.2Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCard;
