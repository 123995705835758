import * as React from "react";
import { SVGProps } from "react";

const SvgHeartFullAdd = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.2 9.1v1.8H14v1.2h-1.8v1.8H11v-1.8H9.2v-1.2H11V9.1h1.2Zm.745-5.546A3.6 3.6 0 0 1 13.25 8.3a3.6 3.6 0 0 0-4.836 4.876l-.415.415-5.086-5.095A3.6 3.6 0 0 1 8 3.417a3.599 3.599 0 0 1 4.945.137Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgHeartFullAdd;
