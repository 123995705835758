import * as React from "react";
import { SVGProps } from "react";

const SvgAddBox = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M.889.5H15.11a.889.889 0 0 1 .889.889V15.61a.889.889 0 0 1-.889.889H.89A.889.889 0 0 1 0 15.611V1.39A.889.889 0 0 1 .889.5ZM7.11 7.611H3.556V9.39H7.11v3.555H8.89V9.39h3.555V7.61H8.89V4.056H7.11V7.61Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgAddBox;
