import * as React from "react";
import { SVGProps } from "react";

const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.118 2.092.784 13.967a1 1 0 0 0 .883 1.47h12.666a1 1 0 0 0 .883-1.47L8.882 2.092a1 1 0 0 0-1.764 0Zm1.51 8.966.124-5.42h-1.45l.12 5.42h1.206Zm-1.188 2.1c.162.16.357.24.585.24.15 0 .286-.037.408-.11a.862.862 0 0 0 .3-.3.774.774 0 0 0 .116-.415.775.775 0 0 0-.247-.578.795.795 0 0 0-.577-.24.804.804 0 0 0-.585.24.787.787 0 0 0-.24.578c0 .23.08.425.24.585Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgWarning;
