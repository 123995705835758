import * as Dialog from "@radix-ui/react-dialog";
import { css, keyframes } from "@emotion/react";
import { zIndex } from "../../theme";
import React from "react";
import { cx } from "../../classnames";

const animateIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 0.7;
	}
`;

const animateOut = keyframes`
	from {
		opacity: 0.7;
	}

	to {
		opacity: 0;
	}
`;

export const ModalOverlay = React.forwardRef<
	React.ElementRef<typeof Dialog.Overlay>,
	React.ComponentPropsWithoutRef<typeof Dialog.Overlay>
>(({ className, ...props }, ref) => (
	<Dialog.Overlay
		className={cx(
			"fixed inset-0 bg-gray-900 opacity-70 will-change-[opacity]",
			className,
		)}
		style={{
			zIndex: zIndex.dialog,
		}}
		css={css`
			&[data-state="open"] {
				animation: ${animateIn} 100ms ease-in;
			}

			&[data-state="closed"] {
				animation: ${animateOut} 100ms ease-out;
			}
		`}
		{...props}
		ref={ref}
	/>
));

ModalOverlay.displayName = "ModalOverlay";
