import * as React from "react";
import { SVGProps } from "react";

const SvgTiktok = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="14"
		height="16"
		viewBox="0 0 14 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.63615 0H9.96399C10.0744 0 10.1635 0.0904001 10.1687 0.200732C10.2854 2.65463 12.535 3.60471 13.8234 3.8061C13.9241 3.82184 14 3.90681 14 4.00874V6.35304C14 6.4635 13.9103 6.55346 13.7999 6.55064C12.2652 6.51147 11.1367 6.02796 10.53 5.638C10.383 5.54349 10.164 5.64596 10.164 5.82076V11.3191C10.164 12.817 8.97056 14.2695 8.37385 14.8085C7.86238 15.2057 6.46437 16 4.96406 16C3.46375 16 2.34988 15.3191 1.98049 14.9787C1.2417 14.3546 -0.184726 12.6638 0.019861 10.8936C0.616574 6.6383 3.25916 6.21277 4.11161 5.95745C4.69848 5.78167 5.32744 5.8454 5.67251 5.92083C5.75883 5.9397 5.8165 6.01797 5.8165 6.10632V8.44258C5.8165 8.5912 5.65611 8.69538 5.51226 8.65804C5.37844 8.6233 5.21732 8.59574 5.0493 8.59574C3.17392 8.59574 2.74769 10.5532 2.74769 10.8936C2.74769 11.234 3.00343 13.2766 5.13455 13.2766C6.83944 13.2766 7.37932 11.8014 7.43615 11.0638V0.2C7.43615 0.0895431 7.5257 0 7.63615 0Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgTiktok;
