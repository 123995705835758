import { getApiError } from "@withjuly/frontend-common";
import { useToast } from "@withjuly/solisv2";
import { useCallback } from "react";

/**
 * Handle an error response from the API.
 *
 * @returns true if the error was acted on (for example, by showing a toast) and
 * false if not.
 */
export const useApiErrorHandler = (): {
	handleApiError: (e: unknown) => boolean;
} => {
	const { toast } = useToast();

	const handleApiError = useCallback(
		(error: unknown) => {
			let handled = false;
			const apiError = getApiError(error);

			if (apiError && apiError.httpCode >= 400) {
				// Handle server errors with a toast
				toast({
					title: apiError.message,
					description: apiError.description,
					variant: "error",
				});
				handled = true;
			}

			// Otherwise let someone else handle it
			return handled;
		},
		[toast],
	);

	return { handleApiError };
};
