import * as React from "react";
import { SVGProps } from "react";

const SvgHeartEmptyAdd = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.2 9.1v1.8H14v1.2h-1.8v1.8H11v-1.8H9.2v-1.2H11V9.1h1.2Zm.746-5.546a3.6 3.6 0 0 1 .141 4.94l-.852-.85c.799-.914.757-2.348-.139-3.242a2.4 2.4 0 0 0-3.294-.092l-.8.719L7.2 4.31a2.4 2.4 0 0 0-3.412 3.362l5.06 5.068-.848.85-5.087-5.096A3.6 3.6 0 0 1 8 3.417a3.599 3.599 0 0 1 4.945.137Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgHeartEmptyAdd;
