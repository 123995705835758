import * as React from "react";
import { SVGProps } from "react";

const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m7.995 1.358 3.862 3.82-1.015.975L8.785 4v7.952H7.25V4L5.176 6.153l-1.002-.975 3.821-3.82Z"
			fill="currentcolor"
		/>
		<path
			d="M3.5 10H2v4a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-4h-1.5v3.5h-9V10Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgUpload;
