import * as React from "react";
import { SVGProps } from "react";

const SvgFile = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#file_svg__a)">
			<path
				d="m9.667 6.722-.104-.006a.889.889 0 0 1-.779-.779l-.006-.104V.5h-6.89A.885.885 0 0 0 1 1.383v14.234c0 .487.396.883.883.883h12.234a.885.885 0 0 0 .883-.888v-8.89H9.667ZM15 4.944 10.556.503v4.441H15Z"
				fill="currentcolor"
			/>
		</g>
		<defs>
			<clipPath id="file_svg__a">
				<path
					fill="currentcolor"
					transform="translate(0 .5)"
					d="M0 0h16v16H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgFile;
