import * as React from "react";
import { SVGProps } from "react";

const SvgDislike = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M11.382 2.195V9.46a1 1 0 0 1-.106.447l-2.469 4.939a1.186 1.186 0 0 1-2.226-.743l.694-3.817a.5.5 0 0 0-.492-.59H2.319c-.89 0-1.469-.936-1.07-1.732a.598.598 0 0 0 0-.536l-.143-.285a1 1 0 0 1 0-.894l.17-.342a1 1 0 0 0 .106-.447v-.528a1 1 0 0 1 .106-.447l.288-.578a1 1 0 0 0 .106-.447v-.585a.76.76 0 0 1 .08-.34c.257-.514.783-.839 1.359-.839h7.561a.5.5 0 0 1 .5.5ZM12.382 2.195v7a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgDislike;
