import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { cx } from "../classnames";

interface SliderProps {
	thumbSize?: "xs" | "sm" | "lg";
	tracksSize?: "sm" | "lg";
	trackBackroundColor?: string;
	thumbs?: number;
}

const Slider = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & SliderProps
>(
	(
		{
			className,
			thumbSize = "sm",
			tracksSize = "sm",
			trackBackroundColor,
			thumbs = 1,
			...props
		},
		ref,
	) => (
		<SliderPrimitive.Root
			ref={ref}
			className={cx(
				"relative flex w-full touch-none select-none items-center",
				className,
			)}
			{...props}
		>
			<SliderPrimitive.Track
				className={cx(
					"relative my-1 h-1 w-full grow cursor-pointer overflow-hidden rounded-full bg-gray-700",
					tracksSize === "lg" && "h-3",
					trackBackroundColor,
				)}
			>
				<SliderPrimitive.Range className="absolute h-full bg-blue-500" />
			</SliderPrimitive.Track>

			{Array.from({ length: thumbs }).map((_, i) => (
				<SliderPrimitive.Thumb
					key={i}
					className={cx(
						"block h-4 w-4 cursor-pointer rounded-full bg-blue-300 transition-all active:scale-110 disabled:pointer-events-none",
						thumbSize === "lg" && "h-6 w-6",
						thumbSize === "xs" && "h-3 w-3",
					)}
				/>
			))}
		</SliderPrimitive.Root>
	),
);
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
