import { MediaKitPreview } from "@withjuly/fabric";
import { immer } from "zustand/middleware/immer";
import { createStore } from "zustand/vanilla";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type MediaKitState = {
	mediakits: MediaKitPreview[];
	isLoading: boolean;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type MediaKitActions = {
	// All Mediakits
	setMediakits: (mediakits: MediaKitPreview[]) => void;
	setIsLoading: (isLoading: boolean) => void;
};

export type MediaKitStore = MediaKitState & MediaKitActions;

export const defaultMediaKitState = {
	mediakits: [],
	isLoading: true,
};

export const createMediaKitStore = (
	initState: MediaKitState = defaultMediaKitState,
) => {
	return createStore<MediaKitStore>()(
		immer((set) => ({
			...initState,
			setMediakits: (mediakits) => {
				set((state) => {
					state.mediakits = mediakits;
				});
			},
			setIsLoading: (isLoading) => {
				set((state) => {
					state.isLoading = isLoading;
				});
			},
		})),
	);
};
