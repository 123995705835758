import { z } from "zod";

export const UploadedFileSchema = z.object({
	uuid: z.string(),
	fileName: z.string(),
	size: z.number(),
	fileType: z.string(),
});
export type UploadedFile = z.infer<typeof UploadedFileSchema>;

export const GetFileUploadRequestSchema = z.object({
	fileName: z.string().max(1024),
	fileType: z.string().max(1024),
	size: z.number(),
});
export type GetFileUploadRequest = z.infer<typeof GetFileUploadRequestSchema>;

export const GetFileUploadResponseSchema = z.object({
	url: z.string(),
	fields: z.record(z.string(), z.string()),
	upload: UploadedFileSchema,
});
export type GetFileUploadResponse = z.infer<typeof GetFileUploadResponseSchema>;

export const GetFileDownloadUrlResponseSchema = z.object({
	url: z.string(),
});
export type GetFileDownloadUrlResponse = z.infer<
	typeof GetFileDownloadUrlResponseSchema
>;
