import * as React from "react";
import { SVGProps } from "react";

const SvgCalculator = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1.6.5h12.8a.8.8 0 0 1 .8.8v14.4a.8.8 0 0 1-.8.8H1.6a.8.8 0 0 1-.8-.8V1.3a.8.8 0 0 1 .8-.8Zm2.4 8v1.6h1.6V8.5H4Zm0 3.2v1.6h1.6v-1.6H4Zm3.2-3.2v1.6h1.6V8.5H7.2Zm0 3.2v1.6h1.6v-1.6H7.2Zm3.2-3.2v4.8H12V8.5h-1.6ZM4 3.7v3.2h8V3.7H4Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCalculator;
