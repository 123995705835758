import { cx } from "../classnames";
import * as Tabs from "@radix-ui/react-tabs";

interface FilterTabGroupProps {
	children: React.ReactNode;
	/**
	 * This should be the title of the tab that will be selected by default
	 */
	defaultValue?: string;
}

/**
 * A group of tabs used for filtering, and must wrap FilterTab components
 */
export const FilterTabGroup: React.FC<FilterTabGroupProps> = ({
	defaultValue,
	children,
}) => {
	return (
		<Tabs.Root activationMode="automatic" defaultValue={defaultValue}>
			<Tabs.List className="flex gap-2">{children}</Tabs.List>
		</Tabs.Root>
	);
};

interface FilterTabProps {
	numItems?: number;
	title: string;
	onSelect?: () => void;
	className?: string;
	value?: string;
}

/**
 * A tab used for filtering, and must be wrapped in a FilterTabGroup component
 */
export const FilterTab: React.FC<FilterTabProps> = ({
	numItems,
	title,
	onSelect,
	value,
	className,
}) => {
	return (
		<Tabs.Trigger
			value={value ?? title}
			onFocus={onSelect}
			className={cx(
				"data-[state=active]:text-white-100 group flex items-center justify-center gap-2 rounded-lg px-3 py-2 capitalize text-gray-200 transition-all hover:text-blue-600 data-[state=active]:bg-blue-700 data-[state=active]:hover:brightness-[110%] lg:px-4",
				className,
			)}
		>
			<p>{title}</p>
			{numItems !== undefined ? (
				<p className="text-body-sm group-data-[state=active]:text-white-100 capitalize text-gray-200 group-hover:text-blue-600">
					{numItems}
				</p>
			) : null}
		</Tabs.Trigger>
	);
};
