import * as React from "react";
import { SVGProps } from "react";

const SvgTwitter = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M16 3.54a6.58 6.58 0 0 1-1.886.516 3.294 3.294 0 0 0 1.444-1.816 6.56 6.56 0 0 1-2.085.797A3.282 3.282 0 0 0 7.88 6.03 9.32 9.32 0 0 1 1.114 2.6 3.282 3.282 0 0 0 2.13 6.983a3.272 3.272 0 0 1-1.487-.41v.04a3.285 3.285 0 0 0 2.633 3.22c-.484.13-.99.15-1.483.056a3.285 3.285 0 0 0 3.066 2.28A6.59 6.59 0 0 1 0 13.53a9.286 9.286 0 0 0 5.031 1.476c6.039 0 9.341-5.003 9.341-9.341a9.61 9.61 0 0 0-.01-.424A6.67 6.67 0 0 0 16 3.54Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgTwitter;
