import * as RadixToast from "@radix-ui/react-toast";
import { ToastViewport } from "./Viewport";
import { ControlledToast } from "./Controlled";
import { useToast } from "../../hooks/use-toast";

export const ToastProvider = () => {
	const { toasts } = useToast();

	return (
		<RadixToast.Provider>
			{toasts.map((props) => {
				return <ControlledToast key={props.id} {...props} />;
			})}
			<ToastViewport />
		</RadixToast.Provider>
	);
};
