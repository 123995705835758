import * as React from "react";
import { SVGProps } from "react";

const SvgArrowChipLeft = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="m5 8.5 6-5v10l-6-5Z" fill="currentcolor" />
	</svg>
);

export default SvgArrowChipLeft;
