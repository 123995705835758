import * as React from "react";
import { SVGProps } from "react";

const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#document_svg__a)" fill="currentcolor">
			<path d="M10 4.5v-4l4 4h-4Z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.5.5H2v16h12V6H8.5V.5Z"
			/>
		</g>
		<defs>
			<clipPath id="document_svg__a">
				<path
					fill="currentcolor"
					transform="translate(0 .5)"
					d="M0 0h16v16H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgDocument;
