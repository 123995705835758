import * as React from "react";
import { SVGProps } from "react";

const SvgMoneyIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 16.5a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm-.322-3.7v.999h.66V12.8c.55-.029 1.027-.132 1.435-.31.488-.212.863-.518 1.124-.916.26-.4.392-.877.394-1.433a2.681 2.681 0 0 0-.169-.955 2.189 2.189 0 0 0-.487-.753 2.912 2.912 0 0 0-.79-.563 4.774 4.774 0 0 0-1.08-.379l-.427-.104V5.55c.235.037.426.118.572.243.213.175.332.42.359.735h1.91c-.006-.547-.139-1.03-.4-1.445a2.607 2.607 0 0 0-1.1-.968 3.52 3.52 0 0 0-1.34-.338v-.991h-.661v.992c-.49.035-.935.145-1.334.333-.489.23-.871.549-1.148.96-.277.41-.414.887-.411 1.432-.003.668.201 1.198.612 1.591.411.39.974.673 1.688.848l.593.148v1.94a1.725 1.725 0 0 1-.464-.127 1.186 1.186 0 0 1-.52-.43 1.4 1.4 0 0 1-.213-.71h-1.91c.008.689.155 1.258.44 1.708.284.45.683.786 1.196 1.007.428.184.919.292 1.47.322Zm.66-1.776c.123-.019.235-.05.335-.09a.997.997 0 0 0 .439-.323.795.795 0 0 0 .157-.473.68.68 0 0 0-.145-.426 1.098 1.098 0 0 0-.435-.31 3.255 3.255 0 0 0-.35-.134v1.756Zm-.66-3.809V5.55a1.334 1.334 0 0 0-.31.084.845.845 0 0 0-.387.296.75.75 0 0 0-.133.426.668.668 0 0 0 .072.349c.057.1.137.19.242.267.107.074.236.14.387.197.041.016.084.032.129.046Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgMoneyIcon;
