import { z } from "zod";

export const SubscriptionPlanSchema = z.enum([
	"free",
	"paid",
	"trending",
	"autopilot",
]);
export type SubscriptionPlan = z.infer<typeof SubscriptionPlanSchema>;

export interface PlanInfo {
	plan: SubscriptionPlan;
	price: string;
	name: string;
	description: string;
	order: number;
	features: string[];
}

export const PLANS: Record<SubscriptionPlan, PlanInfo> = {
	free: {
		plan: "free",
		price: "Free",
		name: "Side Hustle",
		description: "For creators looking to get started",
		order: 0,
		features: [
			"5 pitch credits per month",
			"Automated pitching",
			"Free & instant invoicing",
			"Customizable media kit",
			"Deal tracking",
			"Basic outreach template",
		],
	},
	trending: {
		plan: "trending",
		price: "$10/month",
		name: "Trending",
		description: "For creators starting to monetize",
		order: 1,
		features: [
			"Everything in Side Hustle",
			"50 pitch credits per month",
			"Premium outreach templates",
			"See who brands are working with",
			"2 Brand Requests per month",
			"Get paid early with FastPay",
		],
	},
	paid: {
		plan: "paid",
		price: "$30/month",
		name: "Career",
		description: "For creators making $1000+ per month",
		order: 2,
		features: [
			"Everything in Trending",
			"500 pitch credits per month",
			"AI-powered brand outreach ✨",
			"All premium templates",
			"10 Brand Requests per month",
			"1-on-1 expert coaching",
		],
	},
	autopilot: {
		plan: "autopilot",
		price: "$100/month",
		name: "Autopilot",
		description: "For creators ready for the next step",
		order: 3,
		features: [
			"Everything in Career",
			"Personal inbox management",
			"24/7 support",
			"Career strategy consultations",
		],
	},
};

export const getPlanRelation = (from: PlanInfo, to: PlanInfo) => {
	if (from.order > to.order) {
		return "lower";
	} else if (from.order < to.order) {
		return "higher";
	} else {
		return "active";
	}
};

/**
 * Status of the user's subscription in Stripe.
 */
export enum StripeSubscriptionStatus {
	// Set when first payment attempt for the sub fails
	INCOMPLETE = "incomplete",
	// Set when the subscription isn't paid within 23 hours of invoice being sent
	INCOMPLETE_EXPIRED = "incomplete_expired",
	// User is in free trial
	TRIALING = "trialing",
	// All is good
	ACTIVE = "active",
	// Stripe has tried to charge the user but the payment failed.
	// Stripe will retry a few times, then move into canceled or unpaid
	PAST_DUE = "past_due",
	CANCELED = "canceled",
	UNPAID = "unpaid",
}

export const BillingSchema = z.object({
	plan: SubscriptionPlanSchema,
	status: z.nativeEnum(StripeSubscriptionStatus).optional(),
	lastRenewedAt: z.nullable(z.date()),
	periodEnds: z.nullable(z.date()),
	cancelAt: z.nullable(z.date()),
	hasPreviouslySubscribed: z.boolean(),

	lastFour: z.nullable(z.string()),
	expMonth: z.nullable(z.number()),
	expYear: z.nullable(z.number()),
});
export type Billing = z.infer<typeof BillingSchema>;

export const UpdateBillingSchema = z.object({
	paymentMethodId: z.string(),
});
export type UpdateBilling = z.infer<typeof UpdateBillingSchema>;

export const BillingSessionSchema = z.object({
	clientSecret: z.string(),
});
export type BillingSession = z.infer<typeof BillingSessionSchema>;

export const UpdateSubscriptionSchema = z.object({
	plan: SubscriptionPlanSchema,
	isTrial: z.boolean().optional().default(false),
	coupon: z.string().optional(),
});
export type UpdateSubscription = z.infer<typeof UpdateSubscriptionSchema>;
