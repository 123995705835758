import { ImgHTMLAttributes, useMemo, useState } from "react";
import { cx } from "../classnames";
import Image from "next/image";

export interface AvatarProps {
	src?: string;
	alt?: string;
	size: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";
	className?: string;
	useNextImage?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({
	className,
	src,
	alt,
	size,
	useNextImage = true,
}) => {
	const [isError, setIsError] = useState(() => false);

	const dimensions = useMemo(() => {
		if (size === "xs") {
			return 26;
		} else if (size === "sm") {
			return 32;
		} else if (size === "md") {
			return 40;
		} else if (size === "lg") {
			return 48;
		} else if (size === "xl") {
			return 56;
		} else if (size === "2xl") {
			return 64;
		} else if (size === "3xl") {
			return 96;
		}
	}, [size]);

	const ImageCompontent = useNextImage
		? Image
		: (props: ImgHTMLAttributes<HTMLImageElement>) => <img {...props} />;

	return (
		<div
			className={`text-text-primary ${cx(
				className,
				"bg-surface-tertiary text-paragraph-xs rounded-solis-full flex h-6 w-6 items-center justify-center",
				size === "sm" && "h-8 min-h-8 w-8 min-w-8",
				size === "md" && "h-10 min-h-10 w-10 min-w-10",
				size === "lg" && "text-paragraph-md h-12 min-h-12 w-12 min-w-12",
				size === "xl" && "text-paragraph-md h-14 min-h-14 w-14 min-w-14",
				size === "2xl" && "text-paragraph-xl h-16 min-h-16 w-16 min-w-16",
				size === "3xl" && "text-paragraph-xl h-24 min-h-24 w-24 min-w-24",
			)}`}
		>
			{!src || isError ? (
				<p>{alt?.[0]?.toUpperCase() ?? ""}</p>
			) : (
				<ImageCompontent
					width={dimensions}
					height={dimensions}
					className="aspect-square h-full w-full rounded-full object-cover object-center"
					src={src}
					onError={() => setIsError(() => true)}
					alt={""}
				/>
			)}
		</div>
	);
};
