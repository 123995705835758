import { Close } from "@withjuly/julycons";
import { cx } from "../classnames";
import { IconButton } from "./IconButton";
import { useState } from "react";
import { Info } from "@withjuly/julycons/bold";

interface AlertBannerProps {
	variant?: "neutral" | "success" | "error" | "warning";
	icon?: React.FC<{ className?: string }>;
	title: string;
	description?: string;
	controlledIsOpen?: boolean;
	controlledSetIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AlertBanner: React.FC<AlertBannerProps> = ({
	variant = "neutral",
	icon,
	title,
	description,
	controlledIsOpen,
	controlledSetIsOpen,
}) => {
	const [isOpen, setIsOpen] = useState(() => controlledIsOpen ?? true);

	const Icon = icon ? icon : Info;

	return (
		<div
			className={cx(
				"font-repro rounded-solis-xl bg-sky-alpha-4 border-stroke-secondary text-sky-8 flex w-full items-start gap-4 border py-3 pl-[20px] pr-3",
				variant === "success" &&
					"bg-green-alpha-4 border-green-alpha-4 text-green-8",
				variant === "warning" &&
					"border-yellow-alpha-4 border-yellow-alpha-4 text-yellow-8 bg-yellow-alpha-4",
				variant === "error" && "border-red-alpha-4 text-red-8 bg-red-alpha-4",
				!isOpen && "hidden",
			)}
		>
			<div className="h-full pt-[3px]">
				<Icon className="h-4 w-4" />
			</div>
			<div className="flex w-full flex-col">
				<p className="text-paragraph-md text-text-primary">{title}</p>
				<p className="text-paragraph-xs">{description}</p>
			</div>
			<div className="flex h-8 w-8 items-center justify-center self-center">
				<IconButton
					size="sm"
					variant="blank-primary"
					icon={Close}
					onClick={() => {
						if (controlledSetIsOpen) {
							controlledSetIsOpen(() => false);
							setIsOpen(() => false);
						} else {
							setIsOpen(() => false);
						}
					}}
				/>
			</div>
		</div>
	);
};
