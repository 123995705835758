import { z } from "zod";

export enum DealFlowViewType {
	BOARD = "board",
	TABLE = "table",
}

export enum DealFlowFieldType {
	SELECT = "select",
	// Potential future types
	//MULTI_SELECT = "multi_select",
	//TEXT = "text",
	//NUMBER = "number",
	//DATE = "date",
	//PERSON = "person",
	//FILE = "file",
	//CHECKBOX = "checkbox",
}

export const DealFlowFieldValueSchema = z
	.number()
	.or(z.string())
	.or(z.boolean())
	.or(z.null())
	.or(z.date());
export type DealFlowFieldValue = z.infer<typeof DealFlowFieldValueSchema>;

export const CompanyDealFlowInfoSchema = z.object({
	uuid: z.string().uuid(),
	name: z.string(),
	description: z.optional(z.string()),
	url: z.optional(z.string().url()),
	logoUrl: z.optional(z.string().url()),
});
export type CompanyDealFlowMatchInfo = z.infer<
	typeof CompanyDealFlowInfoSchema
>;

export const DealFlowMatchSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	percent: z.number().optional(),
	company: CompanyDealFlowInfoSchema,
});
export type DealFlowMatch = z.infer<typeof DealFlowMatchSchema>;

export const DealFlowItemActionSchema = z.object({
	createdAt: z.date(),
	message: z.string(),
	details: z.optional(z.string()),
});
export type DealFlowItemAction = z.infer<typeof DealFlowItemActionSchema>;

export const DealFlowItemFieldValueSchema = z.object({
	fieldUuid: z.string().uuid(),
	value: DealFlowFieldValueSchema,
});
export type DealFlowItemFieldValue = z.infer<
	typeof DealFlowItemFieldValueSchema
>;

export const DealFlowItemSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	match: DealFlowMatchSchema,
	fieldValues: z.array(DealFlowItemFieldValueSchema),
	actions: z.array(DealFlowItemActionSchema),
});
export type DealFlowItem = z.infer<typeof DealFlowItemSchema>;

export const SelectValuesSchema = z.array(z.string().min(1).max(512));
export type SelectValues = z.infer<typeof SelectValuesSchema>;

export const DealFlowViewSchema = z.object({
	uuid: z.string(),
	createdAt: z.date(),
	updatedAt: z.date(),
	name: z.string().min(1).max(512),
	type: z.nativeEnum(DealFlowViewType),
	boardColumnsFieldUuid: z.optional(z.string().uuid()),
	boardColumnsOrder: z.optional(SelectValuesSchema),
});
export type DealFlowView = z.infer<typeof DealFlowViewSchema>;

export const DealFlowFieldSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	name: z.string().min(1).max(512),
	type: z.nativeEnum(DealFlowFieldType),
	selectValues: z.optional(SelectValuesSchema),
});
export type DealFlowField = z.infer<typeof DealFlowFieldSchema>;

export const SelectValuesDeltaSchema = z.object({
	old: z.optional(z.string().min(1).max(512)),
	new: z.optional(z.string().min(1).max(512)),
});
export type SelectValuesDelta = z.infer<typeof DealFlowFieldSchema>;

export const UpdateDealFlowFieldSchema = z.object({
	type: z.nativeEnum(DealFlowFieldType),
	selectValuesDelta: z.array(SelectValuesDeltaSchema),
});
export type UpdateDealFlowFieldRequest = z.infer<
	typeof UpdateDealFlowFieldSchema
>;

export const DealFlowSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	items: z.array(DealFlowItemSchema),
	views: z.array(DealFlowViewSchema),
	fields: z.array(DealFlowFieldSchema),
});
export type DealFlow = z.infer<typeof DealFlowSchema>;

export enum DealFlowBoardAction {
	DELETE_GROUP,
}

export const UpdateDealFlowBoardSchema = z.object({
	action: z.nativeEnum(DealFlowBoardAction),
	group: z.string(),
});
export type UpdateDealFlowBoardRequest = z.infer<
	typeof UpdateDealFlowBoardSchema
>;

export const UpdateDealFlowItemSchema = z.object({
	fieldUpdates: z.array(
		z.object({
			fieldUuid: z.string().uuid(),
			value: DealFlowFieldValueSchema,
		}),
	),
});
export type UpdateDealFlowItemRequest = z.infer<
	typeof UpdateDealFlowItemSchema
>;

export const UpdateDealFlowViewSchema = z.object({
	boardColumnsOrder: z.optional(SelectValuesSchema),
});
export type UpdateDealFlowViewRequest = z.infer<
	typeof UpdateDealFlowViewSchema
>;

// Future constructs
//export const DealFlowViewSortSchema = z.object({
//	field: z.string(),
//	ascending: z.boolean(),
//});
//export type DealFlowViewSort = z.infer<typeof DealFlowViewSortSchema>;
//
//export const DealFlowViewFilterSchema = z.object({
//	field: z.string(),
//	condition: z.string(),
//	value: DealFlowFieldValueSchema,
//});
//
//export type DealFlowViewFilter = z.infer<typeof DealFlowViewFilterSchema>;
//export const CreateDealFlowViewSchema = z.object({
//	name: z.string().max(128),
//	type: z.nativeEnum(DealFlowViewType),
//	properties: DealFlowViewPropertiesSchema,
//});
//export type CreateDealFlowViewRequest = z.infer<
//	typeof CreateDealFlowViewSchema
//>;
//
//export const UpdateDealFlowViewSchema = CreateDealFlowViewSchema;
//export type UpdateDealFlowViewRequest = CreateDealFlowViewRequest;
//

export const DealFlowPitchRequestSchema = z.object({
	campaignUuids: z.array(z.string().uuid()).max(128),

	subject: z.string(),
	textContent: z.string(),
	htmlContent: z.string(),
	attachmentUuids: z.array(z.string().uuid()).max(4),

	useOnPlatformInbounds: z.optional(z.boolean()),
	isTestPitch: z.optional(z.boolean()),
});
export type DealFlowPitchRequest = z.infer<typeof DealFlowPitchRequestSchema>;

export const EmailQueueItemSchema = z.object({
	uuid: z.string().uuid(),
	status: z.string(),
	companyName: z.string(),
	profilePicUrl: z.optional(z.string().url()),
});
export type EmailQueueItem = z.infer<typeof EmailQueueItemSchema>;

export const EmailQueueStatusSchema = z.enum([
	"PENDING",
	"IN_PROGRESS",
	"FAILED",
	"COMPLETED",
	"CANCELED",
]);
export type EmailQueueStatus = z.infer<typeof EmailQueueStatusSchema>;

export const EmailQueueSchema = z.object({
	uuid: z.string().uuid(),

	createdAt: z.date(),
	updatedAt: z.date(),

	status: EmailQueueStatusSchema,

	items: z.array(EmailQueueItemSchema),
});
export type EmailQueue = z.infer<typeof EmailQueueSchema>;
