import * as React from "react";
import { SVGProps } from "react";

const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="14"
		height="18"
		viewBox="0 0 14 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8.63897 10.275H10.764L11.614 6.875H8.63897V5.175C8.63897 4.2995 8.63897 3.475 10.339 3.475H11.614V0.619C11.3369 0.58245 10.2905 0.5 9.18551 0.5C6.87776 0.5 5.23896 1.90845 5.23896 4.495V6.875H2.68896V10.275H5.23896V17.5H8.63897V10.275Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgFacebook;
