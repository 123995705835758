import { Item } from "@radix-ui/react-radio-group";
import { cx } from "../../classnames";

interface RadioItemProps {
	value: string;
	disabled?: boolean;
	label: string;
}

export const RadioItem: React.FC<RadioItemProps> = ({
	value,
	disabled = false,
	label,
}) => {
	return (
		<label
			className={cx(
				"flex cursor-pointer items-center gap-3 text-sm font-normal",
				disabled && "cursor-not-allowed",
			)}
		>
			<Item
				className={cx(
					"border-white-100 h-4 w-4 rounded-full border-2",
					"data-[state=checked]:relative data-[state=checked]:border-blue-500",
					"data-[state=checked]:after:content-[' '] data-[state=checked]:after:absolute data-[state=checked]:after:left-[2px] data-[state=checked]:after:top-[2px] data-[state=checked]:after:h-[calc(100%-4px)] data-[state=checked]:after:w-[calc(100%-4px)] data-[state=checked]:after:rounded-full data-[state=checked]:after:bg-blue-500",
					"disabled:border-gray-300 disabled:bg-gray-500 data-[state=checked]:disabled:after:bg-gray-300",
					"transition-all duration-200",
				)}
				value={value}
				disabled={disabled}
			/>
			{label}
		</label>
	);
};
