import React from "react";
import { cx } from "../classnames";
import { Label } from "./Label";

interface TextAreaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	label?: string;
	tooltip?: string;
	description?: string;
	error?: boolean;
	rows?: number;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
	(
		{ label, tooltip, description, error, className, rows = 4, ...rest },
		ref,
	) => {
		return (
			<div className={cx("font-repro flex w-full flex-col gap-2", className)}>
				{label ? (
					<Label
						size="xs"
						tooltip={tooltip}
						variant="overline"
						color="secondary"
					>
						{label}
					</Label>
				) : null}
				<div className="rounded-solis-md bg-slate-alpha-2 focus-within:border-brand h-full w-full border-[2px] border-transparent p-1">
					<textarea
						className="placeholder:text-text-placeholder text-paragraph-md block w-full bg-transparent p-2 focus:outline-none focus:ring-0 disabled:cursor-not-allowed"
						rows={rows}
						ref={ref}
						{...rest}
					/>
				</div>
				{description ? (
					<p
						className={`${
							error ? "text-red-7" : "text-text-tertiary"
						} text-paragraph-xs`}
					>
						{description}
					</p>
				) : null}
			</div>
		);
	},
);
