import * as React from "react";
import { SVGProps } from "react";

const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M14 11.447v2.357a.667.667 0 0 1-.62.665c-.291.02-.53.031-.713.031C6.775 14.5 2 9.725 2 3.833c0-.184.01-.422.03-.713a.667.667 0 0 1 .666-.62h2.357a.333.333 0 0 1 .332.3 9.267 9.267 0 0 0 .848 3.035.304.304 0 0 1-.098.378L4.697 7.241a8.698 8.698 0 0 0 4.562 4.563l1.027-1.436a.308.308 0 0 1 .382-.1 9.269 9.269 0 0 0 3.033.847.333.333 0 0 1 .3.332H14Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgPhone;
