import { forwardRef } from "react";
import { cx } from "../../classnames";

export interface LabeledTextInputProps
	extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
	isInvalid?: boolean;
	isSuccess?: boolean;
	align?: "left" | "right";
	size?: "large" | "small";
	label?: string;
}

export const LabeledTextInput = forwardRef<
	HTMLInputElement,
	LabeledTextInputProps
>(
	(
		{ isInvalid, isSuccess, size, disabled, align, label, id, name, ...rest },
		ref,
	) => {
		return (
			<div
				className={cx(
					"focus-within:shadow-20 flex w-full items-center",
					align === "right" && "flex-row-reverse",
				)}
			>
				<div
					className={cx(
						"h-full rounded-l-lg border-y border-l border-gray-200 bg-gray-900 p-1",
						align === "right" &&
							"rounded-l-none rounded-r-lg border-l-[0px] border-r",
					)}
				>
					<div
						className={cx(
							"flex w-full items-center gap-2 rounded",
							size === "large" ? "px-3 py-2" : "px-[0.375rem] py-[0.125rem]",
						)}
					>
						<div className="text-sm text-gray-100">{label}</div>
					</div>
				</div>
				<label
					className={cx(
						"flex w-full overflow-hidden rounded-r-lg border border bg-gray-600 p-1 transition-all duration-100 focus-within:bg-gray-700 focus-within:[&>div]:bg-gray-500",
						disabled ? "cursor-not-allowed" : "cursor-text",
						isInvalid && "border-danger-400 focus-within:border-danger-400",
						isSuccess && "border-success-300 focus-within:border-success-300",
						!isInvalid &&
							!isSuccess &&
							"border-gray-200 focus-within:border-blue-500 ",
						align === "right" && "rounded-l-lg rounded-r-none",
					)}
				>
					<div
						className={cx(
							"flex w-full items-center gap-2 rounded-r",
							size === "large" ? "p-2" : "px-[0.375rem] py-[0.125rem]",
							align === "right" && "rounded-l rounded-r-none",
						)}
					>
						<input
							id={id}
							name={name}
							type="text"
							aria-invalid={isInvalid}
							className="w-full appearance-none border-none bg-inherit text-sm placeholder:text-gray-200 focus:border-none focus:outline-none focus:ring-0 active:border-none active:outline-none disabled:cursor-not-allowed disabled:text-gray-200"
							disabled={disabled}
							{...rest}
							ref={ref}
						/>
					</div>
				</label>
			</div>
		);
	},
);
