import {
	Root,
	Trigger,
	Value,
	Portal,
	Content,
	ScrollDownButton,
	ScrollUpButton,
	Viewport,
} from "@radix-ui/react-select";
import React, { ReactNode } from "react";
import { cx } from "../../../classnames";
import { ChevronDown } from "@withjuly/julycons";

export interface SelectProps {
	name?: string;
	children: ReactNode;
	value: string | undefined;
	defaultValue?: string;
	setValue: (value: string) => void;
	placeholder?: string;
	disabled?: boolean;
	className?: string;
	contentClassName?: string;
	icon?: ReactNode;
}

export const Select = React.forwardRef<
	React.ElementRef<typeof Content>,
	SelectProps
>(
	(
		{
			name,
			children,
			value,
			defaultValue,
			setValue,
			placeholder,
			disabled,
			className,
			contentClassName,
			icon,
		},
		ref,
	) => {
		return (
			<Root
				name={name}
				value={value}
				defaultValue={defaultValue}
				onValueChange={setValue}
			>
				<Trigger
					className={cx(
						"flex w-full items-center justify-between rounded-lg border border-gray-200 bg-gray-600 px-4 py-3 text-sm data-[placeholder]:text-gray-100",
						"outline-none transition-all duration-150 ease-in-out",
						disabled ? "text-gray-200" : "text-white-100",
						className,
					)}
					disabled={disabled}
				>
					<Value placeholder={placeholder} />
					{icon ? icon : <ChevronDown />}
				</Trigger>

				<Portal>
					<Content
						className={cx(
							"z-[1002] rounded-lg border border-gray-200 bg-gray-600 p-1.5",
							contentClassName,
						)}
						ref={ref}
					>
						<ScrollUpButton />
						<Viewport>{children}</Viewport>
						<ScrollDownButton />
					</Content>
				</Portal>
			</Root>
		);
	},
);
