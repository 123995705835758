import * as React from "react";
import { SVGProps } from "react";

const SvgSignOut = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#sign-out_svg__a)" fill="currentcolor">
			<path d="M0 2C0 .8 1 .5 1.5.5H6c.333 0 1 .2 1 1s-.667 1-1 1H2.5c-.4 0-.5.333-.5.5v11c0 .4.333.5.5.5H6c.333 0 1 .2 1 1s-.667 1-1 1H1c-.8 0-1-.667-1-1V2Z" />
			<path d="M5.5 7.5h6L9.707 5.707a1 1 0 0 1 0-1.414l.086-.086a1 1 0 0 1 1.414 0L14.5 7.5c.8.8.333 1.667 0 2l-3.293 3.293a1 1 0 0 1-1.414 0l-.086-.086a1 1 0 0 1 0-1.414L11.5 9.5h-6c-.333 0-1-.2-1-1s.667-1 1-1Z" />
		</g>
		<defs>
			<clipPath id="sign-out_svg__a">
				<path
					fill="currentcolor"
					transform="translate(0 .5)"
					d="M0 0h16v16H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgSignOut;
