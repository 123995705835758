import * as React from "react";
import { SVGProps } from "react";

const SvgFlag = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2 2.5h6.255a.667.667 0 0 1 .596.369l.482.964h4A.667.667 0 0 1 14 4.5v7.333a.666.666 0 0 1-.667.667H9.08a.667.667 0 0 1-.596-.369L8 11.167H3.333v4H2V2.5Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgFlag;
