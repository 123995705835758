import * as React from "react";
import { SVGProps } from "react";

const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m6 11.5 8.666-8.667L16 4.166l-10 10-6-6 1.333-1.332L6 11.5Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCheck;
