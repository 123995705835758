import * as React from "react";
import { SVGProps } from "react";

const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M11.333 2.5H14a.666.666 0 0 1 .667.667v10.666A.666.666 0 0 1 14 14.5H2a.666.666 0 0 1-.667-.667V3.167A.667.667 0 0 1 2 2.5h2.667V1.167H6V2.5h4V1.167h1.333V2.5Zm-8.666 4v6.667h10.666V6.5H2.667ZM4 9.167h3.333v2.666H4V9.167Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCalendar;
