import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { cx } from "../../classnames";

export const ModalDescription = React.forwardRef<
	React.ElementRef<typeof Dialog.Description>,
	React.ComponentPropsWithoutRef<typeof Dialog.Description>
>(({ className, ...props }, ref) => (
	<Dialog.Description
		className={cx("text-sm text-gray-100", className)}
		{...props}
		ref={ref}
	/>
));

ModalDescription.displayName = "ModalDescription";
