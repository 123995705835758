const TiktokDarkLogo = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_1162_26209)">
			<path
				d="M6.65736 6.31852V5.70373C6.4349 5.67356 6.21079 5.65794 5.9864 5.65698C3.24204 5.65698 1.00887 7.80003 1.00887 10.4342C1.00887 12.0497 1.85049 13.4804 3.13391 14.345C2.27453 13.463 1.79643 12.3004 1.79723 11.0928C1.79723 8.49632 3.96666 6.37892 6.65736 6.31852Z"
				fill="#00F2EA"
			/>
			<path
				d="M6.78927 13.1039C7.94963 13.1039 8.89575 12.1635 8.93892 10.9911L8.94296 0.52735H10.8191C10.7789 0.308959 10.7585 0.0872298 10.7581 -0.13501L8.19614 -0.13501L8.19171 10.3291C8.14894 11.5008 7.20201 12.4411 6.04205 12.4411C5.69405 12.4412 5.35124 12.3546 5.04308 12.1888C5.24277 12.4721 5.50518 12.7028 5.80875 12.8619C6.11231 13.021 6.44835 13.1039 6.78927 13.1039ZM14.3227 4.07911V3.49742C13.634 3.49821 12.9598 3.29398 12.3821 2.90953C12.8892 3.50382 13.5701 3.91408 14.3231 4.07911"
				fill="#00F2EA"
			/>
			<path
				d="M12.3059 3.05801C11.7425 2.40538 11.4314 1.56397 11.4312 0.692383H10.7465C10.8361 1.17463 11.0206 1.6331 11.2887 2.03982C11.5568 2.44654 11.9029 2.79299 12.3059 3.05801M5.98157 8.16617C4.79539 8.16782 3.83434 9.14295 3.83313 10.3465C3.83437 10.7454 3.9424 11.1364 4.14545 11.4767C4.3485 11.817 4.63877 12.0937 4.98462 12.2764C4.72071 11.9078 4.57826 11.4624 4.57792 11.0051C4.57913 9.80118 5.54018 8.82563 6.72637 8.82439C6.94827 8.82439 7.16009 8.86163 7.36061 8.92534V6.27755C7.15047 6.24726 6.93858 6.2315 6.72637 6.23039C6.68925 6.23039 6.65253 6.23287 6.61582 6.23329V8.26712C6.41058 8.20085 6.19675 8.16682 5.98157 8.16617"
				fill="#FF004F"
			/>
			<path
				d="M13.9814 4.41577V6.43636C12.7069 6.43831 11.4637 6.0317 10.4257 5.27341V10.5553C10.4257 13.1936 8.31558 15.34 5.72211 15.34C4.71951 15.34 3.78993 15.0181 3.02617 14.4724C3.46708 14.9551 4 15.3397 4.59187 15.6024C5.18375 15.8652 5.82195 16.0005 6.46691 15.9998C9.06078 15.9998 11.1709 13.8535 11.1709 11.2156V5.93328C12.209 6.69143 13.4522 7.09789 14.7266 7.09583V4.49645C14.4761 4.49637 14.2264 4.46933 13.9814 4.41577"
				fill="#FF004F"
			/>
			<path
				d="M10.6835 10.3816V5.09102C11.7518 5.85245 13.0222 6.25912 14.3232 6.25604V4.23297C13.5582 4.07062 12.8638 3.66184 12.3414 3.06629C11.5054 2.52556 10.9284 1.66793 10.7453 0.692383H8.83003L8.82599 11.1242C8.78161 12.2926 7.81532 13.2301 6.63115 13.2301C5.92348 13.2292 5.26018 12.89 4.84784 12.3182C4.12403 11.9405 3.67013 11.1946 3.66973 10.3812C3.67094 9.17316 4.65418 8.19472 5.8686 8.19306C6.09454 8.19306 6.31161 8.2303 6.51737 8.29442V6.25356C3.91463 6.31438 1.81581 8.43798 1.81581 11.0419C1.81581 12.3017 2.30763 13.4481 3.10891 14.3041C3.92257 14.8718 4.88443 15.1745 5.8686 15.1725C8.5234 15.1725 10.6835 13.0232 10.6835 10.3816Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1162_26209">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default TiktokDarkLogo;
