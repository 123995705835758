import * as React from "react";
import { SVGProps } from "react";

const SvgLightning = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.088 1.538a.47.47 0 0 1 .27.505L8.536 7.1h4.008c.173 0 .33.1.407.258a.477.477 0 0 1-.043.489l-5.454 7.466a.448.448 0 0 1-.543.15.47.47 0 0 1-.27-.506L7.464 9.9H3.455c-.173 0-.33-.1-.407-.258a.477.477 0 0 1 .043-.489l5.454-7.466a.447.447 0 0 1 .543-.15Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgLightning;
