export interface CalculatorTerms {
	isExclusive: boolean;
	isRush: boolean;
	isAd: boolean;
}

export const calculateInstagramRate = ({
	postCount,
	storyCount,
	reelCount,
	engagementRate,
	followers,
	terms,
}: {
	postCount: number;
	storyCount: number;
	reelCount: number;
	engagementRate: number;
	followers: number;
	terms?: CalculatorTerms;
}) => {
	let flatRate = 0;
	if (followers < 1_000) {
		flatRate = 5;
	} else if (followers < 5_000) {
		flatRate = 10;
	} else if (followers < 10_000) {
		flatRate = 20;
	} else if (followers < 50_000) {
		flatRate = 25;
	} else if (followers < 100_000) {
		flatRate = 30;
	} else if (followers < 500_000) {
		flatRate = 40;
	} else if (followers < 1_000_000) {
		flatRate = 50;
	}

	const engagedUsers = (followers * (1 + engagementRate)) / 100;
	let basePrice = engagedUsers + flatRate;

	if (terms?.isExclusive) {
		basePrice *= 1.25;
	}
	if (terms?.isRush) {
		basePrice *= 1.5;
	}
	if (terms?.isAd) {
		basePrice *= 1.25;
	}

	return (
		postCount * 1 * basePrice +
		storyCount * 0.5 * basePrice +
		reelCount * 1.3 * basePrice
	);
};

export const calculateTikTokRate = ({
	postCount,
	storyCount,
	viewEngagementRate,
	followers,
	terms,
}: {
	postCount: number;
	storyCount: number;
	viewEngagementRate: number;
	followers: number;
	terms?: CalculatorTerms;
}) => {
	let basePrice = (followers * viewEngagementRate) / 98.24;

	if (terms?.isExclusive) {
		basePrice *= 1.25;
	}
	if (terms?.isRush) {
		basePrice *= 1.5;
	}
	if (terms?.isAd) {
		basePrice *= 1.25;
	}

	return postCount * 1 * basePrice + storyCount * 0.48 * basePrice;
};

export const calculateYoutubeRate = ({
	postCount,
	shortCount,
	subscribers,
	terms,
}: {
	postCount: number;
	shortCount: number;
	subscribers: number;
	terms?: CalculatorTerms;
}) => {
	let basePrice = subscribers / 56.5;
	if (subscribers < 50_000) {
		basePrice = subscribers / 43;
	}

	if (terms?.isExclusive) {
		basePrice *= 1.25;
	}
	if (terms?.isRush) {
		basePrice *= 1.5;
	}
	if (terms?.isAd) {
		basePrice *= 1.25;
	}

	return postCount * 1 * basePrice + shortCount * 0.47 * basePrice;
};
