import { z } from "zod";
import { UploadedFileSchema } from "./upload";

export const ContractStatusSchema = z.enum([
	"processing",
	"reviewing",
	"declined",
	"accepted",
]);
export type ContractStatus = z.infer<typeof ContractStatusSchema>;

export const UpdateContractStatusSchema = z.object({
	uuid: z.string().uuid(),
	status: ContractStatusSchema,
});
export type UpdateContractStatus = z.infer<typeof UpdateContractStatusSchema>;

export const AiCompensationTypeResponseSchema = z.object({
	type: z.string().optional(),
});
export type AiCompensationTypeResponse = z.infer<
	typeof AiCompensationResponseSchema
>;

export const AiCompensationResponseSchema = z.object({
	compensation: z.array(z.string()).optional(),
});
export type AiCompensationResponse = z.infer<
	typeof AiCompensationResponseSchema
>;

export const FullAiCompensationResponseSchema = z.object({
	type: z.string().optional(),
	compensation: z.array(z.string()).optional(),
});
export type FullAiCompensationResponse = z.infer<
	typeof FullAiCompensationResponseSchema
>;

export const AiDeliverablesResponseSchema = z.object({
	deliverables: z.array(z.string()).optional(),
});
export type AiDealTermsResponse = z.infer<typeof AiDealTermsResponseSchema>;

export const AiDealTermsResponseSchema = z.object({
	dealTerms: z.array(z.string()).optional(),
});
export type AiDeliverablesResponse = z.infer<typeof AiDealTermsResponseSchema>;

export const AiCompanyNameResponseSchema = z.object({
	companyName: z.string().optional(),
});
export type AiCompanyNameResponse = z.infer<typeof AiCompanyNameResponseSchema>;

export const AiContractDueDateResponseSchema = z.object({
	dueDate: z.string().optional(),
});
export type AiContractDueDateResponse = z.infer<
	typeof AiCompanyNameResponseSchema
>;

export const AiContractAnalysisSchema = z.object({
	isLoading: z.boolean().optional(),
	companyName: z.string().optional(),
	compensation: FullAiCompensationResponseSchema.optional(),
	deliverables: z.array(z.string()).optional(),
	dealTerms: z.array(z.string()).optional(),
});
export type AiContractAnalysis = z.infer<typeof AiContractAnalysisSchema>;

export const AiAnalysisSectionSchema = z.enum([
	"compensation",
	"deliverables",
	"dealTerms",
]);
export type AiAnalysisSection = z.infer<typeof AiAnalysisSectionSchema>;

export const AiAnalysisFeedbackSchema = z.object({
	accurate: z.boolean().optional(),
	details: z
		.object({
			inaccurateSections: z.array(AiAnalysisSectionSchema),
			details: z.string().max(500).optional(),
		})
		.optional(),
});
export type AiAnalysisFeedback = z.infer<typeof AiAnalysisFeedbackSchema>;

export const AiAnalysisFeedbackResponseSchema = z.object({
	compensationInaccurate: z.boolean().optional(),
	deliverablesInaccurate: z.boolean().optional(),
	dealTermsInaccurate: z.boolean().optional(),
	details: z.string().max(500).optional(),
});
export type AiAnalysisFeedbackResponse = z.infer<
	typeof AiAnalysisFeedbackResponseSchema
>;

export const AiChatRoleSchema = z.enum(["user", "system", "assistant"]);
export type AiChatRole = z.infer<typeof AiChatRoleSchema>;

export const AiChatMessageSchema = z.object({
	role: AiChatRoleSchema,
	content: z.string(),
});
export type AiChatMessage = z.infer<typeof AiChatMessageSchema>;

export const ContractSchema = z.object({
	uuid: z.string().uuid(),

	createdAt: z.date(),
	updatedAt: z.date(),
	deletedAt: z.date().nullable(),

	name: z.string(),
	status: ContractStatusSchema,
	subtext: z.string().nullable(),
	textContent: z.string(),
	extractedData: AiContractAnalysisSchema,
	analysisFeedback: AiAnalysisFeedbackSchema,

	pdf: UploadedFileSchema.optional(),
	isPublic: z.boolean(),
});
export type Contract = z.infer<typeof ContractSchema>;

export const AskQuestionSchema = z.object({
	uuid: z.string().uuid(),
	messages: z.array(AiChatMessageSchema),
	isPublic: z.boolean(),
});
export type AskQuestion = z.infer<typeof AskQuestionSchema>;
