import { cx } from "../../classnames";

export interface ModalBodyProps {
	children: React.ReactNode;
	className?: string;
}

export const ModalBody: React.FC<ModalBodyProps> = ({
	children,
	className,
}) => {
	return (
		<div className={cx("flex-grow overflow-y-auto p-8 pt-0 ring-0", className)}>
			{children}
		</div>
	);
};
