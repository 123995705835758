import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { DrawerContent } from "./Content";
import { ModalSize } from "../Modal/Root";

export interface DrawerProps
	extends Omit<Dialog.DialogProps, "open" | "onOpenChange" | "children"> {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	placement?: "left" | "right";

	content?: React.ReactNode;
	size?: ModalSize;
}

export const Drawer: React.FC<DrawerProps> = ({
	isOpen,
	setIsOpen,
	content,
	size,
	placement,
	...rest
}) => {
	return (
		<Dialog.Root open={isOpen} onOpenChange={setIsOpen} {...rest}>
			{content && (
				<DrawerContent placement={placement} size={size}>
					{content}
				</DrawerContent>
			)}
		</Dialog.Root>
	);
};
