import * as React from "react";
import { SVGProps } from "react";

const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.143 7.643V2.5h1.714v5.143H14v1.714H8.857V14.5H7.143V9.357H2V7.643h5.143Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgAdd;
