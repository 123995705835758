import { cva, VariantProps } from "class-variance-authority";
import React from "react";

const text = cva([], {
	variants: {
		variant: {
			"bold/xl": ["font-semibold leading-[130%]"],
			"bold/lg": ["font-semibold text-sm leading-[130%]"],
			"bold/sm": ["font-semibold text-xs leading-[130%]"],
			"bold/xs": ["font-semibold text-[10px] leading-[130%]"],
			"body/xl": ["leading-[140%]"],
			"body/lg": ["text-sm leading-[140%]"],
			"body/sm": ["text-xs leading-[140%]"],
			"body/xs": ["text-[10px] leading-[140%]"],
		},
	},
	defaultVariants: {
		variant: "body/lg",
	},
});

export interface TextProps
	extends React.ButtonHTMLAttributes<HTMLParagraphElement>,
		VariantProps<typeof text> {}

export const Text = React.forwardRef<HTMLParagraphElement, TextProps>(
	({ variant, className, ...rest }, ref) => (
		<p
			className={text({
				variant,
				className,
			})}
			{...rest}
			ref={ref}
		/>
	),
);
