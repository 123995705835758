import * as Dialog from "@radix-ui/react-dialog";
import React from "react";

export const ModalTitle = React.forwardRef<
	React.ElementRef<typeof Dialog.Title>,
	React.ComponentPropsWithoutRef<typeof Dialog.Title>
>(({ children, ...props }, ref) => {
	return (
		<Dialog.Title asChild {...props} ref={ref}>
			<h1>{children}</h1>
		</Dialog.Title>
	);
});
