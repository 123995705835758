import * as React from "react";
import { SVGProps } from "react";

const SvgInvoice = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#invoice_svg__a)" fill="currentcolor">
			<path d="M10 4.5v-4l4 4h-4ZM8.234 13.004a1.22 1.22 0 0 0 .32-.085.879.879 0 0 0 .367-.278.706.706 0 0 0 .135-.421.56.56 0 0 0-.12-.361.878.878 0 0 0-.328-.241c-.11-.052-.234-.1-.374-.142v1.528ZM7.817 10.436V8.997c-.105.015-.2.042-.285.08a.758.758 0 0 0-.323.26.664.664 0 0 0-.115.375c0 .117.025.218.074.304.05.085.12.158.206.218.087.059.182.108.288.148.051.02.103.038.155.054Z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.5.5H2v16h12V6H8.5V.5Zm-.683 13.379v.621h.417v-.621a2.32 2.32 0 0 0 .855-.202c.295-.14.52-.335.677-.582a1.6 1.6 0 0 0 .234-.867c0-.243-.042-.454-.127-.635-.085-.18-.2-.334-.346-.462a1.973 1.973 0 0 0-.491-.317 3.198 3.198 0 0 0-.568-.2l-.234-.062V8.994a.994.994 0 0 1 .486.188.76.76 0 0 1 .3.557h.909a1.535 1.535 0 0 0-.247-.836 1.613 1.613 0 0 0-.66-.572 2.055 2.055 0 0 0-.788-.202V7.5h-.417v.633c-.272.024-.52.09-.746.198a1.672 1.672 0 0 0-.684.577 1.52 1.52 0 0 0-.25.867c0 .401.124.723.372.965.25.24.589.42 1.018.539l.29.08v1.647a1.33 1.33 0 0 1-.336-.084.893.893 0 0 1-.384-.29.887.887 0 0 1-.168-.486H6c.014.372.103.687.27.946.168.259.4.455.697.59.247.112.53.177.85.197Z"
			/>
		</g>
		<defs>
			<clipPath id="invoice_svg__a">
				<path
					fill="currentcolor"
					transform="translate(0 .5)"
					d="M0 0h16v16H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgInvoice;
