import { Text } from "./Text";
import { cva, VariantProps } from "class-variance-authority";

const statusIndicator = cva(["rounded-full capitalize inline-block"], {
	variants: {
		status: {
			green: [],
			blue: [],
			yellow: [],
			red: [],
			gray: [],
			brand: [],
			disabled: [],
		},
		fill: {
			false: ["border"],
			true: ["border border-transparent"],
		},
		size: {
			small: ["py-[.125rem] px-[6px] pt-[1px] pb-[2px] font-semibold text-xs"],
			large: ["py-1 px-3 font-medium text-sm"],
		},
	},
	compoundVariants: [
		{
			status: "green",
			fill: true,
			className: "bg-success-500 text-white-100",
		},
		{
			status: "green",
			fill: false,
			className: "bg-success-800 border-success-600 text-success-100",
		},
		{
			status: "blue",
			fill: true,
			className: "bg-notification-500 text-white-100",
		},
		{
			status: "blue",
			fill: false,
			className:
				"bg-notification-800 border-notification-600 text-notification-100",
		},
		{
			status: "yellow",
			fill: true,
			className: "bg-warning-400 text-gray-900",
		},
		{
			status: "yellow",
			fill: false,
			className: "bg-warning-800 border-warning-600 text-warning-100",
		},
		{
			status: "red",
			fill: true,
			className: "bg-danger-500 text-white-100",
		},
		{
			status: "red",
			fill: false,
			className: "bg-danger-800 border-danger-600 text-danger-100",
		},
		{
			status: "gray",
			fill: true,
			className: "bg-gray-100 text-gray-900",
		},
		{
			status: "gray",
			fill: false,
			className: "bg-gray-800 border-gray-300 text-gray-100",
		},
		{
			status: "brand",
			fill: true,
			className: "bg-blue-500 text-gray-900",
		},
		{
			status: "brand",
			fill: false,
			className: "bg-blue-800 border-blue-600 text-blue-100",
		},
		{
			status: "disabled",
			fill: true,
			className: "bg-gray-400 text-gray-200",
		},
		{
			status: "disabled",
			fill: false,
			className: "bg-gray-400 text-gray-200 border-gray-200",
		},
	],
});

type StatusIndicatorVariantProps = VariantProps<typeof statusIndicator>;
export type StatusIndicatorStatus = StatusIndicatorVariantProps["status"];
export interface StatusIndicatorProps
	extends React.ButtonHTMLAttributes<HTMLParagraphElement>,
		Omit<StatusIndicatorVariantProps, "status"> {
	status: StatusIndicatorStatus;
	label: React.ReactNode;
}

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({
	status,
	size = "large",
	fill = true,
	label,
	className,
}) => {
	return (
		<div
			className={statusIndicator({
				status,
				size,
				fill,
				className,
			})}
		>
			<Text variant={size === "small" ? "body/sm" : "body/lg"}></Text>
			{label}
		</div>
	);
};
