import * as React from "react";
import { SVGProps } from "react";

const SvgFacebookLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
			fill="white"
		/>
		<path
			d="M13.8 23.8659C19.5735 22.9975 24 18.0156 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.911 4.27383 22.8239 9.9 23.8169V15.6H6.9V12H9.9V9.6C9.9 8.00002 10.8 4.8 14.4 4.8C15.3601 4.8 16.5999 5.00002 17.1 5.1V8.1H15.6C15 8.1 13.8 8.4 13.8 9.6V12H17.1L16.5 15.6H13.8V23.8659Z"
			fill="#3579EA"
		/>
	</svg>
);

export default SvgFacebookLogo;
