import { z } from "zod";

export enum WishlistRequestStatus {
	RECEIVED = "received",
	FULFILLED = "fulfilled",
	UNRESPONSIVE = "unresponsive",
	DELETED = "deleted",
}

export const getStatusDisplayName = (status: WishlistRequestStatus) => {
	switch (status) {
		case WishlistRequestStatus.RECEIVED:
			return "Received";
		case WishlistRequestStatus.FULFILLED:
			return "Fulfilled";
		case WishlistRequestStatus.UNRESPONSIVE:
			return "Unresponsive";
		case WishlistRequestStatus.DELETED:
			return "Deleted";
	}
};

export const CreateWishlistItemSchema = z.object({
	companyName: z.string().min(1).max(512),
	companyHandle: z.string().min(1).max(512),
});
export type CreateWishlistItemRequest = z.infer<
	typeof CreateWishlistItemSchema
>;

export const WishlistItemSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	companyHandle: z.string(),
	companyName: z.string(),
	comment: z.string().optional(),
	status: z.nativeEnum(WishlistRequestStatus),
	hasTemporary: z.boolean(),
});
export type WishlistItem = z.infer<typeof WishlistItemSchema>;
