import { JSXElementConstructor } from "react";
import { cx } from "../classnames";

export interface TagProps {
	text: string;
	size?: "sm" | "md" | "lg";
	color?: "graphite" | "red" | "orange" | "green" | "brand" | "purple" | "pink";
	leftIcon?: JSXElementConstructor<{ className?: string }>;
	rightIcon?: JSXElementConstructor<{ className?: string }>;
}

export const Tag: React.FC<TagProps> = ({
	text,
	size = "md",
	color = "graphite",
	leftIcon: LeftIcon,
	rightIcon: RightIcon,
}) => {
	const textClassName = [
		(size === "sm" || size === "md") && "text-paragraph-xs",
		size === "lg" && "text-paragraph-sm",
		color === "graphite" ? "text-text-primary bg-sky-alpha-3" : "",
		color === "red" ? "text-text-error bg-surface-error" : "",
		color === "orange" ? "text-text-orange bg-surface-warning" : "",
		color === "green" ? "text-text-success bg-surface-success" : "",
		color === "brand" ? "text-brand bg-sky-alpha-2" : "",
		color === "purple" ? "text-purple-8 bg-purple-alpha-2" : "",
		color === "pink" ? "text-pink-8 bg-pink-alpha-2" : "",
	].join(" ");

	return (
		<div
			className={`${textClassName} ${cx(
				"font-repro flex w-fit items-center gap-2 rounded-full",
				size === "sm" && "h-[18px] px-[6px]",
				size === "md" && "h-[22px] px-2",
				size === "lg" && "h-[28px] px-[10px]",
			)}`}
		>
			{LeftIcon ? (
				<LeftIcon
					className={cx("h-[14px] w-[14px]", size === "sm" && "h-3 w-3")}
				/>
			) : null}
			{text}
			{RightIcon ? (
				<RightIcon
					className={cx("h-[14px] w-[14px]", size === "sm" && "h-3 w-3")}
				/>
			) : null}
		</div>
	);
};
