import { Root, Item } from "@radix-ui/react-toggle-group";
import React from "react";
import { cx } from "../classnames";

export interface ToggleGroupProps {
	className?: string;
	disabled?: boolean;
	value: string[];
	setValue: (value: string[]) => void;
	children: React.ReactNode;
	center?: boolean;
	style?: React.CSSProperties;
}

export const ToggleGroup = React.forwardRef<
	React.ElementRef<typeof Root>,
	ToggleGroupProps
>(({ className, children, disabled, setValue, value, center, style }, ref) => {
	return (
		<Root
			type="multiple"
			disabled={disabled}
			onValueChange={setValue}
			value={value}
			className={cx(
				"flex flex-wrap gap-3",
				center && "justify-center",
				className,
			)}
			style={style}
			ref={ref}
		>
			{children}
		</Root>
	);
});

export interface ToggleGroupItemProps {
	children: React.ReactNode;
	value: string;
	disabled?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

export const ToggleGroupItem: React.FC<ToggleGroupItemProps> = ({
	children,
	value,
	disabled,
	className,
	style,
}) => {
	return (
		<Item
			disabled={disabled}
			value={value}
			className={cx(
				"inline-flex rounded-lg border px-4 py-3 text-sm font-medium transition-colors",
				"data-[disabled]:border-gray-400 data-[disabled]:text-gray-100",
				"data-[state=off]:text-white-100 data-[state=off]:border-gray-300",
				"data-[state=on]:border-blue-400 data-[state=on]:bg-blue-900 data-[state=on]:text-blue-500",
				className,
			)}
			style={style}
		>
			{children}
		</Item>
	);
};
