import * as React from "react";
import { SVGProps } from "react";

const SvgCheckBadge = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m8 .5 5.976 1.328c.332.074.57.369.57.71V9.8c0 1.459-.73 2.822-1.944 3.63L8 16.5l-4.602-3.068a4.362 4.362 0 0 1-1.943-3.63V2.538c0-.341.237-.636.569-.71L8 .5Zm3.238 5.252-3.6 3.6L5.58 7.295 4.553 8.323l3.086 3.086 4.628-4.628-1.03-1.029Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCheckBadge;
