import { Item as RadixItem, ItemText } from "@radix-ui/react-select";
import React from "react";
import { cx } from "../../../classnames";

type ItemProps = React.ComponentProps<typeof RadixItem>;

export const Item: React.FC<ItemProps> = ({
	disabled,
	className,
	children,
	...rest
}) => {
	return (
		<RadixItem
			className={cx(
				"cursor-pointer rounded-lg px-2.5 py-1.5 text-sm outline-none hover:bg-gray-500 focus-visible:bg-gray-400 focus-visible:ring-0",
				className,
			)}
			{...rest}
		>
			<ItemText
				className={cx("flex items-center gap-2", disabled && "text-gray-100")}
			>
				{children}
			</ItemText>
		</RadixItem>
	);
};
