import * as React from "react";
import { SVGProps } from "react";

const SvgPitchFlat = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1.38 1.89c-.174-.349-.036-.492.322-.313L14.426 7.94c.353.176.356.476.023.66L2.18 15.417c-.339.19-.5.046-.361-.317l2.247-5.84 7-1-7-1-2.685-5.37Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgPitchFlat;
