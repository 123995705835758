import { color } from "../theme";

export interface LoaderProps {
	variant?: "primary" | "secondary";
	size?: "small" | "medium" | "large";
}

export const Loader: React.FC<LoaderProps> = ({
	variant = "secondary",
	size: sizeProp = "medium",
}) => {
	const thickness = "2px";

	let size;
	if (sizeProp === "small") {
		size = "1rem";
	} else if (sizeProp === "medium") {
		size = "1.25rem";
	} else {
		size = "1.5rem";
	}

	let foregroundColor = color.white[100];
	let trackColor = color.gray[200];
	if (variant === "primary") {
		foregroundColor = color.blue[200];
		trackColor = color.blue[700];
	}

	return (
		<div
			role="progressbar"
			aria-live="polite"
			aria-label="Loading..."
			className="animate-spin rounded-full"
			style={{
				width: size,
				height: size,
				border: `${thickness} solid ${trackColor}`,
				borderLeft: `${thickness} solid ${foregroundColor}`,
			}}
		/>
	);
};
