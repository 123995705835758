import * as Dialog from "@radix-ui/react-dialog";
import { Close } from "@withjuly/julycons";
import React from "react";
import { cx } from "../../classnames";

export interface ModalHeaderProps {
	children: React.ReactNode;
	border?: boolean;
	closeable?: boolean;
}

export const ModalHeader = React.forwardRef<HTMLDivElement, ModalHeaderProps>(
	({ children, border, closeable = true }, ref) => {
		return (
			<div
				className={cx(
					"flex items-start justify-between gap-4 p-8 pb-6",
					border && "mb-8 border-b border-gray-300",
				)}
				ref={ref}
			>
				<div className="flex w-full flex-col gap-2">{children}</div>
				{closeable && (
					<div className="text-[1.5rem] md:text-[1rem]">
						<Dialog.Close className="rounded transition-all duration-150 ease-in-out">
							<Close aria-label="Close modal" />
						</Dialog.Close>
					</div>
				)}
			</div>
		);
	},
);

ModalHeader.displayName = "ModalHeader";
