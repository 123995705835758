import * as React from "react";
import { SVGProps } from "react";

const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2.556 4.222 1 5.778l7 7 7-7-1.556-1.556L8 9.667 2.556 4.222Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgChevronDown;
