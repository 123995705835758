import * as Toast from "@radix-ui/react-toast";
import { spacing } from "../../theme";

export const VIEWPORT_PADDING = spacing[200];

export const ToastViewport: React.FC = () => {
	return (
		<Toast.Viewport className="fixed bottom-0 right-0 z-[1008] m-0 flex max-w-[100vw] list-none flex-col gap-4 p-[var(--viewport-padding)] outline-none [--viewport-padding:_2rem]" />
	);
};
