import * as React from "react";
import { SVGProps } from "react";

const SvgGmailLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect y={0.5} width={16} height={16} rx={4} fill="currentcolor" />
		<path
			d="M3.387 12.507h1.7V8.38L2.659 6.559v5.22c0 .402.326.728.728.728Z"
			fill="#4285F4"
		/>
		<path
			d="M10.913 12.507h1.7a.728.728 0 0 0 .728-.729v-5.22L10.913 8.38v4.127Z"
			fill="#34A853"
		/>
		<path
			d="M10.913 5.224V8.38l2.428-1.821v-.971c0-.9-1.028-1.414-1.748-.874l-.68.51Z"
			fill="#FBBC04"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.087 8.38V5.224L8 7.409l2.913-2.185V8.38L8 10.564 5.087 8.38Z"
			fill="#EA4335"
		/>
		<path
			d="M2.66 5.588v.97L5.086 8.38V5.224l-.68-.51a1.092 1.092 0 0 0-1.748.874Z"
			fill="#C5221F"
		/>
	</svg>
);

export default SvgGmailLogo;
