import { useStore } from "zustand";
import {
	type MediaKitStore,
	createMediaKitStore,
} from "../../utils/store/mediakit-store";
import { createContext, ReactNode, useContext, useRef } from "react";

export type MediaKitStoreApi = ReturnType<typeof createMediaKitStore>;

export const MediaKitStoreContext = createContext<MediaKitStoreApi | undefined>(
	undefined,
);

export interface MediaKitStoreProviderProps {
	children: ReactNode;
}

export const MediaKitStoreProvider: React.FC<MediaKitStoreProviderProps> = ({
	children,
}) => {
	const storeRef = useRef<MediaKitStoreApi>();
	if (!storeRef.current) {
		storeRef.current = createMediaKitStore();
	}

	return (
		<MediaKitStoreContext.Provider value={storeRef.current}>
			{children}
		</MediaKitStoreContext.Provider>
	);
};

export const useMediaKitStore = <T,>(
	selector: (store: MediaKitStore) => T,
): T => {
	const mediaKitStoreContext = useContext(MediaKitStoreContext);

	if (!mediaKitStoreContext) {
		throw new Error(
			`useMediaKitStore must be used within MediaKitStoreProvider`,
		);
	}

	return useStore(mediaKitStoreContext, selector);
};
