import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import React from "react";
import { cx } from "../classnames";

const Collapsible = CollapsiblePrimitive.Root;

const CollapsibleTrigger = CollapsiblePrimitive.CollapsibleTrigger;

const CollapsibleContent = React.forwardRef<
	React.ElementRef<typeof CollapsiblePrimitive.CollapsibleContent>,
	React.ComponentPropsWithoutRef<typeof CollapsiblePrimitive.CollapsibleContent>
>(({ className, ...props }, ref) => (
	<CollapsiblePrimitive.CollapsibleContent
		ref={ref}
		className={cx(
			"data-[state=open]:animate-collapsible-open data-[state=closed]:animate-collapsible-close",
			className,
		)}
		{...props}
	/>
));
CollapsibleContent.displayName =
	CollapsiblePrimitive.CollapsibleContent.displayName;

export { Collapsible, CollapsibleTrigger, CollapsibleContent };
