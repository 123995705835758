import * as React from "react";
import { SVGProps } from "react";

const SvgChat = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M4.119 12.987 1 15.5V2.218c0-.19.074-.373.205-.508A.691.691 0 0 1 1.7 1.5h12.6c.186 0 .364.076.495.21a.727.727 0 0 1 .205.508v10.051c0 .19-.074.373-.205.508a.691.691 0 0 1-.495.21H4.119Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgChat;
