import * as React from "react";
import { SVGProps } from "react";

const SvgChevronLeft = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m3.757 8.5 7.071-7.071 1.415 1.414L6.586 8.5l5.657 5.657-1.415 1.414L3.758 8.5Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgChevronLeft;
