import { Combobox as HUCombobox } from "@headlessui/react";
import React from "react";

export interface ComboboxInputProps<T>
	extends React.InputHTMLAttributes<HTMLInputElement> {
	as?: React.ElementType;
	displayValue?: (item: T) => string;
}

export const ComboboxInput = <T,>(props: ComboboxInputProps<T>) => {
	// @ts-expect-error emotion causes this to type check incorrectly
	return <HUCombobox.Input {...props} />;
};
