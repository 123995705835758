import * as React from "react";
import { SVGProps } from "react";

const SvgAddToQueue = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1 10.5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM1 14.5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM1 4.5a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5A.75.75 0 0 1 1 4.5Z"
			fill="currentcolor"
		/>
		<path
			d="M4 1.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 4 1.5ZM14 1.5c.8 0 1 .667 1 1v4c0 .8-.667 1-1 1H8.5c-.4 0-.5-.333-.5-.5V6c0-.4.333-.5.5-.5h4c.4 0 .5-.333.5-.5V4c0-.4-.333-.5-.5-.5h-4c-.4 0-.5-.333-.5-.5V2c0-.4.333-.5.5-.5H14Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgAddToQueue;
