import { cx } from "../classnames";

interface ProgressLoaderProps {
	className?: string;
}

export const ProgressLoader: React.FC<ProgressLoaderProps> = ({
	className,
}) => {
	return (
		<div
			className={cx(
				"bg-surface-quaternary h-2 w-full max-w-[352px] rounded-full",
				className,
			)}
		>
			<div className=" animate-ai-loading shadow-brand-glow-small bg-brand h-2 w-full rounded-full" />
		</div>
	);
};
