import * as React from "react";
import { SVGProps } from "react";

const SvgClose = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8 7.465 11.965 3.5 13 4.535 9.035 8.5 13 12.465 11.965 13.5 8 9.535 4.035 13.5 3 12.465 6.965 8.5 3 4.535 4.035 3.5 8 7.465Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgClose;
