import { z } from "zod";
import { ApiErrorSchema } from ".";

export const PhylloErrorSchema = ApiErrorSchema.extend({
	name: z.enum([
		"phyllo_account_not_found",
		"phyllo_too_many_requests",
		"phyllo_max_limit_reached",
	]),
});
export type PhylloError = z.infer<typeof PhylloErrorSchema>;

export const PhylloAccountNotFound: PhylloError = {
	name: "phyllo_account_not_found",
	httpCode: 404,
	message: "We could not find the account.",
	description: "Please try again",
	type: "api",
};

export const PhylloTooManyRequests: PhylloError = {
	name: "phyllo_too_many_requests",
	httpCode: 429,
	message: "Too many requests",
	type: "api",
};

export const PhylloMaxLimitReached: PhylloError = {
	name: "phyllo_max_limit_reached",
	httpCode: 400,
	message: "Max limit reached",
	type: "api",
};
