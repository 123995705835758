import * as React from "react";
import { SVGProps } from "react";

const SvgPaypal = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.986 5.702c.357.638.404 1.461.218 2.414-.537 2.763-2.378 3.716-4.728 3.716h-.363a.584.584 0 0 0-.576.494l-.03.16-.456 2.898-.024.123a.584.584 0 0 1-.576.493H5.025a.35.35 0 0 1-.346-.405l.127-.805h1.101l.69-4.37h1.005c3.395 0 5.625-1.598 6.384-4.718Zm-2.148-3.695c.553.63.713 1.314.546 2.385-.014.089-.03.174-.045.26-.534 2.74-2.242 3.954-5.049 3.954H5.923c-.458 0-.852.3-.983.727l-.01-.002-.675 4.278H1.687a.037.037 0 0 1-.036-.027.037.037 0 0 1 0-.016L3.536 1.583A.69.69 0 0 1 4.217 1h4.337c1.585 0 2.697.34 3.283 1.007Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgPaypal;
