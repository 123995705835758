import * as React from "react";
import { SVGProps } from "react";

const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.722.668A8.844 8.844 0 0 0 6.277.67a3.106 3.106 0 0 1-.547 1.323c-.285.396-.66.726-1.098.965a3.523 3.523 0 0 1-2.91.209A7.946 7.946 0 0 0 0 6.001c.395.3.714.68.933 1.112a3.062 3.062 0 0 1 0 2.772 3.228 3.228 0 0 1-.931 1.112 7.903 7.903 0 0 0 1.72 2.836 3.524 3.524 0 0 1 2.91.208c.438.24.814.57 1.099.967.285.397.472.85.547 1.324a8.843 8.843 0 0 0 3.445-.003c.075-.474.263-.926.547-1.322.285-.396.66-.726 1.098-.965a3.523 3.523 0 0 1 2.91-.209A7.946 7.946 0 0 0 16 10.999c-.771-.586-1.265-1.488-1.265-2.499 0-.48.113-.953.332-1.386.218-.432.537-.813.931-1.112a7.904 7.904 0 0 0-1.72-2.836 3.524 3.524 0 0 1-2.91-.208 3.301 3.301 0 0 1-1.098-.966A3.103 3.103 0 0 1 9.722.668ZM8 12.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgSettings;
