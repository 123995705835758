/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormContext, FieldError, get } from "react-hook-form";

export const useFormWrapper = (name: string) => {
	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useFormContext();
	const error: FieldError = get(errors, name);

	return {
		register,
		error,
		hasError: !!error?.message,
		value: watch(name),
		setValue: (value: any) => setValue(name, value),
	};
};
