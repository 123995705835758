import * as React from "react";
import { SVGProps } from "react";

const SvgGlobe = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1 8.5a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm6.563-6.058c-.587.179-1.169.718-1.652 1.623-.159.302-.296.614-.41.935h2.061V2.442ZM4.579 5c.146-.465.333-.915.56-1.347.151-.286.326-.559.522-.816A6.147 6.147 0 0 0 2.973 5H4.58Zm-.51 3.063c.027-.768.121-1.504.274-2.188H2.465a6.088 6.088 0 0 0-.574 2.188h2.178Zm1.172-2.188c-.172.717-.271 1.45-.296 2.188h2.617V5.874h-2.32Zm3.197 0v2.188h2.616a10.94 10.94 0 0 0-.295-2.188H8.437ZM4.946 8.938c.025.737.123 1.47.295 2.187h2.322V8.937H4.946Zm3.492 0v2.187h2.32c.165-.67.269-1.407.297-2.188H8.438ZM5.502 12c.12.338.258.651.41.934.482.906 1.065 1.444 1.65 1.624V12h-2.06Zm.16 2.163a5.856 5.856 0 0 1-.523-.816A8.107 8.107 0 0 1 4.579 12H2.973a6.146 6.146 0 0 0 2.688 2.163Zm-1.32-3.038c-.158-.72-.25-1.451-.272-2.188H1.89a6.06 6.06 0 0 0 .575 2.188h1.878Zm5.997 3.038A6.146 6.146 0 0 0 13.027 12H11.42a8.098 8.098 0 0 1-.56 1.347c-.151.286-.326.559-.522.816ZM8.437 12v2.558c.587-.179 1.169-.718 1.652-1.623.151-.284.288-.597.41-.935H8.436Zm3.22-.875h1.878c.32-.671.52-1.409.574-2.188h-2.178a11.95 11.95 0 0 1-.273 2.188Zm2.452-3.063a6.09 6.09 0 0 0-.574-2.187h-1.877c.152.684.246 1.42.273 2.188h2.178Zm-3.248-4.409c.216.406.404.858.56 1.347h1.606a6.146 6.146 0 0 0-2.688-2.163c.19.248.366.523.522.816ZM10.498 5a6.965 6.965 0 0 0-.41-.934c-.482-.906-1.064-1.444-1.65-1.624V5h2.06Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgGlobe;
