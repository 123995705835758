import * as React from "react";
import { SVGProps } from "react";

const SvgAlcohol = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#alcohol_svg__a)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="m1.398 1.617 5.493 7.99v4.982l-3.321.554v1.107h8.857v-1.107l-3.321-.554V9.607l5.493-7.99a.554.554 0 0 0-.456-.867H1.855c-.446 0-.71.5-.457.867Zm11.03.24H3.57a.277.277 0 0 0-.221.443L4.834 4.28c.104.14.268.221.443.221h5.446a.553.553 0 0 0 .443-.222L12.649 2.3a.277.277 0 0 0-.222-.443Z"
				fill="currentcolor"
			/>
		</g>
		<defs>
			<clipPath id="alcohol_svg__a">
				<path
					fill="currentcolor"
					transform="translate(0 .5)"
					d="M0 0h16v16H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgAlcohol;
