import * as React from "react";
import { SVGProps } from "react";

const SvgUtensils = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#utensils_svg__a)" fill="currentcolor">
			<path d="M13.5 1.09V15.5a1 1 0 1 1-2 0v-6c-.333 0-1-.6-1-3 0-3.682 1.4-5.287 2.444-5.81.276-.139.556.091.556.4ZM4 16.5h2v-9c.5-.167 1.5-.8 1.5-2V1a.5.5 0 0 0-1 0v4.293a.5.5 0 0 1-1 0V1a.5.5 0 0 0-1 0v4.293a.5.5 0 0 1-1 0V1a.5.5 0 0 0-1 0v4.5c0 1.2 1 1.833 1.5 2v9Z" />
		</g>
		<defs>
			<clipPath id="utensils_svg__a">
				<path
					fill="currentcolor"
					transform="translate(0 .5)"
					d="M0 0h16v16H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgUtensils;
