import { z } from "zod";
import { UploadedFileSchema } from "./upload";
import { OffsetPaginatedSchema } from "./utils";

export enum ChatThreadType {
	DEAL_FLOW = "deal_flow",

	// Potential future values:
	// INVOICE
	// INSTAGRAM_DM
}

export const ChatParticipantRole = z.enum([
	"from",
	"to",
	"bcc",
	"cc",
	"reply_to",
]);
export type ChatParticipantRole = z.infer<typeof ChatParticipantRole>;

export const ChatParticipantSchema = z.object({
	uuid: z.string().uuid(),

	displayName: z.optional(z.string()),
	username: z.string(),
});
export type ChatParticipant = z.infer<typeof ChatParticipantSchema>;

export const ChatMessageParticipantSchema = z.object({
	role: ChatParticipantRole,
	participant: ChatParticipantSchema,
});
export type ChatMessageParticipant = z.infer<
	typeof ChatMessageParticipantSchema
>;

export const ChatMessageSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),

	participants: z.array(ChatMessageParticipantSchema),

	subject: z.optional(z.string()),
	textContent: z.optional(z.string()),
	htmlContent: z.optional(z.string()),

	attachments: z.array(UploadedFileSchema),
});
export type ChatMessage = z.infer<typeof ChatMessageSchema>;

export const PaginatedChatMessagesSchema = OffsetPaginatedSchema.extend({
	items: z.array(ChatMessageSchema),
});
export type PaginatedChatMessages = z.infer<typeof PaginatedChatMessagesSchema>;

export const SendChatMessageParticipantSchema = z.object({
	role: ChatParticipantRole,
	participant: ChatParticipantSchema.omit({ uuid: true }),
});
export type SendChatMessageParticipant = z.infer<
	typeof SendChatMessageParticipantSchema
>;

export const SendChatMessageRequestSchema = z.object({
	participants: z.array(SendChatMessageParticipantSchema),

	subject: z.string(),
	textContent: z.string(),
	htmlContent: z.string(),

	attachmentUuids: z.array(z.string().uuid()),
});
export type SendChatMessageRequest = z.infer<
	typeof SendChatMessageRequestSchema
>;

export const ChatThreadSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),

	type: z.nativeEnum(ChatThreadType),
	name: z.optional(z.string()),

	hasUnread: z.boolean(),
	messages: z.object({
		count: z.number(),
		mostRecent: z.optional(ChatMessageSchema),
	}),
});
export type ChatThread = z.infer<typeof ChatThreadSchema>;

export const PaginatedChatThreadsSchema = OffsetPaginatedSchema.extend({
	items: z.array(ChatThreadSchema),
});
export type PaginatedChatThreads = z.infer<typeof PaginatedChatThreadsSchema>;
