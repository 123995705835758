import { z } from "zod";

z.setErrorMap((issue, ctx) => {
	if (issue.code === z.ZodIssueCode.too_small) {
		if (issue.type === "string") {
			return {
				message: `Must contain ${issue.inclusive ? `at least` : `more than`} ${
					issue.minimum
				} character${issue.minimum === 1 ? "" : "s"}.`,
			};
		}
	} else if (issue.code === z.ZodIssueCode.too_big) {
		if (issue.type === "string") {
			return {
				message: `Must contain ${issue.inclusive ? `at most` : `less than`} ${
					issue.maximum
				} character${issue.maximum === 1 ? "" : "s"}`,
			};
		}
	}

	return { message: ctx.defaultError };
});
