import * as React from "react";
import { SVGProps } from "react";

const SvgAiSparkle = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8.882 5.427 6.128 6.53a.4.4 0 0 0 0 .742l2.754 1.102a.8.8 0 0 1 .445.445l1.102 2.754a.4.4 0 0 0 .742 0l1.102-2.754a.8.8 0 0 1 .445-.445l2.754-1.102a.4.4 0 0 0 0-.742l-2.754-1.102a.8.8 0 0 1-.445-.445L11.17 2.228a.4.4 0 0 0-.742 0L9.327 4.982a.8.8 0 0 1-.445.445ZM3.739 11.484l-1.61.645a.4.4 0 0 0 0 .742l1.61.644a.8.8 0 0 1 .445.446l.645 1.61a.4.4 0 0 0 .742 0l.645-1.61a.8.8 0 0 1 .445-.446l1.61-.644a.4.4 0 0 0 0-.742l-1.61-.645a.8.8 0 0 1-.445-.445l-.645-1.61a.4.4 0 0 0-.742 0l-.645 1.61a.8.8 0 0 1-.445.445ZM1.567 2.913.528 3.33a.4.4 0 0 0 0 .742l1.04.416a.8.8 0 0 1 .445.446l.416 1.039a.4.4 0 0 0 .742 0l.416-1.04a.8.8 0 0 1 .446-.445l1.039-.416a.4.4 0 0 0 0-.742l-1.04-.416a.8.8 0 0 1-.445-.446L3.171 1.43a.4.4 0 0 0-.742 0l-.416 1.038a.8.8 0 0 1-.446.446Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgAiSparkle;
