import * as React from "react";
import { SVGProps } from "react";

const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 15.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm0-8.75a1.05 1.05 0 1 0 0-2.1 1.05 1.05 0 0 0 0 2.1Zm-.879.7v5.13h1.754V7.45H7.12Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgInfo;
