import * as RadixScrollArea from "@radix-ui/react-scroll-area";
import { cx } from "../classnames";

interface ScrollAreaProps extends RadixScrollArea.ScrollAreaProps {
	rootClassName?: string;
}

export const ScrollArea: React.FC<ScrollAreaProps> = ({
	rootClassName,
	children,
	...rest
}) => {
	return (
		<RadixScrollArea.Root
			className={cx("h-full w-full overflow-hidden", rootClassName)}
		>
			<RadixScrollArea.Viewport {...rest}>{children}</RadixScrollArea.Viewport>
			<RadixScrollArea.Scrollbar
				className="group flex touch-none select-none p-[6px] transition-colors data-[state=hidden]:flex data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-[20px] data-[orientation=horizontal]:flex-col"
				orientation="horizontal"
			>
				<RadixScrollArea.Thumb className="bg-surface-hover-1 hover:bg-surface-hover-2 realtive min-w-[10px] flex-1 rounded-full transition-colors" />
			</RadixScrollArea.Scrollbar>
			<RadixScrollArea.Scrollbar
				className="group flex touch-none select-none p-[6px] transition-colors data-[state=hidden]:flex data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-[20px] data-[orientation=horizontal]:flex-col"
				orientation="vertical"
			>
				<RadixScrollArea.Thumb className="bg-surface-hover-1 hover:bg-surface-hover-2 realtive min-w-[10px] flex-1 rounded-full transition-colors" />
			</RadixScrollArea.Scrollbar>
		</RadixScrollArea.Root>
	);
};
