import * as React from "react";
import { SVGProps } from "react";

const SvgExpandDotsHorizontal = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M14.222 10.278a1.778 1.778 0 1 1 0-3.556 1.778 1.778 0 0 1 0 3.556ZM8 10.278a1.778 1.778 0 1 1 0-3.556 1.778 1.778 0 0 1 0 3.556ZM1.778 10.278a1.778 1.778 0 1 1 0-3.556 1.778 1.778 0 0 1 0 3.556Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgExpandDotsHorizontal;
