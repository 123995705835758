import React, { ReactNode } from "react";
import { Root } from "@radix-ui/react-radio-group";
import { cx } from "../../classnames";

interface RadioGroupProps {
	value: string;
	setValue: (value: string) => void;
	children: ReactNode;
	disabled?: boolean;
	orientation?: "horizontal" | "vertical";
}

export const RadioGroup = React.forwardRef<
	React.ElementRef<typeof Root>,
	RadioGroupProps
>(
	(
		{ setValue, value, children, disabled = false, orientation = "vertical" },
		ref,
	) => (
		<Root
			value={value}
			onValueChange={setValue}
			disabled={disabled}
			orientation={orientation}
			className={cx("flex gap-4", orientation === "vertical" && "flex-col")}
			ref={ref}
		>
			{children}
		</Root>
	),
);
RadioGroup.displayName = "RadioGroup";
