import * as React from "react";
import { SVGProps } from "react";

const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2 12a1 1 0 1 1 2 0v2.5a1 1 0 1 1-2 0V12ZM2 2.5a1 1 0 1 1 2 0v5.6a1 1 0 1 1-2 0V2.5ZM7 2.5a1 1 0 0 1 2 0V4a1 1 0 0 1-2 0V2.5Z"
			fill="currentcolor"
		/>
		<path
			d="M.5 11.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75ZM5.5 7.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75ZM10.5 9.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75Z"
			fill="currentcolor"
		/>
		<path
			d="M7 8a1 1 0 0 1 2 0v6.5a1 1 0 1 1-2 0V8ZM12 2.5a1 1 0 1 1 2 0V6a1 1 0 1 1-2 0V2.5ZM12 10a1 1 0 1 1 2 0v4.5a1 1 0 1 1-2 0V10Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgFilter;
