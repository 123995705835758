import { Text } from "../Text";

export interface ToastDescriptionProps {
	children: React.ReactNode;
}

export const ToastDescription: React.FC<ToastDescriptionProps> = ({
	children,
}) => {
	return <Text variant="body/xl">{children}</Text>;
};
