import React, { forwardRef, ReactNode } from "react";
import { Check } from "@withjuly/julycons";
import { cx } from "../../classnames";

export interface TextInputProps
	extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
	leftIcon?: ReactNode;
	rightIcon?: ReactNode;
	isSuccess?: boolean;
	isInvalid?: boolean;
	size?: "large" | "small";
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	(
		{
			id,
			name,
			leftIcon,
			rightIcon,
			isSuccess,
			isInvalid,
			disabled,
			size = "large",
			...rest
		},
		ref,
	) => {
		const renderRightIcon = () => {
			if (isSuccess)
				return (
					<div className="text-success-300">
						<Check />
					</div>
				);
			if (rightIcon)
				return (
					<div
						className={cx(
							"text-sm",
							disabled ? "text-gray-200" : "text-gray-100",
						)}
						aria-hidden
					>
						{rightIcon}
					</div>
				);
		};

		return (
			<label
				className={cx(
					"focus-within:shadow-20 flex w-full overflow-hidden rounded-lg border bg-gray-600 p-1 transition-all duration-100 focus-within:bg-gray-700 focus-within:[&>div]:bg-gray-500",
					disabled ? "cursor-not-allowed" : "cursor-text",
					isInvalid && "border-danger-400 focus-within:border-danger-400",
					isSuccess && "border-success-300 focus-within:border-success-300",
					!isInvalid &&
						!isSuccess &&
						"border-gray-200 focus-within:border-blue-500 ",
				)}
			>
				<div
					className={cx(
						"flex w-full items-center gap-2 rounded",
						size === "large" ? "p-2" : "px-[0.375rem] py-[0.125rem]",
					)}
				>
					{leftIcon && (
						<div
							className={cx(
								"flex items-center text-sm",
								disabled ? "text-gray-200" : "text-gray-100",
							)}
							aria-hidden
						>
							{leftIcon}
						</div>
					)}
					<input
						id={id}
						name={name}
						type="text"
						aria-invalid={isInvalid}
						className="w-full appearance-none border-none bg-inherit text-sm placeholder:text-gray-200 focus:border-none focus:outline-none focus:ring-0 active:border-none active:outline-none disabled:cursor-not-allowed disabled:text-gray-200"
						disabled={disabled}
						{...rest}
						ref={ref}
					/>
					{renderRightIcon()}
				</div>
			</label>
		);
	},
);
