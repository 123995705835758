import * as React from "react";
import { SVGProps } from "react";

const SvgGift = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.333 9.167v4.666a.667.667 0 0 1-.666.667H3.333a.666.666 0 0 1-.666-.667V9.167h10.666ZM9.667 1.833a2.334 2.334 0 0 1 2.108 3.334H14a.667.667 0 0 1 .667.666v2A.667.667 0 0 1 14 8.5H2a.667.667 0 0 1-.667-.667v-2A.667.667 0 0 1 2 5.167h2.225A2.333 2.333 0 0 1 8 2.534a2.32 2.32 0 0 1 1.666-.7ZM6.333 3.167a1 1 0 0 0-.096 1.995l.096.005h1v-1a1 1 0 0 0-.904-.996l-.096-.004Zm3.334 0-.096.004a1 1 0 0 0-.9.9l-.004.096v1h1l.096-.005a1 1 0 0 0 0-1.99l-.096-.005Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgGift;
