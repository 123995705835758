import * as React from "react";
import { SVGProps } from "react";

const SvgExternalLink = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M6.667 4.5v1.333H3.333v7.334h7.334V9.833H12v4a.666.666 0 0 1-.667.667H2.667A.666.666 0 0 1 2 13.833V5.167a.667.667 0 0 1 .667-.667h4ZM14 2.5v5.333h-1.333V4.775L7.47 9.971 6.53 9.03l5.194-5.196H8.667V2.5H14Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgExternalLink;
