import dayjs from "dayjs";
import { Text } from "./Text";
import { ChevronLeft, ChevronRight } from "@withjuly/julycons";
import { Button } from "./Button";
import { cx } from "../classnames";
import { useEffect, useState } from "react";

interface CalendarProps {
	date: Date;
	setDate: (date: Date) => void;
	canSelectPast?: boolean;
}

export const Calendar: React.FC<CalendarProps> = ({
	date,
	setDate,
	canSelectPast,
}) => {
	const [monthDate, setMonthDate] = useState(() => date);

	useEffect(() => {
		setMonthDate(() => date);
	}, [date]);

	const monthDisplay = dayjs(monthDate).format("MMMM YYYY");

	const firstOfTheMonth = dayjs(monthDate).startOf("month");
	const lastOfTheMonth = dayjs(monthDate).endOf("month");

	const daysInPrevMonth = new Array(firstOfTheMonth.day())
		.fill(null)
		.map((_, i) =>
			dayjs(monthDate)
				.startOf("month")
				.subtract(i + 1, "day")
				.toDate(),
		)
		.reverse();
	const daysInMonth = new Array(dayjs(monthDate).daysInMonth())
		.fill(null)
		.map((_, i) => firstOfTheMonth.add(i, "day").toDate());
	const daysInNextMonth = new Array(
		42 - (daysInPrevMonth.length + daysInMonth.length),
	)
		.fill(null)
		.map((_, i) => lastOfTheMonth.add(i + 1, "day").toDate());

	const allDays = [...daysInPrevMonth, ...daysInMonth, ...daysInNextMonth];

	return (
		<div className="flex w-full flex-col rounded-lg border border-gray-200 bg-gray-600">
			<div className="flex w-full items-center justify-between pl-[20px] pr-[3px] pt-4">
				<div className="mr-2 flex w-full items-center justify-between gap-4">
					<Text variant="body/sm">{monthDisplay}</Text>
					<Button
						size="micro"
						variant="outline"
						onClick={() => setDate(new Date())}
						className="hover:shadow-none"
					>
						<Text variant="bold/xs" className="font-light">
							Today
						</Text>
					</Button>
				</div>
				<div className=" flex items-center">
					<Button
						variant="icon"
						size="icon-small"
						onClick={() => {
							setMonthDate((prev) => dayjs(prev).subtract(1, "month").toDate());
						}}
					>
						<ChevronLeft />
					</Button>
					<Button
						variant="icon"
						size="icon-small"
						onClick={() => {
							setMonthDate((prev) => dayjs(prev).add(1, "month").toDate());
						}}
					>
						<ChevronRight />
					</Button>
				</div>
			</div>
			<div className="grid-rows-7 grid grid-cols-7 gap-y-1 p-2">
				<Text
					variant="body/sm"
					className="flex h-9 w-9 items-center justify-center self-center justify-self-center text-gray-300"
				>
					S
				</Text>
				<Text
					variant="body/sm"
					className="flex h-9 w-9 items-center justify-center self-center justify-self-center text-gray-300"
				>
					M
				</Text>
				<Text
					variant="body/sm"
					className="flex h-9 w-9 items-center justify-center self-center justify-self-center text-gray-300"
				>
					T
				</Text>
				<Text
					variant="body/sm"
					className="flex h-9 w-9 items-center justify-center self-center justify-self-center text-gray-300"
				>
					W
				</Text>
				<Text
					variant="body/sm"
					className="flex h-9 w-9 items-center justify-center self-center justify-self-center text-gray-300"
				>
					T
				</Text>
				<Text
					variant="body/sm"
					className="flex h-9 w-9 items-center justify-center self-center justify-self-center text-gray-300"
				>
					F
				</Text>
				<Text
					variant="body/sm"
					className="flex h-9 w-9 items-center justify-center self-center justify-self-center text-gray-300"
				>
					S
				</Text>
				{allDays.map((day, i) => (
					<DayButton
						key={`${day.toString()}-${i}`}
						date={day}
						setDate={setDate}
						selected={dayjs(date).isSame(day, "day")}
						disabled={
							dayjs(day).isBefore(firstOfTheMonth) ||
							dayjs(day).isAfter(lastOfTheMonth)
						}
						canSelectPast={canSelectPast}
					/>
				))}
			</div>
		</div>
	);
};

interface DayButtonProps {
	date: Date;
	setDate: (date: Date) => void;
	selected?: boolean;
	disabled?: boolean;
	canSelectPast?: boolean;
}

const DayButton: React.FC<DayButtonProps> = ({
	date,
	setDate,
	selected,
	disabled,
	canSelectPast,
}) => {
	const dateIsInPast = dayjs(date).isBefore(dayjs(), "day");
	const isToday = dayjs(date).isSame(dayjs(), "day");
	const dayOfMonth = dayjs(date).format("D");

	return (
		<button
			className={cx(
				"h-9 w-9 self-center justify-self-center rounded-[4px] text-gray-100 transition-colors hover:bg-gray-500",
				disabled && "text-gray-300 hover:bg-transparent",
				isToday && "bg-gray-500",
				selected && "bg-blue-500 text-gray-500 hover:bg-blue-400",
				!canSelectPast &&
					dateIsInPast &&
					"cursor-not-allowed text-gray-300 hover:bg-transparent",
			)}
			onClick={() => {
				if (!canSelectPast && dateIsInPast) {
					return;
				}

				setDate(date);
			}}
		>
			<Text variant="body/sm">{dayOfMonth}</Text>
		</button>
	);
};
