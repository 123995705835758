import * as React from "react";
import { SVGProps } from "react";

const SvgInboxFull = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M.8 1.3h14.4a.8.8 0 0 1 .8.8v12.8a.8.8 0 0 1-.8.8H.8a.8.8 0 0 1-.8-.8V2.1a.8.8 0 0 1 .8-.8Zm4.8 7.2a2.4 2.4 0 1 0 4.8 0h4V2.9H1.6v5.6h4Z"
			fill="currentcolor"
		/>
		<path fill="currentcolor" d="M3.2 4.5h9.6v2.4H3.2z" />
	</svg>
);

export default SvgInboxFull;
