import { z } from "zod";

export const ThemeSchema = z.object({
	name: z.string(),
	textPrimary: z.string(),
	textSecondary: z.string(),
	textHeader: z.string(),
	textButton: z.string(),
	background: z.string(),
	fill: z.string(),
	graphPrimary: z.string(),
	graphSecondary: z.string(),
	stroke: z.string(),
	buttonFill: z.string(),
	buttonFillSecondary: z.string(),
});
export type Theme = z.infer<typeof ThemeSchema>;

export const ThemePreviewSchema = z.object({
	uuid: z.string().uuid(),
	name: z.string(),
	agencyName: z.string().optional(),
	textPrimary: z.string(),
	textSecondary: z.string(),
	buttonFill: z.string(),
	background: z.string(),
});
export type ThemePreview = z.infer<typeof ThemePreviewSchema>;
