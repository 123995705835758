import * as React from "react";
import { SVGProps } from "react";

const SvgClock = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 16.5a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8 15A6.5 6.5 0 1 0 8 2a6.5 6.5 0 0 0 0 13Z"
			fill="currentcolor"
		/>
		<path d="M7.25 3h1v6l-1-.251V3Z" fill="currentcolor" />
		<path
			d="m11.953 11.995-.707.708L7.25 8.707 7.957 8l3.996 3.995Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgClock;
