import * as React from "react";
import { SVGProps } from "react";

const SvgNewChat = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m1 15.5 3.119-2.513H14.3a.691.691 0 0 0 .495-.21.727.727 0 0 0 .205-.508V2.218a.727.727 0 0 0-.205-.508.691.691 0 0 0-.495-.21H1.7a.691.691 0 0 0-.495.21.727.727 0 0 0-.205.508V15.5ZM8.6 4.1H7.4v2.4H5v1.2h2.4v2.4h1.2V7.7H11V6.5H8.6V4.1Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgNewChat;
