import * as React from "react";
import { SVGProps } from "react";

const SvgForward = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.333 13.833 14 8.5 7.333 3.167V6.5a6.667 6.667 0 0 0-6.645 7.207A6 6 0 0 1 6 10.5h1.333v3.333Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgForward;
