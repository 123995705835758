import * as React from "react";
import { SVGProps } from "react";

const SvgMoney = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.463 16.5V.5h1.025v16H7.463Zm2.956-10.881c-.059-.546-.304-.971-.738-1.275-.429-.304-.987-.457-1.675-.457-.483 0-.898.073-1.244.22-.345.145-.61.343-.793.593-.184.25-.277.535-.282.856 0 .267.06.498.182.694.125.196.293.362.506.5.213.133.448.246.706.338.259.091.519.168.781.23l1.2.3c.484.113.948.265 1.394.457.45.192.852.433 1.207.725.358.292.641.644.85 1.056.208.413.312.896.312 1.45 0 .75-.192 1.41-.575 1.982-.383.566-.938 1.01-1.662 1.33-.721.317-1.594.476-2.62.476-.995 0-1.86-.154-2.593-.463-.73-.308-1.3-.758-1.712-1.35-.409-.591-.63-1.312-.663-2.162h2.281c.034.446.171.816.413 1.112.241.296.556.517.944.663.391.146.829.219 1.312.219.504 0 .946-.075 1.325-.226.383-.154.683-.366.9-.637.217-.275.327-.596.331-.963-.004-.333-.102-.608-.293-.825-.192-.22-.46-.404-.807-.55a7.77 7.77 0 0 0-1.2-.4L6.75 9.137c-1.054-.27-1.888-.68-2.5-1.23-.608-.555-.913-1.29-.913-2.207 0-.754.205-1.415.613-1.981.412-.567.973-1.006 1.681-1.319.709-.317 1.51-.475 2.406-.475.909 0 1.705.158 2.388.475.688.313 1.227.748 1.619 1.306.391.554.593 1.192.606 1.913h-2.231Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgMoney;
