import * as React from "react";
import { SVGProps } from "react";

const SvgEye = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M6.188 8.5a1.75 1.75 0 1 0 3.5 0 1.75 1.75 0 0 0-3.5 0Zm8.534-.403c-1.481-3.12-3.72-4.69-6.722-4.69-3.003 0-5.24 1.57-6.722 4.691a.942.942 0 0 0 0 .805c1.481 3.12 3.72 4.69 6.722 4.69 3.003 0 5.24-1.57 6.722-4.691a.942.942 0 0 0 0-.805ZM7.938 11.25a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgEye;
