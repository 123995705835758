import * as React from "react";
import { SVGProps } from "react";

const SvgAttachment = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m10.398 5.56-4.31 4.31a.761.761 0 1 0 1.077 1.077l4.31-4.31a2.286 2.286 0 1 0-3.232-3.232l-4.31 4.31a3.81 3.81 0 1 0 5.387 5.387l4.31-4.31 1.077 1.078-4.31 4.31a5.335 5.335 0 0 1-8.699-5.813 5.333 5.333 0 0 1 1.157-1.73l4.31-4.31a3.81 3.81 0 0 1 5.387 5.387l-4.31 4.312a2.288 2.288 0 0 1-3.728-.742 2.286 2.286 0 0 1 .496-2.492l4.31-4.31 1.078 1.077Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgAttachment;
