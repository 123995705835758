import * as React from "react";
import { SVGProps } from "react";

const SvgYoutubeLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path fill="currentcolor" d="M5.287 4.993h5.969v5.985H5.287z" />
		<path
			d="m8.354 13.52-3.282-.06c-1.063-.022-2.128.02-3.17-.197C.317 12.94.204 11.353.087 10.02c-.162-1.872-.1-3.779.206-5.635.173-1.042.851-1.664 1.901-1.732 3.544-.245 7.111-.216 10.647-.101.373.01.75.067 1.118.133 1.817.318 1.861 2.118 1.98 3.632.117 1.53.067 3.069-.157 4.589-.18 1.258-.525 2.313-1.98 2.415-1.823.133-3.604.24-5.432.207 0-.009-.01-.009-.016-.009Zm-1.93-3.186c1.374-.79 2.722-1.565 4.087-2.348-1.376-.789-2.72-1.564-4.086-2.348v4.696Z"
			fill="red"
		/>
	</svg>
);

export default SvgYoutubeLogo;
