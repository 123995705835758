import * as React from "react";
import { SVGProps } from "react";

const SvgSupport = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M15.67 12.006a4.604 4.604 0 0 1-3.615 4.494l-.49-1.468a3.07 3.07 0 0 0 2.16-1.494h-1.89a1.534 1.534 0 0 1-1.534-1.534V8.936a1.534 1.534 0 0 1 1.534-1.534h2.253a6.136 6.136 0 0 0-12.176 0h2.253A1.534 1.534 0 0 1 5.7 8.937v3.068a1.534 1.534 0 0 1-1.534 1.534h-2.3A1.534 1.534 0 0 1 .33 12.004V8.17a7.67 7.67 0 1 1 15.338 0v3.837Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgSupport;
