import * as React from "react";
import { SVGProps } from "react";

const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 15.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm-.924-5.821v.119h1.432v-.12a1.66 1.66 0 0 1 .095-.577c.063-.156.157-.294.283-.413a2.49 2.49 0 0 1 .492-.343c.236-.13.442-.276.616-.437.174-.163.308-.35.404-.56.098-.212.148-.455.148-.728 0-.408-.11-.757-.33-1.046-.219-.29-.52-.51-.906-.661a3.567 3.567 0 0 0-1.33-.231 3.33 3.33 0 0 0-1.252.227c-.378.15-.681.377-.91.683-.23.303-.35.688-.363 1.154h1.542a.854.854 0 0 1 .51-.773c.146-.068.3-.101.465-.101.168 0 .322.032.46.098a.812.812 0 0 1 .337.28.73.73 0 0 1 .125.423c0 .151-.037.29-.11.413a1.283 1.283 0 0 1-.295.332c-.123.1-.263.197-.42.29-.208.122-.387.257-.536.407-.148.149-.262.345-.34.587-.075.243-.115.568-.117.977Zm.102 2.394a.925.925 0 0 0 .646.245.913.913 0 0 0 .775-.416.77.77 0 0 0 .132-.424.776.776 0 0 0-.276-.588.915.915 0 0 0-.63-.245.925.925 0 0 0-.647.245.76.76 0 0 0-.26.588.775.775 0 0 0 .26.595Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgQuestion;
