const YoutubeNoAuthWarning = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.648 8.35094C18.5939 7.67282 18.5111 6.99249 18.3844 6.4984C18.1836 5.7128 17.59 5.0936 16.834 4.8808C15.4668 4.5 10.75 4.5 10.75 4.5C10.75 4.5 6.0356 4.5 4.666 4.8808C3.9132 5.0904 3.3188 5.7104 3.1156 6.4984C2.75 7.924 2.75 10.9 2.75 10.9C2.75 10.9 2.75 13.876 3.1156 15.3016C3.3164 16.0872 3.91 16.7064 4.666 16.9192C6.0356 17.3 10.75 17.3 10.75 17.3C10.75 17.3 11.1956 17.3 11.8525 17.2889C11.7802 17.1359 11.7242 16.9749 11.6858 16.8087C11.5976 16.4265 11.6055 16.0283 11.7087 15.6498C11.8108 15.2752 12.0033 14.9313 12.2691 14.6484L17.5186 9.02402C17.8251 8.69688 18.217 8.4642 18.648 8.35094ZM18.7479 10.6381L13.728 16.0165C13.6854 16.0615 13.6545 16.1163 13.6382 16.176C13.6219 16.2358 13.6207 16.2987 13.6346 16.359C13.6485 16.4194 13.6772 16.4753 13.718 16.5219C13.7589 16.5685 13.8106 16.6042 13.8686 16.6259L15.2488 17.1442C15.9075 17.0901 16.4819 17.0173 16.834 16.9192C17.5868 16.7096 18.1812 16.0896 18.3844 15.3016C18.75 13.876 18.75 10.9 18.75 10.9C18.75 10.9 18.75 10.8039 18.7479 10.6381ZM13.95 10.9L9.15 13.7V8.1L13.95 10.9Z"
			fill="#EE913B"
		/>
		<path
			d="M21.7746 15.7808L16.5246 21.4058C16.4689 21.4651 16.3955 21.5048 16.3153 21.5188C16.2352 21.5328 16.1526 21.5203 16.0802 21.4832C16.0077 21.4462 15.9493 21.3866 15.9137 21.3134C15.8781 21.2403 15.8673 21.1575 15.8828 21.0776L16.57 17.6403L13.8686 16.6259C13.8106 16.6042 13.7589 16.5685 13.718 16.5219C13.6772 16.4753 13.6485 16.4194 13.6346 16.359C13.6207 16.2986 13.6219 16.2358 13.6382 16.176C13.6545 16.1163 13.6854 16.0615 13.728 16.0165L18.978 10.3915C19.0336 10.3322 19.1071 10.2925 19.1872 10.2785C19.2674 10.2645 19.3499 10.277 19.4224 10.3141C19.4948 10.3511 19.5532 10.4107 19.5888 10.4839C19.6244 10.557 19.6353 10.6398 19.6197 10.7197L18.9306 14.1608L21.6321 15.1737C21.6896 15.1956 21.741 15.2313 21.7815 15.2777C21.822 15.3241 21.8505 15.3797 21.8644 15.4397C21.8783 15.4997 21.8772 15.5622 21.8612 15.6217C21.8452 15.6812 21.8148 15.7358 21.7727 15.7808H21.7746Z"
			fill="#EE913B"
		/>
	</svg>
);

export default YoutubeNoAuthWarning;
