import { z } from "zod";
import { NotificationPreferencesSchema, UsernameSchema } from "./user";

export const UpdateCreatorProfileSchema = z.object({
	username: UsernameSchema,
	industries: z.array(z.string()),
	hasCompletedOnboarding: z.boolean(),
	isAutomaticPitchingEnabled: z.boolean(),
	automaticPitchingTemplateUuid: z.string().uuid().optional(),
	user: z.object({
		firstName: z.string(),
		lastName: z.string(),
		profilePicture: z
			.object({
				uuid: z.string(),
			})
			.optional(),
		notificationPreferences: NotificationPreferencesSchema,
		getEmailOnMatchUpdate: z.boolean(),
	}),
	pronouns: z.string().nullable(),
});
export type UpdateCreatorProfile = z.infer<typeof UpdateCreatorProfileSchema>;

export const UpdateAgencyCreatorUsernameSchema = z.object({
	creatorUuid: z.string().uuid(),
	username: UsernameSchema,
});
