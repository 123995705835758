import { useMemo, useState } from "react";
import { ChevronRight } from "@withjuly/julycons";
import { cx } from "../classnames";

interface Value<T> {
	value: T;
	display: string;
}

interface BreadCrumbsProps<T> {
	currentValue: T;
	onValueChange?: (value: T) => void;
	values: Array<Value<T>>;
}

export const BreadCrumbs = <T,>(props: BreadCrumbsProps<T>) => {
	const { currentValue, onValueChange, values } = props;

	const activeStates = useMemo(() => {
		const currentStateIndex = values.findIndex(
			(state) => state.value === currentValue,
		);

		return values.filter((_, i) => i <= currentStateIndex);
	}, [currentValue]);

	const onChange = (value: T) => {
		onValueChange?.(value);
	};

	return (
		<div className="flex items-center">
			{activeStates.map((state, i) => {
				return (
					<>
						<button
							key={`${i}-${state.value}`}
							onClick={() => onChange(state.value)}
							className={cx(
								"text-overline-xs text-grayscale-8 hover:bg-surface-hover-1 hover:text-grayscale-8 flex h-6 items-center justify-center rounded-full px-2 uppercase transition-colors",
								state.value !== currentValue && "text-grayscale-6",
							)}
						>
							{state.display}
						</button>
						<ChevronRight
							key={`chevron-${i}`}
							className="h-3 w-3 text-white opacity-40 last:hidden odd:hidden"
						/>
					</>
				);
			})}
		</div>
	);
};
