import * as React from "react";
import { SVGProps } from "react";

const SvgPin = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m4.523 12.684 2.77-2.77 2.652 2.652a.234.234 0 0 0 .345-.007c.511-.601 1.347-1.937 1.6-3.705l1.767-1.768a1 1 0 0 0 0-1.414l-2.829-2.829a1 1 0 0 0-1.414 0L7.646 4.611c-1.545 0-3.012.977-3.707 1.597a.239.239 0 0 0-.005.347l2.652 2.652-2.77 2.77a.2.2 0 0 0-.059.141v.425c0 .11.09.2.2.2h.425a.2.2 0 0 0 .141-.059Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgPin;
