import { z } from "zod";
import { Platform } from "./profile";
import { OffsetPaginatedSchema } from "./utils";

export const AlsoWorkingWithMatchInfoSchema = z.object({
	postUrl: z.optional(z.string().url()),
	imageUrl: z.optional(z.string().url()),
	caption: z.optional(z.string()),
	displayName: z.optional(z.string()),
	username: z.optional(z.string()),
	followerCount: z.number(),
	platform: z.nativeEnum(Platform),
});
export type AlsoWorkingWithMatchInfo = z.infer<
	typeof AlsoWorkingWithMatchInfoSchema
>;

export enum MediaType {
	CAROUSEL_ALBUM = "CAROUSEL_ALBUM",
	IMAGE = "IMAGE",
	VIDEO = "VIDEO",
}

export const PostMatchInfoSchema = z.object({
	postedAt: z.date().optional(),
	mediaUrl: z.string().url(),
	mediaType: z.nativeEnum(MediaType),
	postUrl: z.string().url(),
});
export type PostMatchInfo = z.infer<typeof PostMatchInfoSchema>;

export const MatchProfileSchema = z.object({
	platform: z.nativeEnum(Platform),
	username: z.string(),
});
export type MatchProfile = z.infer<typeof MatchProfileSchema>;

export const CampaignMatchInfoSchema = z.object({
	uuid: z.string().uuid(),
	lastActive: z.optional(z.date()),
	isActive: z.boolean(),
	name: z.optional(z.string()),
	description: z.optional(z.string()),
	alsoWorkingWith: z.array(AlsoWorkingWithMatchInfoSchema),
	industries: z.string(),
});
export type CampaignMatchInfo = z.infer<typeof CampaignMatchInfoSchema>;

export const CompanyMatchInfoSchema = z.object({
	uuid: z.string().uuid(),
	name: z.string(),
	description: z.optional(z.string()),
	url: z.optional(z.string().url()),
	logoUrl: z.optional(z.string().url()),
	profile: MatchProfileSchema,
	posts: z.array(PostMatchInfoSchema),
	profilePicUrl: z.optional(z.string().url()),
});
export type CompanyMatchInfo = z.infer<typeof CompanyMatchInfoSchema>;

export const BecauseYouRequestedSchema = z.object({
	companyName: z.string(),
});
export type BecauseYouRequested = z.infer<typeof BecauseYouRequestedSchema>;

export const MatchSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	percent: z.optional(z.number()),
	isFromWishlist: z.boolean(),
	isPartnershipEmail: z.boolean(),
	becauseYouRequested: z.optional(BecauseYouRequestedSchema),
});
export type Match = z.infer<typeof MatchSchema>;

export const BrandSchema = z.object({
	campaign: CampaignMatchInfoSchema.optional(),
	company: CompanyMatchInfoSchema,
	match: MatchSchema.optional(),
	brandWithBudget: z.boolean().optional(),
	isVerifiedEmail: z.boolean().optional(),
	lastPitched: z.date().optional(),
});
export type Brand = z.infer<typeof BrandSchema>;

export const UpdateMatchSchema = z.object({
	hasBeenRemoved: z.optional(z.boolean()),
	hasBeenInteracted: z.optional(z.boolean()),
});
export type UpdateMatchRequest = z.infer<typeof UpdateMatchSchema>;

export const GetMatchesResponseSchema = z.object({
	matches: z.array(MatchSchema),
});
export type GetMatchesResponse = z.infer<typeof GetMatchesResponseSchema>;

export const PaginatedBrandsSchema = OffsetPaginatedSchema.extend({
	items: z.array(BrandSchema),
});
export type PaginatedBrands = z.infer<typeof PaginatedBrandsSchema>;

export const QueueableMatchSchema = z.object({
	uuid: z.string().uuid(),
	companyName: z.string(),
	companyUrl: z.optional(z.string().url()),
});
export type QueueableMatch = z.infer<typeof QueueableMatchSchema>;

export const QueueableMatchesSchema = z.array(QueueableMatchSchema);
export type QueueableMatches = z.infer<typeof QueueableMatchesSchema>;

export const DeleteManyMatchesSchema = z.object({
	uuids: z
		.object({
			type: z.enum(["match", "campaign"]),
			data: z.string().uuid(),
		})
		.array(),
});
export type DeleteManyMatchesRequest = z.infer<typeof DeleteManyMatchesSchema>;

export const GetBrandBookCompaniesFormSchema = z.object({
	name: z.string().optional(),
	verifiedEmail: z.boolean().optional(),
	industry: z.string().optional(),
	cursor: z.string().uuid().optional(),
});
export type GetBrandBookCompaniesForm = z.infer<
	typeof GetBrandBookCompaniesFormSchema
>;

export const UpdateAutomaticPitchingFormSchema = z.object({
	isEnabled: z.boolean(),
	templateUuid: z.string().uuid().optional(),
});
export type UpdateAutomaticPitchingFormSchema = z.infer<
	typeof UpdateAutomaticPitchingFormSchema
>;
