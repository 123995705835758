import * as React from "react";
import { SVGProps } from "react";

const SvgDeleteRed = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2 15a1.5 1.5 0 0 0 1.5 1.5h9A1.5 1.5 0 0 0 14 15V4.5H2V15Zm8.5-8a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0V7Zm-3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0V7Zm-3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0V7Zm10-5.5h-3.75l-.294-.584A.75.75 0 0 0 9.784.5H6.212a.741.741 0 0 0-.668.416L5.25 1.5H1.5A.5.5 0 0 0 1 2v1a.5.5 0 0 0 .5.5h13A.5.5 0 0 0 15 3V2a.5.5 0 0 0-.5-.5Z"
			fill="#CB4345"
		/>
	</svg>
);

export default SvgDeleteRed;
