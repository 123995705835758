import * as Dialog from "@radix-ui/react-dialog";
import { css, keyframes } from "@emotion/react";
import {
	breakpoint,
	color,
	modalSizes,
	radius,
	shadow,
	zIndex,
} from "../../theme";
import { ModalSize } from "./Root";
import { ModalOverlay } from "./Overlay";

const mobileAnimateIn = keyframes`
	from {
		opacity: 0;
		transform: translate(0, 100%);
	}
	to { 
		opacity: 1;
		transform: translate(0,0);
	}
`;

const mobileAnimateOut = keyframes`
	from { 
		opacity: 1;
		transform: translate(0,0);
	}
	to {
		opacity: 0;
		transform: translate(0, 100%);
	}
`;

const animateIn = keyframes`
	from { 
		opacity: 0;
		transform: translate(-50%, -49.5%);
	}

	to { 
		opacity: 1; 
		transform: translate(-50%, -50%);
	}
`;

const animateOut = keyframes`
	from { 
		opacity: 1;
		transform: translate(-50%, -50%);
	}

	to { 
		opacity: 0; 
		transform: translate(-50%, -49.5%);
	}
`;

export interface ModalContentProps extends Dialog.DialogContentProps {
	size?: ModalSize;
	passThroughChildren: boolean;
}

export const ModalContent: React.FC<ModalContentProps> = ({
	children,
	size,
	passThroughChildren,
	...props
}) => {
	return (
		<Dialog.Portal>
			<ModalOverlay />
			{passThroughChildren ? (
				<Dialog.Content
					css={css`
						position: fixed;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						z-index: ${zIndex.dialog};

						&[data-state="open"] {
							animation: ${animateIn} 200ms ease-in;
						}

						&[data-state="closed"] {
							animation: ${animateOut} 100ms ease-out;
						}
					`}
				>
					{children}
				</Dialog.Content>
			) : (
				<Dialog.Content
					{...props}
					css={css`
						outline: none;
						position: fixed;
						z-index: ${zIndex.dialog};
						background: ${color.gray[500]};
						filter: drop-shadow(${shadow[100]});
						display: flex;
						flex-direction: column;
						will-change: opacity;
						max-height: calc(100% - 1rem);
						width: 100%;
						bottom: 0;
						border-top-left-radius: 0.75rem;
						border-top-right-radius: 0.75rem;
						&[data-state="open"] {
							animation: ${mobileAnimateIn} 100ms ease-in;
						}
						&[data-state="closed"] {
							animation: ${mobileAnimateOut} 100ms ease-out;
						}
						${breakpoint.md} {
							&[data-state="open"] {
								animation: ${animateIn} 100ms ease-in;
							}
							&[data-state="closed"] {
								animation: ${animateOut} 100ms ease-out;
							}
							transform: translate(-50%, -50%);
							left: 50%;
							top: 50%;
							height: fit-content;
							border: 1px solid ${color.gray[300]};
							max-width: ${size ?? modalSizes[640]};
							max-height: calc(
								100vh - 63px - 2rem - 2rem
							); // Modal goes behind the header, so take out the header height (63px) and add in 2rem worth of padding
							border-radius: ${radius.lg};
						}
					`}
				>
					{children}
				</Dialog.Content>
			)}
		</Dialog.Portal>
	);
};
