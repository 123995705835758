import {
	JSXElementConstructor,
	PropsWithChildren,
	ReactElement,
	useMemo,
} from "react";
import { cx } from "../classnames";
import { ArrowDown } from "@withjuly/julycons/bold";
import {
	Button,
	ButtonProps,
	DropdownMenu,
	IconButton,
	IconButtonProps,
	Tag,
	TagProps,
} from "../components";
import { Avatar, AvatarProps } from "./Avatar";

interface RootProps {
	className?: string;
}

export const Root: React.FC<PropsWithChildren<RootProps>> = ({
	className,
	children,
}) => {
	return (
		<div role="table" className={cx("w-full px-4", className)}>
			{children}
		</div>
	);
};

interface HeadProps {
	className?: string;
}

export const Head: React.FC<PropsWithChildren<HeadProps>> = ({
	className,
	children,
}) => {
	return (
		<div className={cx("border-b-stroke-secondary border-b", className)}>
			{children}
		</div>
	);
};

interface BodyProps {
	className?: string;
}

export const Body: React.FC<PropsWithChildren<BodyProps>> = ({
	className,
	children,
}) => {
	return <div className={cx("", className)}>{children}</div>;
};

interface RowProps {
	onClick?: () => void;
	className?: string;
	size?: "md" | "lg";
}

export const Row: React.FC<PropsWithChildren<RowProps>> = ({
	onClick,
	size = "md",
	className,
	children,
}) => {
	return (
		<div
			role={onClick ? "button" : undefined}
			onClick={onClick}
			className={cx(
				"border-b-stroke-secondary rounded-solis-sm flex h-14 w-full items-center gap-4 border-b px-2 last:border-b-0",
				size === "lg" && "h-16",
				onClick !== undefined &&
					"hover:bg-surface-hover-1 rounded-solis-sm cursor-pointer",
				className,
			)}
		>
			{children}
		</div>
	);
};

interface BaseCellProps {
	align?: "left" | "right";
	className?: string;
}

interface DefaultCellProps extends PropsWithChildren<BaseCellProps> {
	variant?: "default";
}

interface CompactCellProps extends PropsWithChildren<BaseCellProps> {
	variant?: "compact";
}

interface ComplexCellProps extends PropsWithChildren<BaseCellProps> {
	variant: "complex";
	secondaryText: string;
}

interface AccountCellProps extends BaseCellProps, AvatarProps {
	variant: "account";
	children?: string;
}

interface TagCellProps extends BaseCellProps, TagProps {
	variant: "tag";
}

interface IconCellProps extends BaseCellProps {
	variant: "icon";
	icon: JSXElementConstructor<{ className?: string }>;
	color?: "primary" | "brand";
}

interface IconButtonCellProps extends BaseCellProps {
	variant: "icon-button";
	icon: IconButtonProps["icon"];
	buttonVariant?: IconButtonProps["variant"];
	onClick?: IconButtonProps["onClick"];
}

interface ButtonCellProps extends PropsWithChildren<BaseCellProps> {
	variant: "button";
	buttonVariant?: ButtonProps["variant"];
	size?: ButtonProps["size"];
	onClick?: ButtonProps["onClick"];
	disabled?: boolean;
}

interface DropdownCellProps extends BaseCellProps {
	variant: "dropdown";
	children: ReactElement<DropdownMenu.RootProps>;
}

type HeadCellProps =
	| ({
			variant: "th";
			onClick?: () => void;
			selected?: undefined;
			tooltip?: string;
	  } & BaseCellProps)
	| ({
			variant: "th";
			onClick?: () => void;
			selected: true;
			state: "default" | "reverse";
			tooltip?: string;
	  } & BaseCellProps);

type CellProps =
	| DefaultCellProps
	| CompactCellProps
	| ComplexCellProps
	| AccountCellProps
	| PropsWithChildren<HeadCellProps>
	| TagCellProps
	| IconCellProps
	| IconButtonCellProps
	| ButtonCellProps
	| DropdownCellProps;

export const Cell: React.FC<CellProps> = (props) => {
	const { align = "left", className } = props;

	const data = useMemo(() => {
		if (props.variant === undefined || props.variant === "default") {
			return <div className="text-paragraph-sm">{props.children}</div>;
		} else if (props.variant === "th") {
			const data = (
				<>
					<p
						className={`text-overline-2xs ${props.selected ? "text-text-secondary" : "text-text-tertiary"} ${props.onClick ? "hover:text-text-secondary" : ""}`}
					>
						{props.children}
					</p>
					{props.selected ? (
						<ArrowDown
							className={cx(
								"text-text-secondary h-3 w-3",
								props.selected === true &&
									props.state === "reverse" &&
									"rotate-180 transform",
							)}
						/>
					) : null}
				</>
			);
			if (props.onClick) {
				return (
					<button
						className="hover:text-text-secondary flex items-center gap-1"
						onClick={() => props.onClick?.()}
					>
						{data}
					</button>
				);
			} else {
				return <div className="flex items-center gap-1">{data}</div>;
			}
		} else if (props.variant === "compact") {
			return (
				<p className="text-paragraph-xs text-text-tertiary">{props.children}</p>
			);
		} else if (props.variant === "complex") {
			return (
				<div className="flex flex-col gap-[-2px]">
					<p>{props.children}</p>
					<p className="text-paragraph-xs text-text-tertiary">
						{props.secondaryText}
					</p>
				</div>
			);
		} else if (props.variant === "account") {
			return (
				<div className="flex items-center gap-[10px]">
					<Avatar size={props.size} alt={props.alt} src={props.src} />
					<p>{props.children}</p>
				</div>
			);
		} else if (props.variant === "tag") {
			return (
				<Tag
					size={props.size}
					text={props.text}
					color={props.color}
					leftIcon={props.leftIcon}
					rightIcon={props.rightIcon}
				/>
			);
		} else if (props.variant === "icon") {
			const Icon = props.icon;
			return (
				<Icon
					className={cx(
						"text-text-primary h-4 w-4",
						props.color === "brand" && "text-brand",
					)}
				/>
			);
		} else if (props.variant === "icon-button") {
			return (
				<IconButton
					icon={props.icon}
					size="sm"
					variant={props.buttonVariant ?? "secondary"}
					onClick={props.onClick}
				/>
			);
		} else if (props.variant === "button") {
			return (
				<Button
					size="sm"
					variant={props.buttonVariant ?? "outline"}
					onClick={props.onClick}
					disabled={props?.disabled}
				>
					{props.children}
				</Button>
			);
		} else if (props.variant === "dropdown") {
			return props.children;
		} else {
			return null;
		}
	}, [props]);

	return (
		<div
			className={cx(
				"font-repro flex w-full flex-row truncate text-ellipsis",
				align === "left" && "justify-start",
				align === "right" && "justify-end",
				className,
			)}
		>
			{data}
		</div>
	);
};
