import * as React from "react";
import { SVGProps } from "react";

const SvgLinkedinLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#linkedin-logo_svg__a)">
			<path
				d="M0 1.174C0 .544.53.032 1.183.032h13.635C15.47.032 16 .543 16 1.174v13.653c0 .63-.53 1.141-1.182 1.141H1.182C.53 15.968 0 15.457 0 14.827V1.173Z"
				fill="#069"
			/>
			<path
				d="M4.862 13.368V6.194H2.468v7.174h2.394ZM3.666 5.214c.834 0 1.354-.55 1.354-1.239-.016-.704-.52-1.24-1.338-1.24-.82 0-1.355.536-1.355 1.24 0 .688.52 1.24 1.323 1.24h.015Zm2.522 8.154h2.394V9.362c0-.214.015-.429.078-.582.173-.428.567-.872 1.229-.872.866 0 1.213.658 1.213 1.623v3.837h2.394V9.255c0-2.204-1.181-3.23-2.756-3.23-1.292 0-1.86.72-2.174 1.21h.016v-1.04H6.188c.03.672 0 7.173 0 7.173Z"
				fill="currentcolor"
			/>
		</g>
		<defs>
			<clipPath id="linkedin-logo_svg__a">
				<path fill="currentcolor" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default SvgLinkedinLogo;
