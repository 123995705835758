import * as React from "react";
import { SVGProps } from "react";

const SvgPitch = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1.263 6.388c-.362-.12-.366-.316.007-.44l13.239-4.413c.367-.122.577.084.474.443L11.2 15.216c-.104.367-.316.38-.47.031l-2.494-5.61 4.162-5.548L6.849 8.25 1.263 6.388Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgPitch;
