import { cx } from "../classnames";
import { theme } from "../theme";

export const ProgressCircle = ({
	radius = 8,
	stroke = 2,
	progress,
	trackColor = "transparent",
	color = theme.color.white[100],
	className,
}: {
	radius: number;
	stroke: number;
	progress: number;
	trackColor?: string;
	color?: string;
	className?: string;
}) => {
	const size = radius * 2 + stroke * 2;
	const circumference = 2 * Math.PI * radius;
	const offset = circumference - (progress / 100) * circumference;

	return (
		<svg
			style={{
				height: size,
				width: size,
			}}
			className={cx("-rotate-90 -scale-y-100", className)}
		>
			<circle
				stroke={trackColor}
				r={radius}
				strokeWidth={stroke}
				fill="transparent"
				strokeLinecap="round"
				cx={size / 2}
				cy={size / 2}
			/>
			<circle
				stroke={color}
				r={radius}
				strokeWidth={stroke}
				fill="transparent"
				strokeLinecap="round"
				strokeDasharray={circumference}
				strokeDashoffset={offset}
				cx={size / 2}
				cy={size / 2}
			/>
		</svg>
	);
};
