import * as React from "react";
import { SVGProps } from "react";

const SvgExpand = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#expand_svg__a)">
			<path
				d="M9.847 2.038V.5H16v6.153h-1.538V3.126L10.018 7.57 8.93 6.483l4.444-4.445H9.847ZM1.538 10.347H0V16.5h6.153v-1.538H2.626l4.444-4.445L5.983 9.43l-4.445 4.444v-3.527Z"
				fill="currentcolor"
			/>
			<path
				d="M9.847 2.038V.5H16v6.153h-1.538V3.126L10.018 7.57 8.93 6.483l4.444-4.445H9.847ZM1.538 10.347H0V16.5h6.153v-1.538H2.626l4.444-4.445L5.983 9.43l-4.445 4.444v-3.527Z"
				stroke="currentcolor"
			/>
		</g>
		<defs>
			<clipPath id="expand_svg__a">
				<path
					fill="currentcolor"
					transform="translate(0 .5)"
					d="M0 0h16v16H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgExpand;
