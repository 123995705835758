import * as React from "react";
import { SVGProps } from "react";

const SvgUnread = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M14.667 6.3v7.533A.667.667 0 0 1 14 14.5H2a.666.666 0 0 1-.667-.667V3.167A.667.667 0 0 1 2 2.5h8.035a3.491 3.491 0 0 0 1.117 3.096L8.041 8.289l-4.276-3.63-.863 1.016 5.147 4.37 4.314-3.734a3.495 3.495 0 0 0 2.303-.01Z"
			fill="currentcolor"
		/>
		<path d="M16 3a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" fill="currentcolor" />
	</svg>
);

export default SvgUnread;
