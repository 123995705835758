import * as RadixToast from "@radix-ui/react-toast";
import { Close } from "@withjuly/julycons";
import { cx } from "../../classnames";
import { ToastStatus } from "../../hooks/use-toast";
import { color } from "../../theme";

export interface ToastProps
	extends React.ComponentProps<typeof RadixToast.Root> {
	status: ToastStatus;
	icon?: React.ReactNode;
	duration?: number;
	children: React.ReactNode;
}

export const Toast: React.FC<ToastProps> = ({
	status,
	icon,
	duration,
	children,
	...props
}) => {
	const isNeutral = status === "neutral";

	return (
		<RadixToast.Root
			duration={duration}
			className={cx(
				"flex max-w-[30rem] items-center gap-3 rounded-lg p-3",
				"data-[state=open]:animate-toast-slide-in data-[state=closed]:animate-hide",
				"data-[swipe=end]:animate-toast-swipe-out data-[swipe=cancel]:translate-x-0 data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:transition-[transform_200ms_ease-out]",
			)}
			style={{
				background: isNeutral ? color.gray[300] : color[status][500],
			}}
			{...props}
		>
			{icon && (
				<div
					className={
						"flex aspect-square items-center rounded-full p-3 leading-[0]"
					}
					style={{
						background: isNeutral ? color.gray[600] : color[status][700],
						color: isNeutral ? color.gray[100] : color[status][200],
					}}
				>
					{icon}
				</div>
			)}
			<div className="flex flex-col gap-1">{children}</div>
			<RadixToast.Close className="self-start">
				<Close />
			</RadixToast.Close>
		</RadixToast.Root>
	);
};
