import * as React from "react";
import { SVGProps } from "react";

const SvgBankIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 .5a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4H4Zm9 13v-1H3v1h10Zm-8-5H4V12h1V8.5Zm2.5 0h-1V12h1V8.5Zm2 0h-1V12h1V8.5Zm2.5 0h-1V12h1V8.5Zm-4-5L3 6v2h10V6L8 3.5Zm.354 2.854a.5.5 0 1 1-.708-.708.5.5 0 0 1 .708.708Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgBankIcon;
