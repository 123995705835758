import * as React from "react";
import { SVGProps } from "react";

const SvgHeartEmpty = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8 3.417a3.599 3.599 0 0 1 5.087 5.079L8 13.59 2.913 8.496A3.601 3.601 0 0 1 8 3.417Zm4.096.985a2.399 2.399 0 0 0-3.294-.092l-.8.719L7.2 4.31a2.4 2.4 0 0 0-3.412 3.363L8 11.892l4.212-4.218a2.4 2.4 0 0 0-.116-3.272Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgHeartEmpty;
