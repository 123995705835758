import * as React from "react";
import { SVGProps } from "react";

const SvgVenmoLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#venmo-logo_svg__a)">
			<path
				d="M1.891.5H14.11C15.153.5 16 1.347 16 2.391V14.61a1.892 1.892 0 0 1-1.891 1.891H1.89A1.892 1.892 0 0 1 0 14.608V2.391C0 1.347.847.5 1.892.5Z"
				fill="#3396CD"
			/>
			<path
				d="M11.943 3.756c.344.567.498 1.152.498 1.891 0 2.357-2.01 5.417-3.644 7.566H5.07l-1.494-8.94 3.265-.311.794 6.362c.738-1.204 1.65-3.095 1.65-4.384 0-.706-.12-1.186-.31-1.581l2.969-.603Z"
				fill="currentcolor"
			/>
		</g>
		<defs>
			<clipPath id="venmo-logo_svg__a">
				<rect y={0.5} width={16} height={16} rx={4} fill="currentcolor" />
			</clipPath>
		</defs>
	</svg>
);

export default SvgVenmoLogo;
