import * as React from "react";
import { SVGProps } from "react";

const SvgUnlock = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.79 6.675v.003h-2.211v-.003H5.216V4.711c0-.745.294-1.459.816-1.985A2.772 2.772 0 0 1 8 1.904c.738 0 1.446.295 1.968.822a2.82 2.82 0 0 1 .816 1.985h1.391v-.53c0-1.117-.44-1.658-1.223-2.448A4.158 4.158 0 0 0 8 .5C6.893.5 5.83.944 5.048 1.733a4.229 4.229 0 0 0-1.223 2.978v1.964H2.433a.693.693 0 0 0-.492.206.705.705 0 0 0-.204.496v8.421c0 .186.073.365.204.496.13.132.307.206.492.206h11.134a.693.693 0 0 0 .492-.206.705.705 0 0 0 .204-.496v-8.42a.705.705 0 0 0-.204-.497.693.693 0 0 0-.492-.206h-.777Zm-5.486 7.018v-1.592a1.414 1.414 0 0 1-.151-2.329 1.384 1.384 0 0 1 1.694 0 1.414 1.414 0 0 1-.151 2.329v1.592H7.304Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgUnlock;
