import * as React from "react";
import { SVGProps } from "react";

const SvgTwitterLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.032 15.004c6.038 0 9.34-5.004 9.34-9.343 0-.143-.003-.284-.01-.425A6.677 6.677 0 0 0 16 3.536a6.542 6.542 0 0 1-1.885.517 3.296 3.296 0 0 0 1.443-1.817 6.582 6.582 0 0 1-2.084.797A3.284 3.284 0 0 0 7.88 6.028a9.32 9.32 0 0 1-6.767-3.43A3.282 3.282 0 0 0 2.13 6.981 3.258 3.258 0 0 1 .644 6.57v.042a3.284 3.284 0 0 0 2.633 3.22 3.286 3.286 0 0 1-1.482.056 3.286 3.286 0 0 0 3.066 2.28 6.584 6.584 0 0 1-4.077 1.406c-.262 0-.523-.015-.783-.045a9.289 9.289 0 0 0 5.032 1.475Z"
			fill="#1DA1F2"
		/>
	</svg>
);

export default SvgTwitterLogo;
