import * as React from "react";
import { SVGProps } from "react";

const SvgCollapse = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#collapse_svg__a)">
			<path
				d="M15.125 5.78v1.467H9.253V1.375h1.468v3.366L14.962.5 16 1.538l-4.241 4.241h3.366ZM5.28 15.625h1.467V9.753H.875v1.468h3.366L0 15.462 1.038 16.5l4.241-4.241v3.366Z"
				fill="currentcolor"
			/>
			<path
				d="M15.125 5.78v1.467H9.253V1.375h1.468v3.366L14.962.5 16 1.538l-4.241 4.241h3.366ZM5.28 15.625h1.467V9.753H.875v1.468h3.366L0 15.462 1.038 16.5l4.241-4.241v3.366Z"
				stroke="currentcolor"
			/>
		</g>
		<defs>
			<clipPath id="collapse_svg__a">
				<path
					fill="currentcolor"
					transform="translate(0 .5)"
					d="M0 0h16v16H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgCollapse;
