import * as React from "react";
import { SVGProps } from "react";

const SvgStarFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.563 1.292a.474.474 0 0 1 .874 0l1.833 4.405a.474.474 0 0 0 .4.29l4.755.382c.42.033.59.557.27.832l-3.623 3.103a.474.474 0 0 0-.153.47l1.107 4.64a.474.474 0 0 1-.707.515l-4.072-2.487a.474.474 0 0 0-.494 0L3.681 15.93a.474.474 0 0 1-.707-.514l1.107-4.64a.474.474 0 0 0-.153-.47L.305 7.2a.474.474 0 0 1 .27-.832l4.756-.382a.474.474 0 0 0 .4-.29l1.832-4.405Z"
			fill="#F3A94F"
		/>
	</svg>
);

export default SvgStarFilled;
