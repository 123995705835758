import { z } from "zod";

export const PromptNameSchema = z.enum([
	"CONTRACT_COMPANY_NAME",
	"CONTACT_COMPENSATION_TYPE",
	"CONTACT_COMPENSATION",
	"CONTRACT_DELIVERABLES",
	"CONTRACT_DEAL_TERMS",
]);
export type PromptName = z.infer<typeof PromptNameSchema>;

export const PromptSchema = z.object({
	name: PromptNameSchema,
	content: z.string().min(1),
});
export type Prompt = z.infer<typeof PromptSchema>;

export const UpsertPromptSchema = z.object({
	name: PromptNameSchema,
	content: z.string().min(1),
});
export type UpsertPrompt = z.infer<typeof UpsertPromptSchema>;

export const PromptsRecordSchema = z.record(PromptNameSchema, PromptSchema);
export type PromptsRecord = z.infer<typeof PromptsRecordSchema>;
