import * as React from "react";
import { SVGProps } from "react";

const SvgPercent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8.497 12.136v-.613c0-.451.095-.866.284-1.245.193-.378.474-.683.841-.914.368-.231.813-.347 1.335-.347.538 0 .989.116 1.353.347.363.227.638.53.823.909.19.379.285.795.285 1.25v.613c0 .451-.095.866-.284 1.245-.19.378-.468.683-.836.915-.363.23-.81.346-1.34.346s-.98-.115-1.347-.346a2.257 2.257 0 0 1-.835-.915 2.79 2.79 0 0 1-.279-1.245Zm1.483-.613v.613c0 .3.072.574.216.824.144.25.398.375.761.375.368 0 .62-.123.756-.37.14-.25.21-.526.21-.829v-.613c0-.303-.066-.58-.199-.83-.132-.254-.388-.38-.767-.38-.356 0-.608.126-.755.38a1.6 1.6 0 0 0-.222.83ZM2.582 5.477v-.613c0-.455.097-.872.29-1.25.193-.38.474-.682.841-.91.367-.23.813-.346 1.335-.346.534 0 .983.115 1.347.347.367.227.644.53.83.909.185.378.278.795.278 1.25v.613c0 .455-.095.871-.284 1.25a2.188 2.188 0 0 1-.83.904c-.367.227-.814.34-1.34.34-.535 0-.986-.113-1.353-.34a2.23 2.23 0 0 1-.83-.91 2.743 2.743 0 0 1-.284-1.244Zm1.495-.613v.613c0 .303.07.58.21.83.144.246.398.37.761.37.364 0 .614-.124.75-.37.14-.25.21-.527.21-.83v-.613c0-.303-.066-.58-.198-.83-.133-.254-.387-.38-.762-.38-.36 0-.611.126-.755.38-.144.254-.216.53-.216.83Zm-.847 9.454 8-11.636h1.42l-8 11.636H3.23Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgPercent;
