import * as React from "react";
import { SVGProps } from "react";

const SvgReply = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.333 13.833.667 8.5l6.666-5.333V6.5a6.667 6.667 0 0 1 6.646 7.207A6 6 0 0 0 8.667 10.5H7.333v3.333Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgReply;
