import * as React from "react";
import { SVGProps } from "react";

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.31 13.049a7.01 7.01 0 1 1 1.24-1.24l3.45 3.452-1.24 1.239-3.45-3.451Zm.958-5.539a5.258 5.258 0 1 1-10.515 0 5.258 5.258 0 0 1 10.515 0Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgSearch;
