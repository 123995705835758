import * as React from "react";
import { SVGProps } from "react";

const SvgFolder = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.21 3.38H1.44v10.24h13.12V5.668H7.602L5.21 3.38Zm3.35 3.742V8.82h1.69c.082 0 .15.062.15.14v.84c0 .076-.068.14-.15.14H8.56v1.698a.14.14 0 0 1-.14.142h-.84a.142.142 0 0 1-.14-.142V9.94H5.75c-.082 0-.15-.064-.15-.14v-.84c0-.078.068-.14.15-.14h1.69V7.122c0-.078.064-.142.14-.142h.84a.14.14 0 0 1 .14.142Z"
			fill="currentcolor"
		/>
		<path
			d="M15.36 4.228H8.18L5.834 1.984a.163.163 0 0 0-.11-.044H.64a.64.64 0 0 0-.64.64v11.84c0 .354.286.64.64.64h14.72a.64.64 0 0 0 .64-.64V4.868a.64.64 0 0 0-.64-.64Zm-.8 9.392H1.44V3.38h3.77l2.392 2.288h6.958v7.952Z"
			fill="currentcolor"
		/>
		<path
			d="M7.44 7.122V8.82H5.75c-.082 0-.15.062-.15.14v.84c0 .076.068.14.15.14h1.69v1.698c0 .078.064.142.14.142h.84a.14.14 0 0 0 .14-.142V9.94h1.69c.082 0 .15-.064.15-.14v-.84c0-.078-.068-.14-.15-.14H8.56V7.122a.14.14 0 0 0-.14-.142h-.84a.142.142 0 0 0-.14.142Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgFolder;
