import * as Dialog from "@radix-ui/react-dialog";
import { modalSizes, zIndex } from "../../theme";
import { ModalSize } from "../Modal";
import { ModalOverlay } from "../Modal/Overlay";
import { cx } from "../../classnames";

export interface DrawerContentProps extends Dialog.DialogContentProps {
	size?: ModalSize;
	placement?: "left" | "right";
}

export const DrawerContent: React.FC<DrawerContentProps> = ({
	children,
	size,
	placement = "right",
	...props
}) => {
	return (
		<Dialog.Portal>
			<ModalOverlay />
			<Dialog.Content
				{...props}
				className={cx(
					"shadow-100 fixed bottom-0 top-0 flex w-full flex-col border-none bg-gray-500 will-change-transform",
					placement === "left" &&
						"data-[state=open]:animate-slide-in-left data-[state=closed]:animate-slide-out-left left-0 lg:border-r lg:border-gray-300",
					placement === "right" &&
						"data-[state=open]:animate-slide-in-right data-[state=closed]:animate-slide-out-right right-0 lg:border-l lg:border-gray-300",
				)}
				style={{
					maxWidth: size ?? modalSizes[480],
					zIndex: zIndex.dialog,
				}}
			>
				{children}
			</Dialog.Content>
		</Dialog.Portal>
	);
};
