import { Info } from "@withjuly/julycons/bold";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { JSXElementConstructor, PropsWithChildren } from "react";

interface TooltipProps {
	icon?: JSXElementConstructor<{ className?: string }>;
	tooltip: string;
}

export const Tooltip: React.FC<TooltipProps & PropsWithChildren> = ({
	icon,
	tooltip,
	children,
}) => {
	const Icon = icon ? icon : Info;

	return (
		<RadixTooltip.Provider>
			<RadixTooltip.Root>
				<RadixTooltip.Trigger className="w-fit" asChild={true}>
					{!children ? (
						<div className="font-repro flex h-6 items-center justify-center">
							<Icon className="text-text-secondary h-4 w-4" />
						</div>
					) : (
						children
					)}
				</RadixTooltip.Trigger>
				<RadixTooltip.Content className="z-[200]" asChild={true}>
					<div className="font-repro rounded-solis-md bg-surface-quaternary border-stroke-primary border px-3 py-2">
						<p className="text-paragraph-xs max-w-[200px]">{tooltip}</p>
					</div>
				</RadixTooltip.Content>
			</RadixTooltip.Root>
		</RadixTooltip.Provider>
	);
};
