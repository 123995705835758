import * as React from "react";
import { SVGProps } from "react";

const SvgChevronRight = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3.722 13.944 5.278 15.5l7-7-7-7-1.556 1.556L9.167 8.5l-5.445 5.444Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgChevronRight;
