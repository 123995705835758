import * as React from "react";
import { SVGProps } from "react";

const SvgChart = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3.333 2.5v10.667H14V14.5H2v-12h1.333ZM13.53 4.695l.942.943-3.804 3.805-2-2-2.862 2.862-.943-.943 3.805-3.805 2 2 2.862-2.862Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgChart;
