import * as React from "react";
import { SVGProps } from "react";

const SvgDoublechevronRight = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.69 8.5 1 13.167 2.34 14.5l6.029-6-6.03-6L1 3.833 5.69 8.5Z"
			fill="currentcolor"
		/>
		<path
			d="m12.32 8.5-4.689 4.667 1.34 1.333L15 8.5l-6.029-6-1.34 1.333 4.69 4.667Z"
			fill="currentcolor"
		/>
		<path
			d="M5.69 8.5 1 13.167 2.34 14.5l6.029-6-6.03-6L1 3.833 5.69 8.5Z"
			stroke="currentcolor"
		/>
		<path
			d="m12.32 8.5-4.689 4.667 1.34 1.333L15 8.5l-6.029-6-1.34 1.333 4.69 4.667Z"
			stroke="currentcolor"
		/>
	</svg>
);

export default SvgDoublechevronRight;
