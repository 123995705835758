import React from "react";
import { Root as Label } from "@radix-ui/react-label";
import { css } from "@emotion/react";
import { color, fontSizes, radius, spacing } from "../../theme";
import { Add, Delete, Note } from "@withjuly/julycons";
import { cx } from "../../classnames";

export interface TextareaProps
	extends React.InputHTMLAttributes<HTMLTextAreaElement> {
	withUploader?: boolean;
	setFiles?: (files: File[]) => void;
	files?: File[];
	minHeight?: string;
	containerClassName?: string;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	(
		{
			id,
			name,
			withUploader,
			setFiles,
			files,
			minHeight,
			className,
			containerClassName,
			...rest
		},
		ref,
	) => {
		const getTextareaStyles = () => {
			const baseStyle = {
				display: "block",
				width: "100%",
				background: color.gray[600],
				border: "1px solid",
				borderColor: color.gray[200],
				padding: spacing[75],
				fontSize: fontSizes.body.lg,
				minHeight: minHeight ? minHeight : "5rem",
			};

			const inputRadius = {
				borderTopLeftRadius: radius.sm,
				borderTopRightRadius: radius.sm,
				borderBottomRightRadius: withUploader ? "0" : radius.sm,
				borderBottomLeftRadius: withUploader ? "0" : radius.sm,
			};

			return css([
				{ ...baseStyle, ...inputRadius },
				`&:active,
        &:focus {
            outline: none;
            border: 1px solid ${color.blue[500]};
        }
        &::placeholder {
            color: ${color.gray[200]};
        }
        &:disabled {
            color: ${color.gray[200]};
        }`,
			]);
		};

		const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (!e.target.files || !setFiles) {
				return;
			}
			const selectedFiles = Array.from(e.target.files);
			setFiles(selectedFiles);
			e.target.value = "";
		};

		const fileUploadWrapper = css`
			background: ${color.gray[600]};
			text-align: center;
			padding: ${spacing[100]} 0;
			border-bottom-left-radius: ${radius.sm};
			border-bottom-right-radius: ${radius.sm};
			cursor: pointer;
			border: 1px solid ${color.gray[200]};
			border-top: 0;
			font-size: 14px;
			display: flex;
			align-items: center;
			gap: ${spacing[50]};
			font-weight: bold;
		`;

		return (
			<div className={cx("w-full", containerClassName)}>
				<textarea
					id={id}
					name={name}
					css={getTextareaStyles()}
					ref={ref}
					{...rest}
					className={className}
				/>
				{withUploader && !files?.length && (
					<div>
						<Label htmlFor={`${id}-uploader`} className="w-full">
							<div css={css([fileUploadWrapper, `justify-content: center;`])}>
								<Add /> Add a file
							</div>
						</Label>
						<input
							id={`${id}-uploader`}
							type="file"
							className="absolute z-[-1] opacity-0"
							onChange={onSelectFile}
						/>
					</div>
				)}
				{withUploader && files?.length && setFiles ? (
					<div css={css(fileUploadWrapper)}>
						{files.map((file, i) => (
							<div className="flex w-full items-center justify-between px-7">
								<div className="flex items-center gap-1">
									<Note />
									{file.name} attached
								</div>
								<div
									className="text-gray-200 hover:text-gray-100"
									onClick={() => {
										const newState = [...files];
										newState.splice(i, 1);
										setFiles(newState);
									}}
								>
									<Delete />
								</div>
							</div>
						))}
					</div>
				) : null}
			</div>
		);
	},
);
