import * as React from "react";
import { SVGProps } from "react";

const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="16"
		height="14"
		viewBox="0 0 16 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M15.6344 2.59838C16 4.02398 16 6.99998 16 6.99998C16 6.99998 16 9.97598 15.6344 11.4016C15.4312 12.1896 14.8368 12.8096 14.084 13.0192C12.7168 13.4 8 13.4 8 13.4C8 13.4 3.2856 13.4 1.916 13.0192C1.16 12.8064 0.5664 12.1872 0.3656 11.4016C2.38419e-08 9.97598 0 6.99998 0 6.99998C0 6.99998 2.38419e-08 4.02398 0.3656 2.59838C0.5688 1.81038 1.1632 1.19038 1.916 0.980775C3.2856 0.599975 8 0.599976 8 0.599976C8 0.599976 12.7168 0.599975 14.084 0.980775C14.84 1.19358 15.4336 1.81278 15.6344 2.59838V2.59838ZM6.4 9.79998L11.2 6.99998L6.4 4.19998V9.79998Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgYoutube;
