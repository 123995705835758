import { ReactNode } from "react";

interface CardProps {
	children: ReactNode;
}

export const Card: React.FC<CardProps> = ({ children }) => {
	return (
		<div className="w-full rounded-lg border border-gray-300 bg-gray-500 p-6">
			{children}
		</div>
	);
};
