const TiktokNoAuthWarning = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21.5306 15.8809L16.5988 21.5059C16.5465 21.5652 16.4776 21.6049 16.4023 21.6189C16.327 21.6328 16.2494 21.6204 16.1814 21.5833C16.1133 21.5463 16.0584 21.4867 16.025 21.4135C15.9915 21.3404 15.9814 21.2576 15.996 21.1777L16.6415 17.7404L14.1039 16.726C14.0494 16.7043 14.0008 16.6686 13.9624 16.622C13.924 16.5754 13.8971 16.5195 13.884 16.4591C13.8709 16.3987 13.8721 16.3359 13.8874 16.2761C13.9027 16.2164 13.9317 16.1616 13.9718 16.1166L18.9035 10.4916C18.9558 10.4323 19.0248 10.3926 19.1001 10.3786C19.1754 10.3646 19.2529 10.3771 19.321 10.4142C19.389 10.4512 19.4439 10.5108 19.4773 10.584C19.5108 10.6571 19.521 10.7399 19.5064 10.8198L18.8591 14.2609L21.3967 15.2738C21.4508 15.2957 21.499 15.3314 21.5371 15.3778C21.5752 15.4241 21.6019 15.4798 21.615 15.5398C21.628 15.5998 21.627 15.6623 21.612 15.7218C21.5969 15.7813 21.5684 15.8359 21.5288 15.8809H21.5306Z"
			fill="#EE913B"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.601 3H11.3706C11.2601 3 11.1706 3.08954 11.1706 3.2V14.0638C11.1158 14.8014 10.5952 16.2766 8.95117 16.2766C6.89616 16.2766 6.64956 14.234 6.64956 13.8936C6.64956 13.5532 7.06056 11.5957 8.86897 11.5957C9.02473 11.5957 9.17433 11.6212 9.30032 11.654C9.44538 11.6918 9.60877 11.5871 9.60877 11.4372V9.10475C9.60877 9.01715 9.55205 8.93932 9.46659 8.92008C9.13313 8.84498 8.52879 8.78226 7.96477 8.95745C7.90888 8.9748 7.84502 8.99295 7.77435 9.01303C6.80541 9.28832 4.55544 9.92759 4.01915 13.8936C3.82187 15.6638 5.19736 17.3546 5.90976 17.9787C6.26596 18.3191 7.34004 19 8.78677 19C10.2234 19 11.5627 18.2168 12.0643 17.8169C11.8666 17.5486 11.7259 17.2391 11.6524 16.9088C11.5673 16.5266 11.5749 16.1284 11.6744 15.7499C11.773 15.3753 11.9585 15.0314 12.2149 14.7485L13.801 12.9862V8.83227C13.801 8.65573 14.0249 8.55315 14.1723 8.65019C14.7074 9.00224 15.6476 9.4253 16.9122 9.52926L17.2768 9.12412C17.3468 9.04668 17.4214 8.97453 17.5 8.90801V7.00805C17.5 6.90644 17.4246 6.8216 17.3243 6.80521C16.081 6.60193 13.9179 5.65104 13.8055 3.20074C13.8005 3.0904 13.7114 3 13.601 3Z"
			fill="#EE913B"
		/>
	</svg>
);

export default TiktokNoAuthWarning;
