import { createStore } from "zustand/vanilla";
import { immer } from "zustand/middleware/immer";
import {
	AccentColorSchema,
	AgencyEditorRoster,
	AllThemesSchema,
	RosterCreator,
	RosterOrderBySchema,
} from "@withjuly/fabric";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type RosterState = {
	roster: AgencyEditorRoster;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type RosterActions = {
	// Set Roster
	setRoster: (roster: AgencyEditorRoster) => void;

	// Set options
	setShowFollowers: (show: boolean) => void;
	setTitle: (title: string) => void;
	setAllowFilterByMediaKitTags: (show: boolean) => void;
	setShowMediaKitTags: (show: boolean) => void;
	setShowPendingCreators: (show: boolean) => void;
	setOrder: (orderBy: "NAME" | "AUDIENCE" | "MANUAL") => void;
	setCreators: (creators: RosterCreator[]) => void;
};

export type RosterStore = RosterState & RosterActions;

const defaultRosterState = {
	roster: {
		uuid: "",
		name: "",
		title: "",
		description: "",
		email: "",
		logoUrl: "",
		theme: AllThemesSchema.Enum.july,
		accentColor: AccentColorSchema.Enum.light,
		creators: [],
		allCreators: [],
		tags: [],
		showCreatorTags: false,
		filterByCreatorTags: false,
		isPrivate: false,
		customTheme: null,
		rosterOrderBy: RosterOrderBySchema.Enum.NAME,
		logoBase64: "",
		creatorProfilePicturesBase64s: {},
		showFollowers: true,
		showPendingCreators: false,
	},
};

export const createRosterStore = (
	initState: RosterState = defaultRosterState,
) => {
	return createStore<RosterStore>()(
		immer((set) => ({
			...initState,
			setRoster: (roster) => {
				set((state) => {
					state.roster = roster;
				});
			},
			setShowMediaKitTags: (show) => {
				set((state) => {
					state.roster.showCreatorTags = show;
				});
			},
			setTitle: (title) => {
				set((state) => {
					state.roster.title = title;
				});
			},
			setAllowFilterByMediaKitTags: (show) => {
				set((state) => {
					state.roster.filterByCreatorTags = show;
				});
			},
			setOrder: (order) => {
				set((state) => {
					state.roster.rosterOrderBy = order;
				});
			},
			setShowFollowers: (show) => {
				set((state) => {
					state.roster.showFollowers = show;
				});
			},
			setShowPendingCreators: (show) => {
				set((state) => {
					state.roster.showPendingCreators = show;
					state.roster.creators = show
						? state.roster.allCreators
						: state.roster.allCreators.filter((creator) => creator.isConnected);
				});
			},
			setCreators: (creators) => {
				set((state) => {
					state.roster.allCreators = creators;
				});
			},
		})),
	);
};
