import React, { ReactNode } from "react";
import { color } from "../../theme";
import { Root, Indicator } from "@radix-ui/react-checkbox";
import { Check } from "@withjuly/julycons";
import { cx } from "../../classnames";

interface CheckboxProps {
	id?: string;
	label?: string | ReactNode;
	checked: boolean;
	disabled?: boolean;
	isInvalid?: boolean;
	onChange: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
	id,
	label,
	checked,
	onChange,
	disabled = false,
	isInvalid = false,
}) => {
	const getBorderColor = () => {
		if (disabled) return color.gray[500];
		if (checked) return color.blue[500];
		if (isInvalid) return color.danger[500];
		return color.white[100];
	};

	const getBackgroundColor = () => {
		if (disabled) return color.gray[500];
		if (checked) return color.blue[500];
		return "transparent";
	};

	return (
		<label
			className={cx(
				"flex w-full items-center gap-2",
				disabled ? "cursor-not-allowed" : "cursor-pointer",
			)}
		>
			<Root
				id={id}
				checked={checked}
				onCheckedChange={onChange}
				disabled={disabled}
				className="flex h-4 w-4 content-center items-center rounded border-2 text-xs transition-all duration-150 ease-in-out focus-visible:ring-4 [&>svg]:text-gray-900"
				style={{
					background: getBackgroundColor(),
					border: `2px solid ${getBorderColor()}`,
					cursor: "inherit",
				}}
			>
				<Indicator>
					<Check className="text-gray-700" />
				</Indicator>
			</Root>
			{label}
		</label>
	);
};
