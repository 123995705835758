import * as React from "react";
import { SVGProps } from "react";

const SvgTwitchLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#twitch-logo_svg__a)">
			<path d="M2.5 12V1H15v8l-2.5 3H8l-2.5 2.5V12h-3Z" fill="currentcolor" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.477 13.91h-3.13L6.26 16H4.176v-2.09H.352V2.783L1.392 0h14.256v9.739l-4.17 4.17Zm2.784-4.865V1.392H2.784v10.085h3.13v2.086L8 11.477h3.824l2.437-2.432Z"
				fill="#65459B"
			/>
			<path
				d="M10.432 4.176v4.176h1.392V4.176h-1.392Zm-3.824 4.17H8v-4.17H6.608v4.17Z"
				fill="#65459B"
			/>
		</g>
		<defs>
			<clipPath id="twitch-logo_svg__a">
				<path fill="currentcolor" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default SvgTwitchLogo;
