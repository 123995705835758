import React from "react";
import { useFormWrapper } from "../../hooks";

/**
 * Wrap an input with label, help text, and error messages
 *
 * Note: must be used within a reach-hook-form <FormProvider />
 */
export const FormInputWrapper: React.FC<{
	id: string;
	name?: string;
	label?: string;
	help?: string;
	children: React.ReactNode;
}> = ({ id, name, label, help, children }) => {
	const { error } = useFormWrapper(name ?? "");

	return (
		<div className="flex w-full flex-col gap-2">
			{label && (
				<label className="text-white-100 text-xs" htmlFor={id + "-input"}>
					{label}
				</label>
			)}

			{children}

			{help && (
				<div id={id + "-help"} className="text-xs text-gray-100">
					{help}
				</div>
			)}

			{error?.message && (
				<div role="alert" id={id + "-error"} className="text-xs text-red-500">
					{error.message.toString()}
				</div>
			)}
		</div>
	);
};
