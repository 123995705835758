import { Check } from "@withjuly/julycons";
import { UseToastProps } from "../../hooks/use-toast";
import { ToastDescription } from "./Description";
import { Toast } from "./Root";
import { ToastTitle } from "./Title";

export interface ControlledToastProps extends UseToastProps {
	id: string;
}

export const ControlledToast: React.FC<ControlledToastProps> = ({
	status,
	icon = <Check />,
	duration,
	title,
	description,
	render,
	...props
}) => {
	if (render) {
		return render({ status, icon, duration, title, description, render });
	} else {
		return (
			<Toast status={status} icon={icon} duration={duration} {...props}>
				<ToastTitle>{title}</ToastTitle>
				<ToastDescription>{description}</ToastDescription>
			</Toast>
		);
	}
};
