import React from "react";
import { Loader } from "./Loader";
import { cva, VariantProps } from "class-variance-authority";
import { cx } from "../classnames";

const button = cva(
	[
		"flex items-center justify-center cursor-pointer text-sm rounded-lg hover:shadow-20 focus:shadow-none leading-[130%] font-semibold gap-2",
		"transition-all ease-in-out duration-150 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-900",
	],
	{
		variants: {
			variant: {
				primary: [
					"bg-blue-500 text-gray-900 hover:bg-blue-400 active:bg-blue-600 focus-visible:ring-opacity-100",
				],
				secondary: [
					"bg-gray-800 border border-gray-300 hover:bg-gray-700 active:bg-gray-900 focus-visible:ring-gray-300",
				],
				outline: [
					"bg-gray-500 border border-gray-200 hover:bg-gray-400 active:bg-gray-600 focus-visible:ring-gray-200",
				],
				danger: [
					"bg-danger-500 hover:bg-danger-400 active:bg-danger-500 focus-visible:ring-red-500",
				],
				icon: ["hover:bg-gray-500 rounded-lg text-white-100 text-base"],
			},
			size: {
				huge: ["px-4 py-3.5"],
				large: ["px-4 py-3"],
				medium: ["px-4 py-2"],
				small: ["px-3 py-2 text-xs"],
				micro: ["px-2 py-1 text-xs rounded"],
				"icon-small": ["h-[34px] w-[34px]"],
			},
			disabled: {
				true: [
					"bg-gray-600 text-white-100 !cursor-not-allowed hover:shadow-none hover:bg-gray-600",
				],
			},
			isLoading: {
				true: ["cursor-wait"],
			},
			full: {
				true: ["w-full"],
			},
		},
		compoundVariants: [
			{
				variant: "primary",
				disabled: true,
				className: "bg-gray-600 hover:bg-gray-600 active:bg-gray-600",
			},
			{
				variant: "outline",
				disabled: true,
				className: "border border-gray-400",
			},
			{
				variant: "secondary",
				disabled: true,
				className: "border border-gray-400",
			},
			{
				variant: "danger",
				disabled: true,
				className: "bg-gray-600 hover:bg-gray-600",
			},
			{
				variant: "icon",
				disabled: true,
				className: "text-gray-300",
			},
		],
		defaultVariants: {
			variant: "primary",
			size: "medium",
		},
	},
);

export interface ButtonProps
	extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "disabled">,
		VariantProps<typeof button> {
	left?: React.ReactNode;
	right?: React.ReactNode;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			variant,
			size,
			disabled,
			full,
			onClick,
			isLoading,
			children,
			left,
			right,
			className,
			...props
		},
		ref,
	) => (
		<button
			aria-busy={isLoading ?? undefined}
			className={button({
				variant,
				size,
				disabled,
				full,
				isLoading,
				className,
			})}
			disabled={disabled ?? isLoading ?? undefined}
			onClick={isLoading || disabled ? undefined : onClick}
			{...props}
			ref={ref}
		>
			{isLoading && (
				<div className="absolute">
					<Loader variant={variant === "primary" ? "primary" : undefined} />
				</div>
			)}
			{left && left}
			<div className={cx(disabled && "opacity-40", isLoading && "opacity-0")}>
				{children}
			</div>
			{right && right}
		</button>
	),
);

Button.displayName = "Button";
