import * as React from "react";
import { SVGProps } from "react";

const SvgCardBadge = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m6.8.5 5.838 1.824a.8.8 0 0 1 .562.764V4.5h1.6a.8.8 0 0 1 .8.8v1.6H5.2V5.3a.8.8 0 0 1 .8-.8h5.6v-.824l-4.8-1.5-4.8 1.5v5.923a3.2 3.2 0 0 0 1.246 2.535l.152.11 3.402 2.32L9.826 12.5H6a.8.8 0 0 1-.8-.8V8.5h10.4v3.2a.8.8 0 0 1-.8.8h-2.576a4.79 4.79 0 0 1-1.12 1.065L6.8 16.5l-4.304-2.934A4.8 4.8 0 0 1 .4 9.599V3.088a.8.8 0 0 1 .562-.763L6.8.5Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgCardBadge;
