export const color = {
	white: { 100: "#FFF", 200: "#F9FAFB" },
	gray: {
		100: "#DCDEE0",
		200: "#797F84",
		250: "#525252",
		300: "#484D51",
		400: "#2B2E30",
		500: "#252628",
		600: "#1A1D1E",
		700: "#181A1B",
		800: "#0E0F10",
		900: "#070808",
	},
	blue: {
		100: "#EDF9FE",
		200: "#CFF4FC",
		300: "#B5F1FD",
		400: "#A0EEFC",
		500: "#87E2F9",
		600: "#76D6EA",
		700: "#16344A",
		800: "#102535",
		900: "#0E181F",
	},
	notification: {
		100: "#EBEEFF",
		200: "#9FBAF9",
		300: "#5A7BE0",
		400: "#3C61DD",
		500: "#2A49C3",
		600: "#253B8B",
		700: "#21316F",
		800: "#1B2241",
		900: "#16192C",
	},
	success: {
		100: "#BFFCC9",
		200: "#97E7A3",
		300: "#62BC6F",
		400: "#3C9649",
		500: "#2B7A37",
		600: "#24652E",
		700: "#1B5524",
		800: "#162719",
		900: "#054319",
	},
	warning: {
		100: "#FFF4DB",
		200: "#F9C377",
		300: "#F3A94F",
		400: "#E18F3D",
		500: "#D7822D",
		600: "#994D24",
		700: "#61291A",
		800: "#391704",
		900: "#210E02",
	},
	danger: {
		100: "#FFE8E9",
		200: "#F19292",
		300: "#DC6B6A",
		400: "#CB4345",
		500: "#BE2D31",
		600: "#7F2126",
		700: "#691C1D",
		800: "#511410",
		900: "#3F110E",
	},
};

export const spacing = {
	25: ".25rem",
	50: ".5rem",
	75: ".75rem",
	100: "1rem",
	125: "1.25rem",
	150: "1.5rem",
	175: "1.75rem",
	200: "2rem",
	250: "2.5rem",
	300: "3rem",
	400: "4rem",
	500: "5rem",
	600: "6rem",
};

export const radius = {
	xs: ".25rem",
	sm: ".5rem",
	lg: "1rem",
};

export const shadow = {
	10: "0px 1.5px 1.5px rgba(0, 0, 0, 0.25), 0px 1px 0.5px -0.5px rgba(0, 0, 0, 0.25)",
	20: "0px 4px 3px rgba(7, 8, 8, 0.25), 0px 7px 7px rgba(7, 8, 8, 0.22)",
	40: "0px 5px 4px rgba(7, 8, 8, 0.4), 0px 10px 10px rgba(7, 8, 8, 0.2)",
	60: "0px 7px 6px rgba(7, 8, 8, 0.4), 0px 12px 12px rgba(7, 8, 8, 0.3)",
	80: "0px 9px 7px rgba(7, 8, 8, 0.3), 0px 16px 16px rgba(7, 8, 8, 0.4)",
	100: "0px 11px 7px rgba(7, 8, 8, 0.4), 0px 24px 24px rgba(7, 8, 8, 0.5)",
};

export const modalSizes = {
	352: "22rem",
	480: "30rem",
	640: "40rem",
	690: "43.125rem",
	800: "50rem",
};

export const fontSizes = {
	heading: {
		1: "3rem",
		2: "2rem",
		3: "28px",
		4: "1.5rem",
		5: "1.25rem",
		6: "1rem",
	},
	body: {
		xl: "1rem",
		lg: "0.875rem",
		sm: "0.75rem",
		xs: "0.625rem",
	},
};

export const fontWeights = {
	bold: "700",
	semiBold: "600",
	medium: "500",
};

export const fontFamilies = {
	heading: "Agrandir",
	body: "'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
};

export const zIndex = {
	nav: 100,
	dialog: 1000,
	confetti: 10000,
};

export const deviceWidth = {
	sm: 480,
	md: 768,
	lg: 1008,
	xl: 1280,
	"2xl": 1536,
};

export const breakpoint = {
	base: "@media (max-width: 30rem)",
	sm: "@media (min-width: 30rem)",
	md: "@media (min-width: 48rem)",
	lg: "@media (min-width: 62rem)",
	xl: "@media (min-width: 80rem)",
	"2xl": "@media (min-width: 96rem)",
};

export const theme = {
	color,
	spacing,
	radius,
	modalSizes,
	shadow,
	zIndex,
	breakpoint,
};
