import * as React from "react";
import { SVGProps } from "react";

const SvgBookmark = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2 1.967a.8.8 0 0 1 .8-.8h10.4a.8.8 0 0 1 .8.8v13.866l-6-4-6 4V1.967Z"
			fill="currentcolor"
		/>
	</svg>
);

export default SvgBookmark;
